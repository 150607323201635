import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGroupedEventsByGroupId,
  useGroupedTournamentsBySport
} from 'astra-core/hooks/useEvents'
import {
  getEventList,
  getIsEventListFilterUsed,
  getIsNextPageLoading
} from 'astra-core/containers/EventsProvider'
import { EventsApiGetEventsV2Request } from 'astra-core/containers/EventsProvider/types'
import {
  useFilterBySearch,
  useResetFilters
} from 'astra-core/containers/EventsProvider/hooks/list-filters'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { LineParams } from 'pages/page-line/LineContainer/types'
import { useAppSelector } from 'store'
import { EventsTableWithVirtualScroll } from 'widgets/events-table-virtual-scroll'
import { ClearResultSearch } from 'shared/ui/ClearResult'
import { EmptyPageFilter } from 'shared/ui/EmptyPageFilter'
import { InfinityPagination } from 'shared/ui/infinityPagination'
import { useVirtualScrollActions } from 'features/virtual-scroll/model/hooks'

import { LINE_EVENTS_LIST_KEY } from '../../model/constants'

import * as S from './EventsTable.styled'

export const EventsTable: FC = () => {
  const { tournamentId } = useParams<LineParams>()

  const events = useAppSelector((state) =>
    getEventList(state, LINE_EVENTS_LIST_KEY)
  )

  const { value: searchText } = useFilterBySearch(LINE_EVENTS_LIST_KEY)

  const { onResetFilters } = useResetFilters(LINE_EVENTS_LIST_KEY)

  const isLoading = useAppSelector((state) =>
    getIsNextPageLoading(state, LINE_EVENTS_LIST_KEY)
  )

  const isFilterUsed = useAppSelector((state) =>
    getIsEventListFilterUsed(state, LINE_EVENTS_LIST_KEY)
  )
  const sportGroups = useGroupedTournamentsBySport(events)

  const eventsGroups = useGroupedEventsByGroupId(events)

  const request: EventsApiGetEventsV2Request = useMemo(
    () => ({
      tournamentIds: [+tournamentId],
      scheduledFromNow: true
    }),
    [tournamentId]
  )

  const {
    loadPrevPage,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    resetEventListScroll
  } = useVirtualScrollActions({
    listKey: LINE_EVENTS_LIST_KEY,
    request,
    paramsExist: !!tournamentId
  })

  if (!events.length) {
    if (isLoading) {
      return <LoaderSpinner />
    }

    if (searchText) {
      return <ClearResultSearch />
    }

    if (isFilterUsed) {
      return <EmptyPageFilter onButtonClickResetFilter={onResetFilters} />
    }
    return null
  }

  return (
    <S.LineEvents $isEmpty={!eventsGroups.length}>
      <InfinityPagination
        hasMore={hasNextPage}
        isPaginationLoading={isNextPageLoading}
        variant="fullHeight"
        onLoadMore={loadNextPage}
      >
        <EventsTableWithVirtualScroll
          eventsGroups={sportGroups}
          listKey={LINE_EVENTS_LIST_KEY}
          loadPrevPage={loadPrevPage}
          position="line"
          resetEventListScroll={resetEventListScroll}
        />
      </InfinityPagination>
    </S.LineEvents>
  )
}
