import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DefaultCountersReq } from 'astra-core/containers/SportCountersProvider/constants'
import { sportCountersProviderActions } from 'astra-core/containers/SportCountersProvider/slice'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { resultsProviderActions } from 'astra-core/containers/ResultsProvider/slice'

import { useAppDispatch } from 'store'

import { ResultsMenuList } from '../ResultsMenuList'

import {
  StyledResultsMenuTitle,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './ResultsSportCounters.styled'

export const ResultsSportCounters = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )

  useEffect(() => {
    if (isNewCountersEndpoint) {
      const reqData = DefaultCountersReq.RESULTS

      dispatch(
        sportCountersProviderActions.fetchEventResultCountersV2({
          key: ESportsCounterType.RESULTS,
          // @ts-ignore
          reqData
        })
      )
    } else {
      dispatch(resultsProviderActions.fetchResultCounters({}))
    }
  }, [dispatch, isNewCountersEndpoint])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <StyledResultsMenuTitle>{t('kind of sport')}</StyledResultsMenuTitle>
        </StyledTabsWrapper>
        <ResultsMenuList />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
