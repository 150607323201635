import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EventsPageProvider } from 'widgets/events-page-provider'
import { ELayoutsList, Layout } from 'widgets/Layout'
import { EventsPageHeader } from 'widgets/events-page-header'

import {
  LINE_EVENTS_LIST_CONFIG,
  LINE_EVENTS_LIST_KEY,
  LINE_PERIOD_OPTIONS
} from '../../model/constants'
import { LineContainer } from '../LineContainer'
import { LineParams } from '../../model/types'

export const PageLineEventsV2 = () => {
  const { sportId } = useParams<LineParams>()
  const { t } = useTranslation()
  return (
    <Layout type={ELayoutsList.SPORT}>
      <EventsPageProvider
        config={LINE_EVENTS_LIST_CONFIG}
        listKey={LINE_EVENTS_LIST_KEY}
      >
        <EventsPageHeader
          listKey={LINE_EVENTS_LIST_KEY}
          periodOptions={LINE_PERIOD_OPTIONS(t)}
          sportId={sportId}
        />

        <LineContainer />
      </EventsPageProvider>
    </Layout>
  )
}
