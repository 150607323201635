import React, { forwardRef, memo, useMemo } from 'react'
import isEqual from 'react-fast-compare'

import { Market } from '../Market'
import { MarketV2 } from '../Market/Market'

import { CategoryProps, CategoryV2Props, PanelHandle } from './Category.types'
import { StyledTableRadius } from './Category.styled'
import { CategoryPanel } from './CategoryPanel'

const MERGED_TEMPLATES = ['G2Template']

export const Category = memo(
  forwardRef<PanelHandle, CategoryProps>(
    ({ eventId, category }, forwardedRef) => {
      const { virtualMarkets: markets, categoryName } = category

      const Markets = useMemo(
        () =>
          markets.map((market, index, array) => (
            <Market
              isMerged={
                index > 0 &&
                MERGED_TEMPLATES.includes(
                  market.outcomesGroup.renderTemplateId
                ) &&
                MERGED_TEMPLATES.includes(
                  array[index - 1].outcomesGroup.renderTemplateId
                )
              }
              eventId={eventId}
              isFirst={index === 0}
              isLast={index === array.length - 1}
              key={market.id}
              market={market}
            />
          )),
        [markets, eventId]
      )

      return (
        <CategoryPanel ref={forwardedRef} title={categoryName}>
          <StyledTableRadius>{Markets}</StyledTableRadius>
        </CategoryPanel>
      )
    }
  ),
  isEqual
)

export const CategoryV2 = memo(
  forwardRef<PanelHandle, CategoryV2Props>((props, forwardedRef) => {
    const {
      eventId,
      category: { vCategoryName, vMarkets }
    } = props

    const Markets = useMemo(
      () =>
        vMarkets.map((market, index, array) => (
          <MarketV2
            isMerged={
              index > 0 &&
              MERGED_TEMPLATES.includes(
                market.outcomesGroup.renderTemplateId
              ) &&
              MERGED_TEMPLATES.includes(
                array[index - 1].outcomesGroup.renderTemplateId
              )
            }
            eventId={eventId}
            isFirst={index === 0}
            isLast={index === array.length - 1}
            key={market.id}
            market={market}
          />
        )),
      [vMarkets, eventId]
    )

    return (
      <CategoryPanel ref={forwardedRef} title={vCategoryName}>
        <StyledTableRadius>{Markets}</StyledTableRadius>
      </CategoryPanel>
    )
  }),
  isEqual
)

Category.displayName = 'Category'
CategoryV2.displayName = 'CategoryV2'
