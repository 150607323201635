import { useCallback, useEffect } from 'react'
import {
  ESportsCounterType,
  sportCountersProviderActions
} from 'astra-core/containers/SportCountersProvider'
import { mapGetEventsListFiltersToGetEventCounters } from 'astra-core/containers/SportCountersProvider/utils'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'astra-core/containers/SportCountersProvider/constants'

import { useInterval } from 'hooks'
import { ESportsMenuTabTypes } from 'widgets/sports-menu/sports-menu.types'
import { LIVE_EVENTS_LIST_CONFIG } from 'pages/live-events-V2/model/constants'
import { LINE_EVENTS_LIST_CONFIG } from 'pages/line-events-V2/model/constants'
import { useAppDispatch } from 'store'

import { SPORTS_COUNTERS_POLLING_INTERVAL } from '../constants'

export const useFetchSportsCounters = (currentTabMenu: ESportsMenuTabTypes) => {
  const dispatch = useAppDispatch()

  const fetchSportsCounters = useCallback(() => {
    if (currentTabMenu === ESportsMenuTabTypes.LINE) {
      dispatch(
        sportCountersProviderActions.fetchSportCounters({
          key: ESportsCounterType.LINE,
          reqData: {
            ...mapGetEventsListFiltersToGetEventCounters(
              LINE_EVENTS_LIST_CONFIG
            ),
            scheduledFrom: dayjs().format(DATE_FORMAT)
          }
        })
      )
    } else if (currentTabMenu === ESportsMenuTabTypes.LIVE) {
      dispatch(
        sportCountersProviderActions.fetchSportCounters({
          key: ESportsCounterType.LIVE,
          reqData: {
            ...mapGetEventsListFiltersToGetEventCounters(
              LIVE_EVENTS_LIST_CONFIG
            ),
            scheduledTo: dayjs().format(DATE_FORMAT)
          }
        })
      )
    }
  }, [dispatch, currentTabMenu])

  useEffect(() => {
    fetchSportsCounters()
  }, [dispatch, fetchSportsCounters, currentTabMenu])

  useInterval(fetchSportsCounters, SPORTS_COUNTERS_POLLING_INTERVAL)
}
