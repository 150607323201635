import React, { FC, memo } from 'react'
import {
  useFeatureFlag,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'

import { ResultsSportCounters } from 'widgets/results-sport-counters/ui/ResultsSportCounters'
import { Auth } from 'widgets/Auth/Auth'
import { RightBasketContainer } from 'containers/BasketContainer'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { Header } from 'widgets/header'
import { FooterOfPage } from 'widgets/footer-of-page'
import { useLayoutContext } from 'pages/page-event-completed/EventCompleted.context'
import { HeaderResults, HeaderResultsV2 } from 'features/results/ui'
import { ResultsSportCountersV2 } from 'widgets/results-sport-counters/ui/ResultsSportCountersV2'

import { LayoutProps } from '../../Layout.types'

import { StyledContent, StyledLayout } from './Results.styled'

export const LayoutEventResults: FC<LayoutProps> = memo(({ children }) => {
  const { hideHeaderResults } = useLayoutContext()
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )

  return (
    <StyledLayout>
      <Auth />

      <Header />

      {hideHeaderResults ? null : isNewCountersEndpoint ? (
        <HeaderResultsV2 />
      ) : (
        <HeaderResults />
      )}

      {isNewCountersEndpoint ? (
        <ResultsSportCountersV2 />
      ) : (
        <ResultsSportCounters />
      )}

      <NotificationsPanel />

      <StyledContent>
        {children}
        <FooterOfPage />
      </StyledContent>

      <RightBasketContainer />
    </StyledLayout>
  )
})
