import React, { memo } from 'react'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { useLocation } from 'react-router-dom'
import {
  selectSettingsDataFetchItem,
  selectSettingsDataProp
} from 'astra-core/containers/SettingsProvider'
import { adjustAmountForFrontend } from 'astra-core'

import { ETestData } from 'shared/lib/testData'
import { IconLightning } from 'shared/ui/Icon/General/IconLightning'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { useAppSelector, useAppDispatch } from 'store'

import { hiddenRightContainerRoutesList } from '../../constants'

import {
  StyledBasketQuickBetButton,
  StyledBasketQuickBetButtonIcon,
  StyledBasketQuickBetButtonLine,
  StyledBasketQuickBetButtonLoaderWrapper,
  StyledBasketQuickBetButtonText
} from './BasketQuickBetButton.styled'

export const BasketQuickBetButton = memo(() => {
  const currencyIcon = useGetCurrencyIcon()
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const { colors } = useTheme()
  const { pathname } = useLocation()
  const isRouteWithPermanentHiddenBasket =
    hiddenRightContainerRoutesList.includes(pathname)

  const quickBetAmount: number = useAppSelector((state) =>
    selectSettingsDataProp(state, 'quickBetAmount')
  )

  const { loading } = useAppSelector(selectSettingsDataFetchItem)

  const toggleShowBasket = () => {
    if (loading) {
      return
    }

    dispatch(
      rightBasketContainerState.toggleBasketOnSmallWindow({ isShow: true })
    )
  }

  return (
    <StyledBasketQuickBetButton
      data-test-id={ETestData.TestQuickBetButton}
      isRouteWithPermanentHiddenBasket={isRouteWithPermanentHiddenBasket}
      onClick={toggleShowBasket}
    >
      {loading ? (
        <StyledBasketQuickBetButtonLoaderWrapper>
          <LoaderPoints
            colors={{
              start: colors[EColorsTypes.DEFAULT].primary[0],
              end: colors[EColorsTypes.DEFAULT].primary[60]
            }}
            settingsAnimation={{
              timeAfterEnd: 2.4,
              scaleActivePoint: 1
            }}
            count={3}
            margin={2}
            size={4}
          />
        </StyledBasketQuickBetButtonLoaderWrapper>
      ) : (
        <>
          <StyledBasketQuickBetButtonIcon>
            <IconLightning
              colorProps={{
                type: EColorsTypes.FIXED,
                name: EColorsNames.Primary,
                value: 0
              }}
            />
          </StyledBasketQuickBetButtonIcon>
          {`${adjustAmountForFrontend(quickBetAmount)} ${currencyIcon ?? ''}`}
          <StyledBasketQuickBetButtonLine />
          <StyledBasketQuickBetButtonText>
            {t('just coupon')}
          </StyledBasketQuickBetButtonText>
        </>
      )}
    </StyledBasketQuickBetButton>
  )
})
