import get from 'lodash/get'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  Competitor,
  Event,
  Sport,
  EventSourceType,
  EventProbabilityTradingStatus,
  EventProbability
} from 'betweb-openapi-axios'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import {
  CommonDataProviderState,
  MainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'

import { TUseProbs } from 'hooks'
import { toIdsArray } from 'shared/lib/@reduxjs'

export function getEventSport(sports, event?: Event): Sport {
  return get(sports, `${event?.tournament.sportId}`)
}

export function getLastYearEvent(date: string): string | null {
  const islastYear = Number(dayjs(date).format('YYYY')) < Number(dayjs().year())
  return islastYear ? dayjs(date).format('YYYY') : null
}

export const groupEventItemsBySport = (items: Event[]) =>
  mapValues(
    groupBy(items, (item) => item.tournament.sportId),
    (events) => toIdsArray(events)
  )

export const getCompetitorsNames = (competitors: Competitor[]) =>
  competitors.map((c) => c.name).join(', ')

export const getEventBetradarSourceId = (event: Event) =>
  event.sources
    ?.find((source) => source.sourceType === EventSourceType.Betradar)
    ?.sourceEventId.split(':')[2]

export const getMainOutcomeTypeProbability = ({
  outcomeType,
  probs,
  mainOutcomeTypes,
  eventId
}: {
  outcomeType: MainOutcomeTypes
  probs: TUseProbs
  mainOutcomeTypes: CommonDataProviderState['entries']['mainOutcomeTypes']
  eventId: Event['id']
}): EventProbability & { eventId: number } => {
  const probability = probs[mainOutcomeTypes[outcomeType]!]
  const outcomeTypeId = mainOutcomeTypes[outcomeType]!

  return probability
    ? {
        ...probability,
        eventId
      }
    : {
        odd: 0,
        outcomeTypeId,
        parameters: [],
        suspended: true,
        tradingStatus: EventProbabilityTradingStatus.Suspended,
        eventId
      }
}
