import { PayloadAction } from '@reduxjs/toolkit'
import { ErrorPayload } from 'astra-core/typings/api'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'
import { requestInitialState } from 'shared/lib/api'
import { getPreloadedState } from 'shared/lib/@reduxjs'

import {
  ContainerState,
  FetchEventPayload,
  FetchEventsByIdsPayload
} from './types'

const REDUCER_KEY = 'eventContainer'

export const initialState: ContainerState = getPreloadedState(REDUCER_KEY, {
  fetchItem: requestInitialState
})

const eventContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchEventsByIds(state, _action: PayloadAction<FetchEventsByIdsPayload>) {},
    fetchEvent(state, _action: PayloadAction<FetchEventPayload>) {
      state.fetchItem.loading = true
      state.fetchItem.error = null
    },
    fetchEventSuccess(state) {
      state.fetchItem.loading = false
      state.fetchItem.error = null
    },
    fetchEventError(state, action: PayloadAction<ErrorPayload>) {
      state.fetchItem.loading = false
      state.fetchItem.error = action.payload
    }
  }
})

export const {
  actions,
  actions: eventContainerActions,
  reducer,
  name: sliceKey
} = eventContainerSlice
