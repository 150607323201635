import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  accountProviderActions,
  selectTypeAuthModal
} from 'astra-core/containers/AccountProvider'

import { useAppSelector, useAppDispatch } from 'store'

import { useAuthTypeModal } from './useAuthTypeModal'

export const useAuth = () => {
  const location = useLocation<{ showAuthModal: boolean }>()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const setAuthTypeModal = useAuthTypeModal()

  const typeAuthModal = useAppSelector(selectTypeAuthModal)

  const hideAuthModal = useCallback(() => {
    if (location.state?.showAuthModal) {
      history.replace({
        state: {
          showAuthModal: false
        }
      })
    }
  }, [history, location.state?.showAuthModal])

  const showAuthModal = useCallback(() => {
    dispatch(accountProviderActions.setTypeAuthModal(null))
    setAuthTypeModal(false)
  }, [dispatch, setAuthTypeModal])

  useEffect(() => {
    if (location.state?.showAuthModal) {
      showAuthModal()
    }
  }, [location.state?.showAuthModal, showAuthModal])

  useEffect(() => {
    if (typeAuthModal) {
      hideAuthModal()
    }
  }, [typeAuthModal, hideAuthModal])
}
