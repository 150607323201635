import { Event } from 'betweb-openapi-axios'
import { EEventsStatusList } from 'astra-core/utils/events/types'

import { OutcomeCategoriesHandle } from './Categories/Categories.types'

export enum OutcomesComponentType {
  EVENT_PAGE = 'event page',
  EVENTS_LIST = 'events list'
}

export interface OutcomesProps {
  eventId: Event['id']
  type: OutcomesComponentType
  isLoading?: boolean
  outcomesRef?: React.RefObject<OutcomeCategoriesHandle>
  eventStatusType: EEventsStatusList | null
}

interface Streaming {
  kind: 'STREAM'
}
interface Polling {
  kind: 'POLL'
  fetchInterval: number | null
}
interface Nothing {
  kind: 'NOTHING'
}

export type OutcomesFetchType = Streaming | Polling | Nothing
export interface OutcomesV2Props {
  eventId: Event['id']
  dataFetchType?: OutcomesFetchType
  isLoading?: boolean
  outcomesRef?: React.RefObject<OutcomeCategoriesHandle>
  eventStatusType: EEventsStatusList | null
}
