import { selectIsDevelopment } from 'astra-core/containers/ConfigProvider'
import React, { FC } from 'react'

import { useAppSelector } from 'store'

import { RowWrapperProps } from './RowWrapper.types'

export const RowWrapper: FC<RowWrapperProps> = ({
  eventId,
  page1StartRef,
  page1EndRef,
  page2StartRef,
  page2EndRef,
  beforeStartRef,
  beforeEndRef,
  children,
  page1EventIds,
  page2EventIds,
  beforeEventIds
}) => {
  const isDevelopment = useAppSelector(selectIsDevelopment)

  const isFirstPage = page1EventIds.includes(eventId)
  const isSecondPage = page2EventIds.includes(eventId)

  let comp: JSX.Element = <div />
  let position: 'before' | 'after' = 'before'

  if (beforeEventIds.length !== 0) {
    if (eventId === beforeEventIds[0]) {
      comp = (
        <div
          ref={beforeStartRef}
          style={isDevelopment ? { border: '1px black solid' } : undefined}
        />
      )
      position = 'before'
    } else if (eventId === beforeEventIds[beforeEventIds.length - 1]) {
      comp = (
        <div
          ref={beforeEndRef}
          style={isDevelopment ? { border: '1px black solid' } : undefined}
        />
      )
      position = 'after'
    }
  }
  if (page1EventIds.length !== 0) {
    if (eventId === page1EventIds[0]) {
      comp = (
        <div
          ref={page1StartRef}
          style={isDevelopment ? { border: '1px red solid' } : undefined}
        />
      )
      position = 'before'
    } else if (eventId === page1EventIds[page1EventIds.length - 1]) {
      comp = (
        <div
          ref={page1EndRef}
          style={isDevelopment ? { border: '1px red solid' } : undefined}
        />
      )
      position = 'after'
    }
  }

  if (page2EventIds.length !== 0) {
    if (eventId === page2EventIds[0]) {
      comp = (
        <div
          ref={page2StartRef}
          style={isDevelopment ? { border: '1px blue solid' } : undefined}
        />
      )
      position = 'before'
    } else if (eventId === page2EventIds[page2EventIds.length - 1]) {
      comp = (
        <div
          ref={page2EndRef}
          style={isDevelopment ? { border: '1px blue solid' } : undefined}
        />
      )
      position = 'after'
    }
  }

  const isOverview = isDevelopment && !isFirstPage && !isSecondPage

  return (
    <>
      {position === 'before' && comp}
      {children({ isOverview })}
      {position === 'after' && comp}
    </>
  )
}
