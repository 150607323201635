import React from 'react'
import { selectNotificationGeneralUnread } from 'astra-core/containers/MessagesProvider'

import { ETestData } from 'shared/lib/testData'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { getIsDisplayNotificationPanel } from 'containers/LayoutContainer/selectors'
import { useAppSelector, useAppDispatch } from 'store'

import { StyledIconMessage } from './NotificationsButton.types'

export const NotificationsButton = () => {
  const dispatch = useAppDispatch()
  const isDisplayNotificationPanel = useAppSelector(
    getIsDisplayNotificationPanel
  )
  const generalUnread = useAppSelector(selectNotificationGeneralUnread)

  const onToggleNotificationPanel = () => {
    dispatch(
      layoutContainerActions.toggleIsDisplayNotificationPanel(
        !isDisplayNotificationPanel
      )
    )
  }

  return (
    <StyledIconMessage
      isNewNotifications={!!generalUnread}
      size={26}
      testData={ETestData.TestMainHeaderNotificationsButton}
      onClick={onToggleNotificationPanel}
    />
  )
}
