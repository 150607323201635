import { useCallback, useEffect } from 'react'
import {
  ESportsCounterType,
  selectSportsCountersList,
  selectSportsCountersListV2
} from 'astra-core/containers/SportCountersProvider'
import { useHistory } from 'react-router-dom'
import find from 'lodash/find'
import { Sport } from 'betweb-openapi-axios'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { useAppSelector } from 'store'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { getMenuTabSportsCounterType } from 'widgets/sports-menu/components/SportMenuList/utils'

export interface RedirectToSportProps {
  sportId?: Sport['id']
  currentTabMenu: ESportsMenuTabTypes
}

// TODO: used in mobile too. Transfer to Astra-Core

export const useRedirectToSport = ({
  sportId,
  currentTabMenu
}: RedirectToSportProps) => {
  const history = useHistory()

  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )
  const key =
    currentTabMenu === 'live'
      ? ESportsCounterType.LIVE
      : ESportsCounterType.LINE

  const sportsCountersList = useAppSelector((state) =>
    isNewCountersEndpoint
      ? selectSportsCountersListV2(state, key)
      : selectSportsCountersList(
          state,
          getMenuTabSportsCounterType(currentTabMenu)
        )
  )

  const redirectToSport = useCallback(() => {
    if (sportsCountersList.length) {
      history.replace(`/${currentTabMenu}/${sportsCountersList[0].id}`)
    }
  }, [currentTabMenu, history, sportsCountersList])

  useEffect(() => {
    if (!sportId || !find(sportsCountersList, ['id', sportId])) {
      redirectToSport()
    }
  }, [currentTabMenu, history, redirectToSport, sportId, sportsCountersList])
}
