import React, { FC, useMemo } from 'react'

import { Toastify } from 'shared/lib/Toastify'
import { TypeformComponents } from 'widgets/typeform'
import { Settings } from 'widgets/user-account-settings'
import { LayoutDefault } from 'widgets/Layout/components/Default'

import { LayoutEvent } from './components/Event'
import { StyledLayout } from './Layout.styled'
import { ELayoutsList, LayoutProps } from './Layout.types'
import {
  LayoutProfile,
  LayoutSport,
  LayoutAbout,
  LayoutGame,
  LayoutEventResults,
  LayoutError,
  LayoutCalendar,
  LayoutMainContainer
} from './components'

const layoutsList = {
  [ELayoutsList.SPORT]: LayoutSport,
  [ELayoutsList.PROFILE]: LayoutProfile,
  [ELayoutsList.ABOUT]: LayoutAbout,
  [ELayoutsList.GAME]: LayoutGame,
  [ELayoutsList.RESULTS]: LayoutEventResults,
  [ELayoutsList.CALENDAR]: LayoutCalendar,
  [ELayoutsList.EVENT]: LayoutEvent,
  [ELayoutsList.ERROR]: LayoutError,
  [ELayoutsList.DEFAULT]: LayoutDefault,
  [ELayoutsList.MAIN]: LayoutMainContainer
}

export const Layout: FC<LayoutProps> = (props) => {
  const { type = ELayoutsList.SPORT } = props

  const Component = useMemo(() => layoutsList[type], [type])

  return (
    <StyledLayout>
      <Toastify />
      <Settings />
      <TypeformComponents />
      <Component {...props} type={type} />
    </StyledLayout>
  )
}
