import { pageCalendarPrefetch } from 'pages/page-calendar'
import { pageLinePrefetch } from 'pages/page-line'
import { pageLineTournamentPrefetch } from 'pages/page-line-tournament'
import { pageLiveEventsPrefetch } from 'pages/page-live-events'
import { ERoutes } from 'shared/types/routes'

type PrefetchFunction = ({ req, store }) => void
type RoutesPrefetchMap = { [r in ERoutes]?: PrefetchFunction }

export const PREFETCH_ROUTE_MAP: RoutesPrefetchMap = {
  [ERoutes.LiveEvents]: pageLiveEventsPrefetch,
  [ERoutes.Line]: pageLinePrefetch,
  [ERoutes.LineTournament]: pageLineTournamentPrefetch,
  [ERoutes.Calendar]: pageCalendarPrefetch
} as const
