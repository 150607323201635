import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { selectInputSearchLine } from 'astra-core/containers/SearchProvider'
import { useGroupedTournamentsBySport } from 'astra-core/hooks/useEvents'
import { selectIsGenericSport } from 'astra-core/containers/CommonDataProvider'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useAppSelector, useAppDispatch } from 'store'
import { ClearResultSearch } from 'shared/ui/ClearResult'
import { EmptyPageFilter } from 'shared/ui/EmptyPageFilter'

import {
  selectIsLiveFilterUsed,
  selectLayoutIsLoading,
  selectLiveEventsBySearch
} from '../LiveContainer/selectors'
import { LiveParams } from '../LiveContainer/types'
import { liveContainerActions } from '../LiveContainer/slice'

import { StyledLiveTable } from './Line.styled'
import SportGroup from './components/SportsGroup/SportsGroup'

export const GenericLiveTable: FC = () => {
  const dispatch = useAppDispatch()

  const searchText = useAppSelector(selectInputSearchLine)
  const { sportId } = useParams<LiveParams>()

  const isLiveFilterUsed = useAppSelector(selectIsLiveFilterUsed)

  const events = useAppSelector((state) =>
    selectLiveEventsBySearch(state, +sportId)
  )
  const sportGroups = useGroupedTournamentsBySport(events)
  const isLayoutLoading = useAppSelector(selectLayoutIsLoading)

  const resetLiveFilter = useCallback(() => {
    dispatch(liveContainerActions.resetLiveFilter({ isResetFilter: true }))
  }, [dispatch])

  const showSportHeader = useAppSelector((s) =>
    selectIsGenericSport(s, +sportId)
  )

  return (
    <StyledLiveTable>
      {isLayoutLoading && !sportGroups.length ? (
        <LoaderSpinner />
      ) : sportGroups.length ? (
        <div>
          {sportGroups.map((sg) => (
            <SportGroup
              key={sg.sportId}
              showSportHeader={showSportHeader}
              sportGroup={sg}
            />
          ))}
        </div>
      ) : searchText ? (
        <ClearResultSearch />
      ) : isLiveFilterUsed ? (
        <EmptyPageFilter onButtonClickResetFilter={resetLiveFilter} />
      ) : null}
    </StyledLiveTable>
  )
}
