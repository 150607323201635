import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { TFunction } from 'react-i18next'

export const formatEventDate = (date: string, t: TFunction): string => {
  if (dayjs(date).isSame(dayjs().add(1, 'day'), 'day')) {
    return t('tomorrow')
  }
  if (dayjs(date).isSame(dayjs(), 'day')) {
    return t('today')
  }
  return ''
}
