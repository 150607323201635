import React, { FC, Fragment, useContext, useMemo } from 'react'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import uniqueId from 'lodash/uniqueId'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { useAppSelector } from 'store'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-event/Event/Event.context'

import {
  StyledGameScore,
  StyledGameScoreColumn,
  StyledGameScoreItem,
  StyledGameScoreWrapper,
  StyledMainScore,
  StyledMainScoreColumn,
  StyledMainScoreItem,
  StyledMainScoreWrapper,
  StyledNamePart,
  StyledPeriodScoreColumn,
  StyledScoreByPeriods,
  StyledSection,
  StyledPeriodScoreWrapper,
  StyledPeriodScoreItem,
  StyledPeriodScore,
  StyledPeriodScoreColumns
} from './ScoreByPeriods.styled'
import { ScoreByPeriodsPropsType, ScoreWithId } from './ScoreByPeriods.types'
import { REG_EXP_SCORES } from './consts'

export const ScoreByPeriods: FC<ScoreByPeriodsPropsType> = ({
  isLive,
  bannerScore
}) => {
  const [t] = useTranslation()

  const { event } = useContext(EventContext) as IEventContext
  const sport = useAppSelector((state) =>
    selectSport(state, event.tournament.sportId)
  )

  const mainScores = bannerScore
    ?.split(':')
    .filter((element) => element.trim().replace(REG_EXP_SCORES, ''))
    .map((score, index) => ({ id: index, score }))

  const scoreByPeriods = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.ScoresByPeriods
  )

  const gameScore = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.GameScore
  )

  const gameScores: ScoreWithId[] | null = useMemo(() => {
    if (!gameScore?.value) {
      return null
    }

    if (sport?.code === ESportsCodes.TENNIS) {
      const scores = gameScore?.value
        .replace(/50/g, 'A')
        .split(':')
        .filter((score) => !isEmpty(score))
        .map((score, index) => ({ id: index, score })) // invariant for an empty string
      // Invariant for scores list length, must be 2

      if (scores.length === 2) {
        return scores
      }
    }
    return null
  }, [gameScore?.value, sport?.code])

  const splittedScoreByPeriods = useMemo(
    () =>
      scoreByPeriods?.value.split(',').map((item) => ({
        id: uniqueId(),
        value: item
      })),
    [scoreByPeriods]
  )

  // TODO Take out and make for all sports
  const namePartEvent = (numberPart: number) => {
    if (
      sport?.code === ESportsCodes.FOOTBALL ||
      sport?.code === ESportsCodes.ICE_HOCKEY ||
      sport?.code === ESportsCodes.TENNIS ||
      sport?.code === ESportsCodes.TABLE_TENNIS
    ) {
      return `${numberPart} ${t('t')}`
    }
    if (sport?.code === ESportsCodes.BASKETBALL) {
      return `${numberPart} ${t('p')}`
    }
    if (sport?.code === ESportsCodes.VOLLEYBALL) {
      return `${numberPart} ${t('s')}`
    }
    if (sport?.code === ESportsCodes.BOX || sport?.code === ESportsCodes.MMA) {
      return `${numberPart} ${t('r')}`
    }

    return `${numberPart} ${t('p')}`
  }

  return (
    <StyledScoreByPeriods>
      <StyledSection>
        {!!gameScores?.length && <GameScoreColumn gameScores={gameScores} />}
        <StyledPeriodScoreColumns>
          {splittedScoreByPeriods?.map((item, index) => {
            const scoresHalfTime = item.value
              .split(':')
              .filter((e) => e.trim().replace(REG_EXP_SCORES, ''))

            return (
              <Fragment key={item.id}>
                {!!scoresHalfTime?.length && (
                  <StyledPeriodScoreColumn isLive={isLive} key={item.id}>
                    <StyledNamePart>{namePartEvent(index + 1)}</StyledNamePart>

                    <StyledPeriodScoreWrapper>
                      {scoresHalfTime?.map((score, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <StyledPeriodScoreItem key={`${score}_${index}`}>
                          <StyledPeriodScore>{score}</StyledPeriodScore>
                        </StyledPeriodScoreItem>
                      ))}
                    </StyledPeriodScoreWrapper>
                  </StyledPeriodScoreColumn>
                )}
              </Fragment>
            )
          })}
        </StyledPeriodScoreColumns>
        {!!mainScores?.length && <MainScoreColumn mainScores={mainScores} />}
      </StyledSection>
    </StyledScoreByPeriods>
  )
}

// TODO: Move to own component
type MainScoreColumnProps = {
  mainScores: ScoreWithId[]
}
const MainScoreColumn: FC<MainScoreColumnProps> = ({ mainScores }) => {
  const [t] = useTranslation()

  return (
    <StyledMainScoreColumn>
      <StyledNamePart>{t('score')}</StyledNamePart>
      <StyledMainScoreWrapper>
        {mainScores.map(({ id, score }) => (
          <StyledMainScoreItem key={id}>
            <StyledMainScore>{score}</StyledMainScore>
          </StyledMainScoreItem>
        ))}
      </StyledMainScoreWrapper>
    </StyledMainScoreColumn>
  )
}

// TODO: Move to own component
type GameScoreColumnProps = {
  gameScores: ScoreWithId[]
}
const GameScoreColumn: FC<GameScoreColumnProps> = ({ gameScores }) => {
  const [t] = useTranslation()

  return (
    <StyledGameScoreColumn>
      <StyledNamePart>{t('game')}</StyledNamePart>
      <StyledGameScoreWrapper>
        {gameScores.map(({ id, score }) => (
          <StyledGameScoreItem key={id}>
            <StyledGameScore>{score}</StyledGameScore>
          </StyledGameScoreItem>
        ))}
      </StyledGameScoreWrapper>
    </StyledGameScoreColumn>
  )
}
