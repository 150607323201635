import React, { useMemo } from 'react'
import { BetOutcome, OutcomeCategory, OutcomeType } from 'betweb-openapi-axios'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import {
  selectVirtualCategoryInfo,
  selectVirtualMarketInfo,
  selectVirtualMarketInfosMap
} from 'astra-core/containers/CommonDataProvider/selectors'
import { selectConfig } from 'astra-core/containers/ConfigProvider/selectors'
import { selectPlayers } from 'astra-core/containers/PlayersProvider/selectors'
import { getOutcomeNameInBetsHistory } from 'astra-core/utils/outcomes/computeEventProbabilitiesByMarket/v3'

import { useAppSelector } from 'store'

import { StyledEventType } from './OutcomeName.styled'

interface Props {
  outcomeCategory: OutcomeCategory | undefined
  outcomeType: OutcomeType | undefined
  outcome: BetOutcome
}

export const OutcomeName = ({
  outcomeCategory,
  outcomeType,
  outcome
}: Props) => {
  const newOutcomesEnabled = useFeatureFlag(EFeatureFlags.NEW_OUTCOMES_ENABLED)
  return newOutcomesEnabled ? (
    <OutcomeNameNew outcome={outcome} outcomeType={outcomeType} />
  ) : (
    <OutcomeNameDeprecated
      outcome={outcome}
      outcomeCategory={outcomeCategory}
      outcomeType={outcomeType}
    />
  )
}

const OutcomeNameNew = ({
  outcomeType,
  outcome
}: {
  outcomeType: OutcomeType | undefined
  outcome: BetOutcome
}) => {
  const virtualMarketInfosMap = useAppSelector(selectVirtualMarketInfosMap)
  const virtualMarketInfo = useAppSelector((state) => {
    if (outcome.virtualMarketId) {
      return selectVirtualMarketInfo(state, outcome.virtualMarketId)
    }
  })
  const virtualCategoryInfo = useAppSelector((state) => {
    if (virtualMarketInfo) {
      return selectVirtualCategoryInfo(state, virtualMarketInfo.vCategoryId)
    }
  })
  const config = useAppSelector(selectConfig)
  const playersMap = useAppSelector(selectPlayers)

  const outcomeName = useMemo(() => {
    if (!outcomeType) return ''
    return getOutcomeNameInBetsHistory(
      outcome,
      virtualMarketInfosMap,
      outcomeType,
      config.CLIENT_TYPE,
      playersMap,
      outcome?.event?.competitors || [],
      virtualMarketInfo,
      virtualCategoryInfo
    )
  }, [
    outcomeType,
    outcome,
    virtualMarketInfosMap,
    config.CLIENT_TYPE,
    playersMap,
    virtualMarketInfo,
    virtualCategoryInfo
  ])

  return <StyledEventType>{outcomeName}</StyledEventType>
}

const OutcomeNameDeprecated = ({
  outcomeCategory,
  outcomeType,
  outcome
}: Props) => {
  const basketOutcomeName = useMemo(
    () =>
      getBasketOutcomeName({
        probWithOutcome: { ...outcome, outcomeTypeData: outcomeType! },
        event: outcome.event!,
        isBetOutcome: true,
        outcomeCategory
      }),
    [outcome, outcomeCategory, outcomeType]
  )
  return <StyledEventType>{basketOutcomeName}</StyledEventType>
}
