import {
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab
} from 'astra-core/containers/BasketProvider/selectors'
import { selectCurrencyMaxPayout } from 'astra-core/containers/CommonDataProvider/selectors'
import { useBasketAvailableWidth } from 'astra-core/hooks/useBasketAvailableWidth'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'
import { formatAmount } from 'astra-core/utils/format'
import React, { useCallback, useMemo, useReducer, useRef } from 'react'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import { IconExtraHint } from 'shared/ui/Icon/General/IconExtraHint'
import { EColorsNames, EColorsTypes } from 'shared/types/theme/colors'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { CurrencyDynamicInput } from 'shared/ui/DynamicInput'
import { DynamicInputContext } from 'shared/ui/DynamicInput/DynamicInputContext'

import { getIconArrowColorProps } from '../../constants'
import {
  StyledIconArrow,
  StyledIconExtraHintWrapper,
  StyledWinSum
} from '../../Common.styled'

import {
  StyledBasketInputContainer,
  StyledBasketInputWrapper,
  StyledBetCurrency,
  StyledBetInputWrapper,
  StyledBetPlaceholder
} from './BasketInput.styled'
import { BasketInputProps } from './BasketInput.types'
import { MinMaxHint, MinMaxHintPopup } from './components'

export const BASKET_INPUT_WIDTH = 248
export const BASKET_INPUT_WIDTH_WITHOUT_EMPTY_SPACE = BASKET_INPUT_WIDTH - 24

export const BasketInput: React.FC<BasketInputProps> = ({
  stakeAmount,
  winSum,
  maxBet,
  onAmountChange,
  isBonusWallet,
  testData
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const focusOnInput = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const currencyIcon = useGetCurrencyIcon()
  const [isShownHint, setIsShownHint] = useReducer((_, type) => type, false)

  const maxPayout = useAppSelector(selectCurrencyMaxPayout)
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)

  const inputValue = useMemo(() => {
    return formatInput(stakeAmount, appliedCoupon)
  }, [stakeAmount, appliedCoupon])

  const [isShownHintIcon, setMaxBetBlockWidth, measuredRef] =
    useBasketAvailableWidth(inputValue, BASKET_INPUT_WIDTH_WITHOUT_EMPTY_SPACE)

  const sumWin = useMemo(() => {
    return countWinningSum(
      winSum,
      maxPayout,
      appliedCoupon,
      stakeAmount,
      currencyIcon
    )
  }, [currencyIcon, winSum, maxPayout, stakeAmount, appliedCoupon])

  const showCurrency = !isBonusWallet && !appliedCoupon
  const showInput = !appliedCoupon

  return (
    <StyledBasketInputContainer>
      {isShownHint && <MinMaxHintPopup maxBet={maxBet} />}
      <StyledBasketInputWrapper
        betTypeTab={betTypeTab}
        isAppliedCoupon={!!appliedCoupon}
        onClick={focusOnInput}
      >
        <StyledBetPlaceholder>
          {isShownHintIcon ? (
            <StyledIconExtraHintWrapper
              onMouseEnter={() => setIsShownHint(true)}
              onMouseLeave={() => setIsShownHint(false)}
            >
              <IconExtraHint
                colorProps={{
                  type: EColorsTypes.DEFAULT,
                  name: EColorsNames.Primary,
                  value: 30
                }}
              />
            </StyledIconExtraHintWrapper>
          ) : (
            <MinMaxHint
              maxBet={maxBet}
              setMaxBetBlockWidth={setMaxBetBlockWidth}
            />
          )}
        </StyledBetPlaceholder>

        <StyledBetInputWrapper ref={measuredRef}>
          {showInput && (
            <DynamicInputContext.Provider value={{ testData }}>
              <CurrencyDynamicInput
                inputValue={inputValue}
                placeholder="0"
                ref={inputRef}
                onAmoutChange={onAmountChange}
              />
            </DynamicInputContext.Provider>
          )}

          {isBonusWallet && <IconBonus />}
          {showCurrency && (
            <StyledBetCurrency>{currencyIcon}</StyledBetCurrency>
          )}
          {!!winSum && (
            <StyledWinSum
              data-test-id={ETestData.TestWinSumBasket}
              isAppliedCoupon={!!appliedCoupon}
            >
              <StyledIconArrow
                colorProps={getIconArrowColorProps(!!appliedCoupon)}
              />
              {sumWin}
            </StyledWinSum>
          )}
        </StyledBetInputWrapper>
      </StyledBasketInputWrapper>
    </StyledBasketInputContainer>
  )
}

function formatInput(
  stakeAmount: string,
  appliedCoupon: string | null | undefined
): string {
  const value = stakeAmount ? stakeAmount.replace('.', ',') : ''

  return appliedCoupon ? '' : value
}

function countWinningSum(
  winSum: number,
  maxPayout: number,
  appliedCoupon: string | null | undefined,
  stakeAmount: string,
  currencyIcon: string
): string {
  const sum = winSum > maxPayout ? maxPayout : winSum
  const value = appliedCoupon ? stakeAmount : sum.toFixed(2)

  return formatAmount({
    value,
    currency: currencyIcon
  })
}
