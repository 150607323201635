import React, { forwardRef, memo, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { selectMarkets } from 'astra-core/containers/CommonDataProvider/selectors'
import { MarketType } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'

import { StyledHeaderCell, StyledOutcomesTableHeader } from '../OutcomesTable'
import { Market } from '../Market'

import { CategoryProps, PanelHandle } from './Category.types'
import { StyledTableRadius } from './Category.styled'
import { CategoryPanel } from './CategoryPanel'

const MERGED_TEMPLATES = ['G2Template']

export const Category = memo(
  forwardRef<PanelHandle, CategoryProps>(
    ({ eventId, category }, forwardedRef) => {
      const { marketCategoryName, categoryMarketInfos: markets } = category
      const { t } = useTranslation()
      const marketsDictionary = useAppSelector(selectMarkets)

      let shouldShowCommonTableHeader = false
      let commonHeaderTitles: string[] = []
      if (markets.length) {
        const marketType = marketsDictionary[markets[0].marketId].type
        shouldShowCommonTableHeader = markets.every(
          (market) =>
            market.templateId === 'G2Template' &&
            marketsDictionary[market.marketId].type === marketType
        )
        if (shouldShowCommonTableHeader) {
          if (marketType === MarketType.YesNo) {
            commonHeaderTitles = ['', 'yes', 'no']
          } else if (marketType === MarketType.Total) {
            commonHeaderTitles = ['', 'over', 'under']
          }
        }
      }

      const Markets = useMemo(
        () =>
          markets.map((market, index, array) => (
            <Market
              isLast={
                shouldShowCommonTableHeader ? false : index === array.length - 1
              }
              isMerged={
                shouldShowCommonTableHeader
                  ? true
                  : index > 0 &&
                    MERGED_TEMPLATES.includes(market.templateId) &&
                    MERGED_TEMPLATES.includes(array[index - 1].templateId)
              }
              eventId={eventId}
              isFirst={index === 0}
              key={market.id}
              market={market}
            />
          )),
        [markets, eventId, shouldShowCommonTableHeader]
      )

      return (
        <CategoryPanel ref={forwardedRef} title={marketCategoryName}>
          <StyledTableRadius>
            {shouldShowCommonTableHeader && (
              <StyledOutcomesTableHeader columns={commonHeaderTitles as any}>
                {commonHeaderTitles.map((title, i) => (
                  <StyledHeaderCell isFirstInRow={i === 0} key={title}>
                    {t(title)}
                  </StyledHeaderCell>
                ))}
              </StyledOutcomesTableHeader>
            )}
            {Markets}
          </StyledTableRadius>
        </CategoryPanel>
      )
    }
  ),
  isEqual
)

Category.displayName = 'Category'
