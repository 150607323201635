import React, { ComponentProps, FC, useContext } from 'react'
import { isTopEntity } from 'astra-core'
import { getHomeAwayCompetitorsRenamed } from 'astra-core/utils/events'
import { Event } from 'betweb-openapi-axios'
import { selectTopTagId } from 'astra-core/containers/CommonDataProvider'
import {
  useFeatureFlag,
  EFeatureFlags
} from 'astra-core/containers/ConfigProvider'

import { useAppSelector } from 'store'
import {
  EventTeamsNamesCompactView as EventTeamsNamesEntityCompactView,
  EventTeamsNames as EventTeamsNamesEntity
} from 'entities/event'
import { EventRowOpenEventPage } from 'features/event'
import { ETagTypes, Tag } from 'shared/ui/Tag'
import { useIsSmallMedium } from 'shared/lib/styled'
import { TableBetsContext } from 'pages/page-live-events/Line/components/ui/TableBets/TableBets.context'
import { useCompetitorsRenamed } from 'hooks'

import { useGetIsActivePlayer } from '../../lib/teams-names'

import { StyledTeamWrapper } from './teams-names.styled'

export const EventRowTeamsNames: FC<{ event: Event }> = ({ event }) => {
  const isEntityTagsEnabled = useFeatureFlag(EFeatureFlags.ENTITY_TAGS_ENABLED)
  const { isFavourite } = useContext(TableBetsContext)
  const { renameDate, isNewSbCompetitorsNameEnabled } = useCompetitorsRenamed()

  const { homeCompetitors, awayCompetitors } = getHomeAwayCompetitorsRenamed({
    event,
    renameDate,
    isNewSbCompetitorsNameEnabled
  })

  const { isActiveHomeTeam, isActiveAwayTeam } = useGetIsActivePlayer({
    eventStatistics: event.statistics
  })

  const teams: ComponentProps<typeof EventTeamsNamesEntity>['teams'] = [
    {
      name: homeCompetitors.map((c) => c.name).join(', '),
      isActivePlayer: isActiveHomeTeam
    },
    {
      name: awayCompetitors.map((c) => c.name).join(', '),
      isActivePlayer: isActiveAwayTeam
    }
  ]

  const isCompactView = useIsSmallMedium()
  const topTagId = useAppSelector(selectTopTagId)

  const isTopEvent =
    isEntityTagsEnabled && !isFavourite // TODO remove isFavourite after fix backend
      ? isTopEntity(event, topTagId)
      : event.top

  return (
    <>
      {isCompactView ? (
        <EventTeamsNamesEntityCompactView
          awayTeamWrapper={(teams) => (
            <TeamWrapper event={event} marqueeId={awayCompetitors[0]?.id}>
              {teams}
            </TeamWrapper>
          )}
          homeTeamWrapper={(teams) => (
            <StyledTeamWrapper>
              <TeamWrapper event={event} marqueeId={homeCompetitors[0]?.id}>
                {teams}
              </TeamWrapper>
              {isTopEvent && <Tag type={ETagTypes.TOP} />}
            </StyledTeamWrapper>
          )}
          teams={teams}
        />
      ) : (
        <EventTeamsNamesEntity
          teamsWrapper={(teams) => (
            <TeamWrapper event={event}>{teams}</TeamWrapper>
          )}
          tagComponent={isTopEvent && <Tag type={ETagTypes.TOP} />}
          teams={teams}
        />
      )}
    </>
  )
}

export const TeamWrapper: FC<{
  event: Event
  marqueeId?: number
  children
}> = ({ children, event, marqueeId }) => (
  <EventRowOpenEventPage event={event} marqueeId={marqueeId}>
    {children}
  </EventRowOpenEventPage>
)
