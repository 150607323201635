import React, { FC, memo, useCallback, useEffect } from 'react'
import {
  eventsProviderActions,
  selectEventProbabilitiesChannelSSEById
} from 'astra-core/containers/EventsProvider'
import { useLocation } from 'react-router-dom'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { useInView } from 'react-intersection-observer'

import { ETestData } from 'shared/lib/testData'
import { useInterval } from 'hooks'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { ERoutes } from 'shared/types/routes'
import { useAppSelector, useAppDispatch } from 'store'

import {
  OutcomesComponentType,
  OutcomesProps,
  OutcomesV2Props
} from './Outcomes.types'
import * as S from './Outcomes.styled'
import { Categories } from './Categories'
import { CategoriesV2 } from './Categories/Categories'
import { useOutcomesData } from './useOutcomesData'

const EVENT_OUTCOMES_POLLING_INTERVAL = 2000

export const Outcomes: FC<OutcomesProps> = memo(
  ({ eventId, type, isLoading = false, outcomesRef, eventStatusType }) => {
    const dispatch = useAppDispatch()
    const { pathname } = useLocation()
    const isMainPage = ERoutes.Root === pathname
    const isMainPageSseEnabled = useFeatureFlag(
      EFeatureFlags.MAIN_PAGE_SSE_ENABLED
    )
    const isMainPageSse = isMainPage && isMainPageSseEnabled

    const eventProbabilitiesChannelSSE = useAppSelector((state) =>
      selectEventProbabilitiesChannelSSEById(state, eventId)
    )

    useEffect(() => {
      if (isMainPageSse) {
        dispatch(
          eventsProviderActions.subscribeEventProbabilitiesSSE({ eventId })
        )

        return () => {
          dispatch(
            eventsProviderActions.unsubscribeEventProbabilitiesSSE({ eventId })
          )
        }
      }
    }, [dispatch, eventId, isMainPageSse])

    const fetchEvent = useCallback(() => {
      if (!eventProbabilitiesChannelSSE) {
        dispatch(
          eventsProviderActions.fetchEvent({
            reqData: { eventId }
          })
        )
      }
    }, [dispatch, eventId, eventProbabilitiesChannelSSE])

    /*
  Add polling event outcomes only for Events List,
  since polling full event data is already enabled on Event Page
   */
    useInterval(
      fetchEvent,
      type === OutcomesComponentType.EVENTS_LIST
        ? EVENT_OUTCOMES_POLLING_INTERVAL
        : null,
      true
    )

    const eventHasStatusType = eventStatusType !== null

    const newOutcomesStructEnabled = useFeatureFlag(
      EFeatureFlags.NEW_OUTCOMES_STRUCT_ENABLED
    )

    if (eventHasStatusType) {
      return <S.OutcomesWrapper />
    }

    return (
      <S.OutcomesWrapper>
        {isLoading ? (
          <S.OutcomesLoaderWrap>
            <LoaderPoints size={6} />
          </S.OutcomesLoaderWrap>
        ) : (
          <S.OutcomesTables data-test-id={ETestData.TestEventTable}>
            {newOutcomesStructEnabled ? (
              <CategoriesV2 eventId={eventId} ref={outcomesRef} />
            ) : (
              <Categories eventId={eventId} ref={outcomesRef} />
            )}
          </S.OutcomesTables>
        )}
      </S.OutcomesWrapper>
    )
  }
)

export const OutcomesWithFetchType: FC<OutcomesV2Props> = memo(
  ({
    eventId,
    isLoading = false,
    outcomesRef,
    eventStatusType,
    dataFetchType
  }) => {
    const [ref, inView] = useInView({ threshold: 0 })
    useOutcomesData({ eventId, dataFetchType, when: inView })

    const eventHasStatusType = eventStatusType !== null

    const newOutcomesStructEnabled = useFeatureFlag(
      EFeatureFlags.NEW_OUTCOMES_STRUCT_ENABLED
    )

    if (eventHasStatusType) {
      return <S.OutcomesWrapper />
    }

    return (
      <S.OutcomesWrapper ref={ref}>
        {isLoading ? (
          <S.OutcomesLoaderWrap>
            <LoaderPoints size={6} />
          </S.OutcomesLoaderWrap>
        ) : (
          <S.OutcomesTables data-test-id={ETestData.TestEventTable}>
            {newOutcomesStructEnabled ? (
              <CategoriesV2 eventId={eventId} ref={outcomesRef} />
            ) : (
              <Categories eventId={eventId} ref={outcomesRef} />
            )}
          </S.OutcomesTables>
        )}
      </S.OutcomesWrapper>
    )
  }
)

Outcomes.displayName = 'Outcomes'
