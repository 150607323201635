import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FetchBetsHistoryPayload,
  FetchBetsHistorySuccessPayload
} from 'astra-core/containers/BetsHistoryProvider'
import uniq from 'lodash/uniq'
import { ErrorPayload } from 'astra-core/typings/api'

import { requestInitialState } from 'shared/lib/api'
import { toIdsArray } from 'shared/lib/@reduxjs'

import { ContainerState } from './types'

const REDUCER_KEY = 'betsHistoryBasketContainer'

export const initialState: ContainerState = {
  bets: {
    fetchItems: requestInitialState,
    hasMore: false,
    sortedIds: []
  },
  layout: {},
  pagination: {
    limit: 8,
    offset: 0
  }
}

export const betsHistoryBasketContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    fetchHistoryBetsBasket(
      state,
      _actions: PayloadAction<FetchBetsHistoryPayload>
    ) {
      state.bets.fetchItems.loading = true
      state.bets.fetchItems.error = null
    },
    fetchHistoryBetsBasketSuccess(
      state,
      { payload }: PayloadAction<FetchBetsHistorySuccessPayload>
    ) {
      state.bets.fetchItems.loading = false
      state.bets.fetchItems.error = null
      state.bets.hasMore = payload.resData.hasMore

      state.bets.sortedIds = uniq([
        ...state.bets.sortedIds,
        ...toIdsArray(payload.resData.items)
      ])
    },
    fetchHistoryBetsBasketError(
      state,
      { payload }: PayloadAction<ErrorPayload>
    ) {
      state.bets.fetchItems.loading = false
      state.bets.fetchItems.error = payload
      clearBetsHistoryBasketPagination(state)
    },
    setPagination(state, { payload }) {
      state.pagination = { ...state.pagination, ...payload }
    },
    setInitialStateBetsHistoryBasket() {
      return initialState
    }
  }
})

const clearBetsHistoryBasketPagination = (state: ContainerState) => {
  state.pagination = initialState.pagination
  state.bets.fetchItems = { ...requestInitialState }
}

export const {
  actions,
  reducer,
  name: sliceKey
} = betsHistoryBasketContainerSlice
