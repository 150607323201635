import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectSettingsDataProp,
  selectUserSettingLayoutTheme
} from 'astra-core/containers/SettingsProvider'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import { Radio } from 'shared/ui/Radio'

import { getSettingIcon } from '../../utils'
import {
  StyledSettingItem,
  StyledSettingItemContentRadio,
  StyledSettingItemIcon,
  StyledSettingItemLabel,
  StyledSettingItemTitle
} from '../../Settings.styled'

export const SettingDisplayLiveOrLineSection = () => {
  const [t] = useTranslation()
  const themeType = useAppSelector(selectUserSettingLayoutTheme)
  const isDisplayLive = useAppSelector((state) =>
    selectSettingsDataProp(state, 'liveInitiallyDisplayed')
  )
  const { onSetUserSetting } = useSetUserSetting()

  const handleChangeSection = useCallback(
    (isLive: boolean) => () => {
      onSetUserSetting({
        liveInitiallyDisplayed: isLive
      })
    },
    [onSetUserSetting]
  )

  const iconSources = useMemo(
    () => ({
      line: getSettingIcon('setting site section line.png', themeType),
      live: getSettingIcon('setting site section live.png', themeType)
    }),
    [themeType]
  )

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>
        {t('show section on login')}
      </StyledSettingItemTitle>

      <StyledSettingItemContentRadio>
        <StyledSettingItemLabel onClick={handleChangeSection(false)}>
          <StyledSettingItemIcon $src={iconSources.line} />

          <Radio
            checked={!isDisplayLive}
            label={t('line (default)')}
            testData={ETestData.TestSettingsLine}
          />
        </StyledSettingItemLabel>

        <StyledSettingItemLabel onClick={handleChangeSection(true)}>
          <StyledSettingItemIcon $src={iconSources.live} />

          <Radio
            checked={!!isDisplayLive}
            label={t('live')}
            testData={ETestData.TestSettingsLive}
          />
        </StyledSettingItemLabel>
      </StyledSettingItemContentRadio>
    </StyledSettingItem>
  )
}
