import React, { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { useAppSelector } from 'store'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { LiveParams } from 'pages/page-live-events/LiveContainer/types'

import { LINE_HEADER_FILTERS } from './constants'
import { ILineHeaderProps } from './LineHeader.types'
import { StyledLineHeader } from './LineHeader.styled'

export const LineHeader: FC<ILineHeaderProps> = ({ typeHeader }) => {
  const { sportId } = useParams<LiveParams>()
  const sport = useAppSelector((state) => selectSport(state, +sportId))

  const Icon = useMemo(
    () => (
      <SportIcon
        sportCode={sport?.code as ESportsCodes}
        isActive
        withBackground
      />
    ),
    [sport?.code]
  )

  const Filters = LINE_HEADER_FILTERS[typeHeader]

  return (
    <StyledLineHeader>
      <TitleLinePage iconComponent={Icon} title={sport?.name}>
        <Filters />
      </TitleLinePage>
    </StyledLineHeader>
  )
}
