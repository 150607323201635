import styled from 'styled-components'

import { Button, StyledButton } from 'shared/ui/Button'

export const StyledTableRow = styled.div<{
  isSuspended?: boolean
}>`
  display: grid;
  grid-template-columns:
    1fr repeat(6, 40px) repeat(2, 80px) repeat(3, minmax(max-content, 45px))
    68px;
  position: relative;
`

export const CoefficientTableCellButtonExpandStyled = styled(Button)`
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;

  * {
    color: ${(props) => props.theme.colors.default.primary[50]} !important;
  }
`

export const LinkedEventsExpandButton = styled.div`
  ${StyledButton} {
    background-color: transparent;
    color: ${(props) => props.theme.colors.default.primary[50]};
    font-size: ${(props) => props.theme.fonts.size.xxs};
    font-weight: ${(props) => props.theme.fonts.weight.normal};
    max-height: 16px;
    padding: 0;
  }
`

export const LinkedEventInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
