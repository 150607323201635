import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Event } from 'betweb-openapi-axios'

import { MAX_OPENED_EVENTS } from './constants'
import { EventsState } from './types'

const initialState: EventsState = {
  ui: {
    expandedOutcomes: {
      byId: {},
      order: []
    }
  }
}

const slice = createSlice({
  name: 'eventsContainer',
  initialState,
  reducers: {
    toggleEvent(
      state,
      action: PayloadAction<{
        eventId: Event['id']
        nextState: boolean
        maxCount?: number
      }>
    ) {
      const {
        eventId,
        nextState,
        maxCount = MAX_OPENED_EVENTS
      } = action.payload

      const { expandedOutcomes } = state.ui

      if (nextState === true) {
        if (expandedOutcomes.order.length === maxCount) {
          const firstId = expandedOutcomes.order.shift()
          if (firstId) {
            delete expandedOutcomes.byId[firstId]
          }
        }

        expandedOutcomes.byId[eventId] = true
        expandedOutcomes.order.push(eventId)
      } else {
        state.ui.expandedOutcomes.order = expandedOutcomes.order.filter(
          (id) => id === eventId
        )
        delete expandedOutcomes.byId[eventId]
      }
    },
    close(state, action: PayloadAction<{ eventId: Event['id'] }>) {
      const { expandedOutcomes } = state.ui
      const { eventId } = action.payload

      state.ui.expandedOutcomes.order = expandedOutcomes.order.filter(
        (id) => id === eventId
      )
      delete expandedOutcomes.byId[eventId]
    },
    closeAll(state) {
      state.ui.expandedOutcomes.order = []
      state.ui.expandedOutcomes.byId = {}
    }
  }
})

export const { actions: eventExpandActions, reducer, name } = slice
