import React, { createContext, FC } from 'react'
import { ListKey } from 'astra-core/containers/EventsProvider'

import { TableBetsEventHeaderProps } from './TableBets.types'

export interface TableBetsContextProps {
  tableEventHead?: TableBetsEventHeaderProps
  isLive?: boolean
  isLinkHead?: boolean
  isFavourite?: boolean
  listKey: ListKey
  position?: 'line' | 'live' | 'calendar' | 'favourites'
}

export const TableBetsContext = createContext<TableBetsContextProps>({
  tableEventHead: { withSportIcon: false, withSportName: false },
  listKey: 'live'
})

export const TableBetsProvider: FC<TableBetsContextProps> = ({
  children,
  ...value
}) => {
  return (
    <TableBetsContext.Provider value={value}>
      {children}
    </TableBetsContext.Provider>
  )
}
