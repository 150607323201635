import { useInView } from 'react-intersection-observer'
import { useMemo } from 'react'

import { usePrevious } from 'hooks'

export const useInfiniteScrollPagesInfo = () => {
  const [beforeStartRef, beforeStartInView] = useInView()
  const [beforeEndRef, beforeEndInView] = useInView()
  const [page1StartRef, page1StartInView] = useInView()
  const [page1EndRef, page1EndInView] = useInView()
  const [page2StartRef, page2StartInView] = useInView()
  const [page2EndRef, page2EndInView] = useInView()
  const beforeStartInViewPrev = usePrevious(beforeStartInView)
  const beforeEndInViewPrev = usePrevious(beforeEndInView)
  const page1StartInViewPrev = usePrevious(page1StartInView)
  const page1EndInViewPrev = usePrevious(page1EndInView)
  const page2StartInViewPrev = usePrevious(page2StartInView)
  const page2EndInViewPrev = usePrevious(page2EndInView)
  const page = useMemo(() => {
    if (
      page1StartInView &&
      !page1EndInView &&
      !page2StartInView &&
      !page2EndInView
    ) {
      return 'page1'
    }
    if (
      !page1StartInView &&
      page1EndInView &&
      !page2StartInView &&
      !page2EndInView
    ) {
      return 'page1'
    }
    if (
      !page1StartInView &&
      !page1EndInView &&
      page2StartInView &&
      !page2EndInView
    ) {
      return 'page2'
    }
    if (
      !page1StartInView &&
      !page1EndInView &&
      !page2StartInView &&
      page2EndInView
    ) {
      return 'page2'
    }
    if (
      page1StartInView &&
      page1EndInView &&
      !page2StartInView &&
      !page2EndInView
    ) {
      return 'page1' // check page size
    }
    if (
      !page1StartInView &&
      page1EndInView &&
      page2StartInView &&
      !page2EndInView
    ) {
      return 'both'
    }
    if (
      !page1StartInView &&
      !page1EndInView &&
      page2StartInView &&
      page2EndInView
    ) {
      return 'page2' // check page size
    }
    if (
      page1StartInView &&
      page1EndInView &&
      page2StartInView &&
      !page2EndInView
    ) {
      return 'both' // check page size
    }
    if (
      !page1StartInView &&
      page1EndInView &&
      page2StartInView &&
      page2EndInView
    ) {
      return 'both' // check page size
    }
    if (
      page1StartInView &&
      page1EndInView &&
      page2StartInView &&
      page2EndInView
    ) {
      return 'both' // check page size
    }
    if (
      !page1StartInView &&
      !page1EndInView &&
      !page2StartInView &&
      !page2EndInView
    ) {
      if (
        !page1StartInViewPrev &&
        !page1EndInViewPrev &&
        !page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page1'
      }
      if (
        page1StartInViewPrev &&
        !page1EndInViewPrev &&
        !page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page1'
      }
      if (
        !page1StartInViewPrev &&
        page1EndInViewPrev &&
        !page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page1'
      }
      if (
        !page1StartInViewPrev &&
        page1EndInViewPrev &&
        page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page1'
      }
      if (
        !page1StartInViewPrev &&
        page1EndInViewPrev &&
        page2StartInViewPrev &&
        page2EndInViewPrev
      ) {
        return 'page1'
      }

      if (
        page1StartInViewPrev &&
        page1EndInViewPrev &&
        page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page2'
      }
      if (
        !page1StartInViewPrev &&
        page1EndInViewPrev &&
        page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page2'
      }
      if (
        !page1StartInViewPrev &&
        !page1EndInViewPrev &&
        page2StartInViewPrev &&
        !page2EndInViewPrev
      ) {
        return 'page2'
      }
      if (
        !page1StartInViewPrev &&
        !page1EndInViewPrev &&
        !page2StartInViewPrev &&
        page2EndInViewPrev
      ) {
        return 'page2'
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page1StartInView, page1EndInView, page2StartInView, page2EndInView])
  const pagePrev = usePrevious(page)

  return {
    page,
    pagePrev,
    page1StartRef,
    page1EndRef,
    page2StartRef,
    page2EndRef,
    beforeStartRef,
    beforeEndRef,
    beforeStartInView,
    beforeEndInView,
    beforeStartInViewPrev,
    beforeEndInViewPrev
  }
}
