import { useInterval } from 'astra-core'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { useEffect, useCallback } from 'react'
import { Event } from 'betweb-openapi-axios'

import { useAppDispatch } from 'store'

import { OutcomesFetchType } from './Outcomes.types'

/*

Outcomes как сейчас
 - на главной странице в раскрытом списке
    - должно подключаться к sse - но только пробсы
 - на старнице лайв/лайн в раскрытом списке
    - поллит все событие
 - на странице события
    - он ничего не делает

*/
interface UseOutcomesDataArgs {
  eventId: Event['id']
  dataFetchType?: OutcomesFetchType
  when: boolean
}

const EVENT_OUTCOMES_POLLING_INTERVAL = 2000

export function useOutcomesData(args: UseOutcomesDataArgs) {
  const {
    eventId,
    dataFetchType = {
      kind: 'POLL',
      fetchInterval: EVENT_OUTCOMES_POLLING_INTERVAL
    },
    when
  } = args

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (dataFetchType.kind === 'STREAM' && when) {
      dispatch(
        eventsProviderActions.subscribeEventProbabilitiesSSE({ eventId })
      )

      return () => {
        dispatch(
          eventsProviderActions.unsubscribeEventProbabilitiesSSE({ eventId })
        )
      }
    }
  }, [dispatch, eventId, dataFetchType.kind, when])

  const fetchEventFn = useCallback(() => {
    if (dataFetchType.kind === 'POLL' && when) {
      dispatch(
        eventsProviderActions.fetchEvent({
          reqData: { eventId }
        })
      )
    }
  }, [dataFetchType.kind, when, dispatch, eventId])

  useInterval(
    fetchEventFn,
    dataFetchType.kind === 'POLL' ? dataFetchType.fetchInterval : null,
    true
  )
}
