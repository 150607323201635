import React, { memo } from 'react'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { getIsDisplayNotificationPanel } from 'containers/LayoutContainer/selectors'
import {
  useHandleRedirectList,
  useOnboardingAttrs
} from 'widgets/header/MainHeader/components/PersonalData/components/ComponentOfAuthorized/hooks'
import { useAppSelector, useAppDispatch } from 'store'

import {
  StyledCasinoComponentOfAuthorized,
  StyledIconMessage,
  StyledIconPersonal,
  StyledPadding
} from './CasinoComponentOfAuthorized.styled'
import { BalanceButton } from './BalanceButton'

export const CasinoComponentOfAuthorized = memo(() => {
  const dispatch = useAppDispatch()
  const isDisplayNotificationPanel = useAppSelector(
    getIsDisplayNotificationPanel
  )

  const handleRedirectList = useHandleRedirectList()

  const { onboardingAttrPersonalAccount, onboardingAttrBalance } =
    useOnboardingAttrs()

  const onToggleNotificationPanel = () => {
    dispatch(
      layoutContainerActions.toggleIsDisplayNotificationPanel(
        !isDisplayNotificationPanel
      )
    )
  }

  return (
    <StyledCasinoComponentOfAuthorized>
      <StyledPadding {...onboardingAttrBalance}>
        <StyledIconMessage size={20} onClick={onToggleNotificationPanel} />
      </StyledPadding>
      {/* Check if we should show the bonus container, because it returns null if isBalanceHidden and it will break the onboarding */}

      <StyledPadding {...onboardingAttrBalance}>
        <BalanceButton />
      </StyledPadding>

      <StyledPadding {...onboardingAttrPersonalAccount}>
        <StyledIconPersonal
          colorProps={{
            name: EColorsNames.Primary,
            type: EColorsTypes.CUSTOM,
            value: 7
          }}
          size={20}
          onClick={handleRedirectList(ERoutes.Profile)}
        />
      </StyledPadding>
    </StyledCasinoComponentOfAuthorized>
  )
})
