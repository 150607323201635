import styled from 'styled-components'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

export const ExpandButton = styled(IconChevronDown)`
  height: 4px;
  margin-inline-start: auto;
  width: 8px;
`

export const EventDate = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.default.primary[5]};
  color: ${(props) => props.theme.colors.default.primary[50]};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xs};
  line-height: ${(props) => props.theme.fonts.size.l};
  padding: 0 16px;
  position: sticky;
  top: 23px;
  z-index: 1;

  span {
    margin-right: 4px;

    &:empty {
      margin: 0;
    }
  }
`

export const EventsTable = styled.div``

export const EventItem = styled.div<{ isShow: boolean }>`
  display: ${({ isShow }) => `${!isShow ? 'none' : 'block'}`};
`
