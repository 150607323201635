import React, { FC } from 'react'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { useAppSelector } from 'store'
import { EventCoefficientTotal } from 'entities/event'

import { useGetTotalValue } from '../../lib'

export const EventRowCoefficientTotal: FC<
  Pick<Parameters<typeof useGetTotalValue>[0], 'event'> &
    Pick<Parameters<typeof useGetTotalValue>[0], 'totalOutcomeType'>
> = ({ event, totalOutcomeType }) => {
  const value = useGetTotalValue({ event, totalOutcomeType })
  const { code: sportCode } =
    useAppSelector((state) => selectSport(state, event.tournament.sportId)) ||
    {}

  return (
    <EventCoefficientTotal
      sportCode={sportCode as ESportsCodes}
      value={value}
    />
  )
}
