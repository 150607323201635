import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  basketProviderActions,
  selectActiveLoyaltyByAppliedCouponIdsByBetType,
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab
} from 'astra-core/containers/BasketProvider'
import { useGetCurrencyIcon } from 'astra-core'
import { useTheme } from 'styled-components'
import { ELoyaltyCoupons } from 'astra-core/containers/AccountProvider'

import { IconExtraHint } from 'shared/ui/Icon/General/IconExtraHint'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'
import { ERatioView, Radio } from 'shared/ui/Radio'
import { RootState } from 'shared/types/store'
import { useAppSelector, useAppDispatch } from 'store'

import {
  StyledBasketBonusCouponEmptySpace,
  StyledBasketBonusCouponFirstCircle,
  StyledBasketBonusCouponFirstRightCircle,
  StyledBasketBonusCouponFourthCircle,
  StyledBasketBonusCouponFourthRightCircle,
  StyledBasketBonusCouponRightBigCircle,
  StyledBasketBonusCouponSecondCircle,
  StyledBasketBonusCouponSecondRightCircle,
  StyledBasketBonusCouponsWrapper,
  StyledBasketBonusCouponThirdCircle,
  StyledBasketBonusCouponThirdRightCircle,
  StyledBasketBonusCouponWrapper,
  StyledBasketCouponRightSide,
  StyledRadioWrapper
} from './BasketBonusCoupons.styled'
import { IBasketBonusCouponProps } from './BasketBonusCoupons.types'

// List of bonus coupons that have passed the terms of use and can be used
export const BasketBonusCoupons = () => {
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)
  const activeLoyaltyByAppliedCouponIdsByBetType = useAppSelector(
    (state: RootState) =>
      selectActiveLoyaltyByAppliedCouponIdsByBetType(
        state,
        ELoyaltyCoupons.SUITABLE
      )
  )

  return (
    <StyledBasketBonusCouponsWrapper betTypeTab={betTypeTab}>
      {activeLoyaltyByAppliedCouponIdsByBetType[betTypeTab].map((coupon) => (
        <BasketBonusCoupon key={coupon.id} {...coupon} />
      ))}
    </StyledBasketBonusCouponsWrapper>
  )
}

const BasketBonusCoupon: FC<IBasketBonusCouponProps> = ({
  id,
  possibleAmount,
  coupon: { amount }
}) => {
  const dispatch = useAppDispatch()
  const currencyIcon = useGetCurrencyIcon()
  const { t } = useTranslation()
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)
  const theme = useTheme()

  const setAppliedCoupon = () => {
    if (appliedCoupon === id) {
      dispatch(basketProviderActions.setAppliedCoupon(null))
    } else {
      dispatch(basketProviderActions.setAppliedCoupon(id))
    }
  }

  const isRadioChecked = appliedCoupon === id
  const couponTitle = getFormattedBonusCouponAmount(
    t('coupon for'),
    possibleAmount ?? amount,
    currencyIcon
  )

  return (
    <StyledBasketBonusCouponWrapper>
      <StyledRadioWrapper isActive={isRadioChecked}>
        <Radio
          labelStyle={{
            color: theme.colors.custom.blue[17],
            width: 'max-content',
            paddingTop: '8px',
            paddingBottom: '8px'
          }}
          checked={isRadioChecked}
          label={couponTitle}
          view={ERatioView.BASKET}
          onClick={setAppliedCoupon}
        />

        <StyledBasketBonusCouponEmptySpace onClick={setAppliedCoupon}>
          <IconExtraHint
            colorProps={{
              type: EColorsTypes.CUSTOM,
              name: EColorsNames.Blue,
              value: 23
            }}
          />
        </StyledBasketBonusCouponEmptySpace>
      </StyledRadioWrapper>

      <BasketBonusCouponFourCirclesLeft />

      <StyledBasketCouponRightSide isActive={isRadioChecked}>
        <BasketBonusCouponFourCirclesRight />
      </StyledBasketCouponRightSide>
    </StyledBasketBonusCouponWrapper>
  )
}

const BasketBonusCouponFourCirclesLeft = () => (
  <>
    <StyledBasketBonusCouponFirstCircle />
    <StyledBasketBonusCouponSecondCircle />
    <StyledBasketBonusCouponThirdCircle />
    <StyledBasketBonusCouponFourthCircle />
  </>
)

const BasketBonusCouponFourCirclesRight = () => (
  <>
    <StyledBasketBonusCouponFirstRightCircle />
    <StyledBasketBonusCouponSecondRightCircle />
    <StyledBasketBonusCouponThirdRightCircle />
    <StyledBasketBonusCouponFourthRightCircle />
    <StyledBasketBonusCouponRightBigCircle />
  </>
)
