import React from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { useAppSelector } from 'store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import {
  GenericLineTournaments,
  LineTournaments
} from 'pages/page-live-events/Line/components/LineTournaments'

import { selectLineTournamentsIsEmpty } from './selectors'
import { useLineTournamentsContainerData } from './hooks'
import { LineTournamentsProps } from './types'

export function LineTournamentsContainer(_props: LineTournamentsProps) {
  const isEmpty = useAppSelector(selectLineTournamentsIsEmpty)
  // TODO: OL-553 Remove after release
  const isGenericEnabled = useFeatureFlag(EFeatureFlags.GENERIC_SPORT_ENABLED)

  useLineTournamentsContainerData()

  return isEmpty ? (
    <LoaderSpinner />
  ) : isGenericEnabled ? (
    <GenericLineTournaments />
  ) : (
    <LineTournaments />
  )
}
