import React, { FC } from 'react'
import { useGetCurrencyIcon } from 'astra-core'
import { useTranslation } from 'react-i18next'
import { LayoutTheme } from 'betweb-openapi-axios'
import { useTheme } from 'styled-components'
import {
  selectActiveLoyaltyByAppliedCouponIdsByBetType,
  selectBasketBetTypeTab
} from 'astra-core/containers/BasketProvider'
import { ELoyaltyCoupons } from 'astra-core/containers/AccountProvider'

import { useAppSelector } from 'store'
import {
  IconBonusCouponBasketDark,
  IconBonusCouponBasketLight
} from 'shared/ui/Icon/General/IconBonusCouponBasket'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'
import { IconProps } from 'shared/ui/Icon'
import { RootState } from 'shared/types/store'

import { IBasketSuitableBonusCouponProps } from './BasketSuitableBonusCoupons.types'
import {
  StyledBasketSuitableBonusCouponsWrapper,
  StyledBasketSuitableBonusCouponTitle,
  StyledBasketSuitableBonusCouponTitleWrapper,
  StyledBasketSuitableBonusCouponWrapper,
  StyledConditions
} from './BasketSuitableBonusCoupons.styled'

// The list of bonus coupons cannot be used because the terms of use have not been passed
export const BasketSuitableBonusCoupons = () => {
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)
  const activeLoyaltyByAppliedCouponIdsByBetType = useAppSelector(
    (state: RootState) =>
      selectActiveLoyaltyByAppliedCouponIdsByBetType(
        state,
        ELoyaltyCoupons.UNSUITABLE
      )
  )[betTypeTab]

  return activeLoyaltyByAppliedCouponIdsByBetType.length ? (
    <StyledBasketSuitableBonusCouponsWrapper>
      {activeLoyaltyByAppliedCouponIdsByBetType.map((coupon) => (
        <BasketSuitableBonusCoupon key={coupon.id} {...coupon} />
      ))}
    </StyledBasketSuitableBonusCouponsWrapper>
  ) : null
}

const getIconBonusCoupon = (props: IconProps) => ({
  [LayoutTheme.Light]: <IconBonusCouponBasketLight {...props} />,
  [LayoutTheme.Dark]: <IconBonusCouponBasketDark {...props} />,
  [LayoutTheme.Unknown]: <IconBonusCouponBasketLight {...props} />
})

const BasketSuitableBonusCoupon: FC<IBasketSuitableBonusCouponProps> = ({
  possibleAmount,
  coupon: { description, amount }
}) => {
  const currencyIcon = useGetCurrencyIcon()
  const { t } = useTranslation()
  const { typeLayoutTheme } = useTheme()

  const couponTitle = getFormattedBonusCouponAmount(
    t('coupon for'),
    possibleAmount ?? amount,
    currencyIcon
  )

  const hintAttr = getHintAttrNameFormatted(description ?? '')

  return (
    <StyledBasketSuitableBonusCouponWrapper>
      <StyledBasketSuitableBonusCouponTitleWrapper>
        {getIconBonusCoupon({ size: 16 })[typeLayoutTheme]}
        <StyledBasketSuitableBonusCouponTitle>
          {couponTitle}
        </StyledBasketSuitableBonusCouponTitle>
      </StyledBasketSuitableBonusCouponTitleWrapper>

      <StyledConditions {...hintAttr}>{t('conditions')}</StyledConditions>
    </StyledBasketSuitableBonusCouponWrapper>
  )
}
