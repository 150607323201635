import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { useSetUserSetting } from 'astra-core/containers/SettingsProvider/utils'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import { Checkbox } from 'shared/ui/checkbox'

import {
  StyledSettingItem,
  StyledSettingItemTitle
} from '../../Settings.styled'

export const SettingBalance = () => {
  const [t] = useTranslation()
  const isBalanceHidden = useAppSelector((state) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )
  const { onSetUserSetting } = useSetUserSetting()

  const handleDisplayBalance = useCallback(() => {
    onSetUserSetting({
      balanceHidden: !isBalanceHidden
    })
  }, [isBalanceHidden, onSetUserSetting])

  return (
    <StyledSettingItem>
      <StyledSettingItemTitle>{t('balance')}</StyledSettingItemTitle>
      <Checkbox
        checked={!!isBalanceHidden}
        label={t('hide balance')}
        testData={ETestData.TestSettingsCheckbox}
        onClick={handleDisplayBalance}
      />
    </StyledSettingItem>
  )
}
