import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ETestData } from 'og-web-core/utils/test-data'
import {
  useFilterBySearch,
  useFilterByTop
} from 'astra-core/containers/EventsProvider/hooks/list-filters'

import { Switch } from 'shared/ui/Switch'
import { SearchEventsInput } from 'widgets/search-events-input'

import { TimePeriodFilter } from '../TimePeriodFilter'

import * as S from './EventsFilters.styled'
import { EventsFiltersProps } from './EventsFilters.types'

export const EventsFilters: FC<EventsFiltersProps> = memo((props) => {
  const { periodOptions, listKey } = props
  const [t] = useTranslation()

  const { onToggleTop, isTop } = useFilterByTop(listKey, ['TOP'])

  const { value, onChangeSearch } = useFilterBySearch(listKey)
  return (
    <S.Filters>
      <Switch
        active={isTop}
        label={t('top')}
        testData={ETestData.TestLiveTopButton}
        onClick={onToggleTop}
      />
      {periodOptions && (
        <TimePeriodFilter listKey={listKey} periodOptions={periodOptions} />
      )}

      <S.InputWrapper>
        <SearchEventsInput
          initialValue={value}
          key={listKey}
          onChange={onChangeSearch}
        />
      </S.InputWrapper>
    </S.Filters>
  )
})
