import { put, takeLatest, call } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import {
  EFeatureFlags,
  isFeatureFlagEnabled
} from 'astra-core/containers/ConfigProvider'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'

import { liveContainerActions } from './slice'
import { FetchLivePayload } from './types'

const SOURCE_KEY = 'liveEvents'

export function* fetchLiveSaga(action: PayloadAction<FetchLivePayload>) {
  const isGenericSportsEnabled = yield call(
    isFeatureFlagEnabled,
    EFeatureFlags.GENERIC_SPORT_ENABLED
  )

  yield put(
    eventsProviderActions.fetchEvents({
      reqData: action.payload,
      sideEffects: eventsProviderSideEffects[SOURCE_KEY],
      params: {
        withCompetitorsLogo: false,
        withLinkedEvents: true,
        includeSubsports: isGenericSportsEnabled || undefined
      }
    })
  )
}

export function* liveContainerSaga() {
  yield takeLatest(liveContainerActions.fetchLive.type, fetchLiveSaga)
}
