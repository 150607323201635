import React, { FC } from 'react'

import { useAppSelector } from 'store'
import { selectIsDisplayModalSettings } from 'containers/LayoutContainer/selectors'

import { SettingsList } from './components'

export const Settings: FC = () => {
  const isDisplayModal = useAppSelector(selectIsDisplayModalSettings)

  return isDisplayModal ? <SettingsList /> : null
}
