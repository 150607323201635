import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { selectCurrentLanguage } from 'astra-core/containers/LanguagesProvider'

import { ETestData } from 'shared/lib/testData'
import { IconHeadMic } from 'shared/ui/Icon/General/IconHeadMic'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { TIME_DATA_FORMAT, useGetNowTime } from 'hooks/useGetNowTime'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { EButtonViews } from 'shared/ui/Button'
import { ERoutes } from 'shared/types/routes'
import { Flag } from 'shared/ui/Flag'
import { EWelcomeOnboardingDataAttrs } from 'widgets/onboarding/typesOfOnboarding/welcomeOnboarding/welcomeOnboarding.types'
import { useOnboardingAttr } from 'widgets/onboarding/hooks'
import { EOnboardingType } from 'widgets/onboarding/Onboarding.types'
import { useAppSelector, useAppDispatch } from 'store'

import { ChangeLanguage } from '../ChangeLanguage'

import {
  StyledAdditional,
  StyledFlagButton,
  StyledIconSetting,
  StyledLink,
  StyledSupport,
  StyledTime
} from './Additional.styled'

export const Additional: FC = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const date = useGetNowTime(TIME_DATA_FORMAT)
  const currentLanguage = useAppSelector(selectCurrentLanguage)

  const Icon = () =>
    currentLanguage ? <Flag country={currentLanguage.code} /> : null

  const handleClickSettings = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalSettings(true))
  }, [dispatch])

  const handleClickChangeLanguage = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalChangeLanguage(true))
  }, [dispatch])

  const { OnboardingAttrWrapper } = useOnboardingAttr(
    EWelcomeOnboardingDataAttrs.SITE_SETTINGS,
    EOnboardingType.MAIN_PAGE_WELCOME
  )

  const handleSupport = () => {
    window.LC_API.open_chat_window()
  }

  return (
    <StyledAdditional>
      <StyledSupport
        data-test-id={ETestData.TestFixedHeaderAdditionalSupport}
        onClick={handleSupport}
      >
        <IconHeadMic
          colorProps={{
            name: EColorsNames.Primary,
            type: EColorsTypes.CUSTOM,
            value: 6
          }}
        />
        {t('support')}
      </StyledSupport>
      <StyledLink
        data-test-id={ETestData.TestFixedHeaderAdditionalRules}
        to={ERoutes.Rules}
      >
        {t('rules')}
      </StyledLink>
      {Icon && (
        <StyledFlagButton
          data-test-id={ETestData.TestFixedHeaderAdditionalLanguage}
          icon={Icon}
          view={EButtonViews.NONE}
          onClick={handleClickChangeLanguage}
        />
      )}
      <ChangeLanguage />
      <OnboardingAttrWrapper>
        <StyledIconSetting
          colorProps={{ name: EColorsNames.Primary, value: 30 }}
          size={14}
          testData={ETestData.TestFixedHeaderAdditionalSetting}
          onClick={handleClickSettings}
        />
      </OnboardingAttrWrapper>
      <StyledTime>{date}</StyledTime>
    </StyledAdditional>
  )
}
