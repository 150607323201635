import { EventListInfo, ListKey } from 'astra-core/containers/EventsProvider'
import { ActiveEventStatus } from 'betweb-openapi-axios'
import { TFunction } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { TimePeriodOption } from 'astra-core/containers/EventsProvider/hooks/list-filters'

export const LINE_EVENTS_LIST_KEY: ListKey = 'line'

export const LINE_EVENTS_LIST_CONFIG: EventListInfo['config'] = {
  statuses: [
    ActiveEventStatus.Suspended,
    ActiveEventStatus.Trading,
    ActiveEventStatus.Planning,
    ActiveEventStatus.Open
  ].map((el) => el.replace('EVENT_', '')) as unknown as ActiveEventStatus[],
  sort: ['scheduledAt.asc'],
  pageSize: 20,
  live: false,
  hasLineOutcomes: true
}

export const LINE_POLLING_INTERVAL = 5000

export const LINE_PERIOD_OPTIONS = (
  t?: TFunction<'translation'>
): (TimePeriodOption & {
  additionalLabel?: string
  testId?: string
  styles?: {
    isBottomBorder?: boolean
  }
})[] => [
  {
    id: '1',
    label: t?.('all time') || '',
    value: 0,
    testId: 'allTime'
  },
  {
    id: '2',
    label: t?.('today') || '',
    value: '0-days',
    additionalLabel: dayjs().format('D MMMM'),
    testId: 'today'
  },
  {
    id: '3',
    label: t?.('tomorrow') || '',
    value: '1-days',
    testId: 'tomorrow'
  },
  {
    id: '4',
    label: t?.('day after tomorrow') || '',
    value: '2-days',
    styles: {
      isBottomBorder: true
    },
    testId: 'dayAfterTomorrow'
  },
  {
    id: '5',
    label: t?.('for 2 hours') || '',
    value: 2,
    testId: 'for2Hours'
  },
  {
    id: '6',
    label: t?.('for 4 hours') || '',
    value: 4,
    testId: 'for4Hours'
  },
  {
    id: '7',
    label: t?.('for 8 hours') || '',
    value: 8,
    testId: 'for8Hours'
  },
  {
    id: '8',
    label: t?.('for 12 hours') || '',
    value: 12,
    testId: 'for12Hours'
  },
  {
    id: '9',
    label: t?.('per 24 hours') || '',
    value: 24,
    styles: {
      isBottomBorder: true
    },
    testId: 'per24Hours'
  },
  {
    id: '10',
    label: t?.('for 2 days') || '',
    value: 48,
    testId: 'for2Days'
  },
  {
    id: '11',
    label: t?.('for 3 days') || '',
    value: 72,
    testId: 'for3Days'
  },
  {
    id: '12',
    label: t?.('by week') || '',
    value: 168,
    testId: 'byWeek'
  }
]
