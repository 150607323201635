import React from 'react'
import { useParams } from 'react-router-dom'
import { LiveParams } from 'astra-core/containers/LiveProvider'

import { ELayoutsList, Layout } from 'widgets/Layout'
import { EventsPageProvider } from 'widgets/events-page-provider'
import { EventsPageHeader } from 'widgets/events-page-header'

import {
  LIVE_EVENTS_LIST_CONFIG,
  LIVE_EVENTS_LIST_KEY
} from '../../model/constants'
import { LiveContainer } from '../LiveContainer'

export const PageLiveEventsV2 = () => {
  const { sportId } = useParams<LiveParams>()

  return (
    <Layout type={ELayoutsList.SPORT}>
      <EventsPageProvider
        config={LIVE_EVENTS_LIST_CONFIG}
        listKey={LIVE_EVENTS_LIST_KEY}
      >
        <EventsPageHeader listKey={LIVE_EVENTS_LIST_KEY} sportId={sportId} />
        <LiveContainer />
      </EventsPageProvider>
    </Layout>
  )
}
