import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Event, MarketGroup } from 'betweb-openapi-axios'
import isEqual from 'react-fast-compare'
import {
  selectMarketGroupsByEvent,
  selectMarketGroupsByEventV3,
  selectMarketGroupsByEventV4
} from 'astra-core/containers/EventsProvider/selectors'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { outcomesContainerActions } from 'containers/OutcomesContainer/slice'
import { getSelectedOutcomesGroupId } from 'containers/OutcomesContainer/selectors'
import { ETestData, getTruncateAfterDash } from 'shared/lib/testData'
import { useAppSelector, useAppDispatch } from 'store'

import { StyledTabItemWrapper } from './OutcomesGroupsTabs.styled'

type OutcomesFilterTabsProps = {
  event: Event
}

export const OutcomesGroupsTabs: FC<OutcomesFilterTabsProps> = ({ event }) => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const newOutcomesEnabled = useFeatureFlag(EFeatureFlags.NEW_OUTCOMES_ENABLED)
  const newOutcomesStructEnabled = useFeatureFlag(
    EFeatureFlags.NEW_OUTCOMES_STRUCT_ENABLED
  )
  const selectedMarketGroupId = useAppSelector((state) =>
    getSelectedOutcomesGroupId(state, event.id)
  )
  const marketGroups = useAppSelector((state) => {
    if (newOutcomesStructEnabled) {
      return selectMarketGroupsByEventV4(state, event.id)
    }

    if (newOutcomesEnabled) {
      return selectMarketGroupsByEventV3(state, event.id)
    }

    return selectMarketGroupsByEvent(state, event.id)
  }, isEqual)

  useEffect(() => {
    if (
      !!selectedMarketGroupId &&
      !marketGroups.find(
        (marketGroup) => marketGroup.id === selectedMarketGroupId
      )
    ) {
      // If the selected market group becomes empty, select 'all' market group
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup({
          marketGroup: null,
          eventId: event.id
        })
      )
    }
  }, [dispatch, marketGroups, selectedMarketGroupId, event.id])

  useEffect(() => {
    return () => {
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup({
          marketGroup: null,
          eventId: event.id
        })
      )
    }
  }, [dispatch, event.id])

  const handleSportTypeClick = useCallback(
    (marketGroup: MarketGroup | null) => () =>
      dispatch(
        outcomesContainerActions.setSelectedOutcomesGroup({
          marketGroup,
          eventId: event.id
        })
      ),
    [dispatch, event.id]
  )
  return (
    <Tabs variant={2} combined>
      {!!marketGroups.length && (
        <StyledTabItemWrapper>
          <TabsItem
            active={!selectedMarketGroupId}
            handleTabChange={handleSportTypeClick(null)}
            testData={ETestData.TestEventTabAll}
          >
            {t('all')}
          </TabsItem>
        </StyledTabItemWrapper>
      )}

      {marketGroups.map((group) => (
        <StyledTabItemWrapper key={group.id}>
          <TabsItem
            testData={`${ETestData.TestEventTab}-${getTruncateAfterDash(
              group?.id
            )}`}
            active={group.id === selectedMarketGroupId}
            handleTabChange={handleSportTypeClick(group)}
          >
            {group?.name}
          </TabsItem>
        </StyledTabItemWrapper>
      ))}
    </Tabs>
  )
}
