import { selectBasketAppliedCoupon } from 'astra-core/containers/BasketProvider/selectors'
import { selectCurrencyMinBet } from 'astra-core/containers/CommonDataProvider/selectors'
import { formatAmountLocaleString } from 'astra-core/utils/format'
import React, { useMemo, FC, useEffect } from 'react'
import { useContainerDimensions } from 'astra-core/hooks/useContainerDimensions'

import { useAppSelector } from 'store'

import { StyledMinMaxHint } from './MinMaxHint.styled'

interface MinMaxHintProps {
  maxBet: number
  setMaxBetBlockWidth: React.Dispatch<React.SetStateAction<number>>
}

export const MinMaxHint: FC<MinMaxHintProps> = ({
  maxBet,
  setMaxBetBlockWidth
}) => {
  const minBet = useAppSelector(selectCurrencyMinBet)
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)

  const [dimensions, inputMaxBetRef] = useContainerDimensions()

  useEffect(() => {
    if (dimensions?.width) {
      setMaxBetBlockWidth(dimensions.width)
    }
  }, [dimensions, setMaxBetBlockWidth])

  const minMaxBet = useMemo(() => {
    return appliedCoupon
      ? ''
      : `${formatAmountLocaleString({
          value: minBet
        })} - ${formatAmountLocaleString({
          value: maxBet
        })}`
  }, [minBet, maxBet, appliedCoupon])

  return <StyledMinMaxHint ref={inputMaxBetRef}>{minMaxBet}</StyledMinMaxHint>
}
