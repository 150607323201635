import { useCallback } from 'react'
import {
  getHasNextPage,
  getIsNextPageLoading
} from 'astra-core/containers/EventsProvider/selectors'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'
import {
  EventsApiGetEventsV2Request,
  ListKey
} from 'astra-core/containers/EventsProvider/types'

import { useAppDispatch, useAppSelector } from 'store'

interface UseVirtualScrollActionsParams {
  listKey: ListKey
  request: EventsApiGetEventsV2Request
  paramsExist: boolean
}

interface UseVirtualScrollActionsReturn {
  hasNextPage: boolean
  isNextPageLoading: boolean
  loadPrevPage: () => void
  loadNextPage: () => void
  resetEventListScroll: () => void
}

type UseVirtualScrollActions = (
  params: UseVirtualScrollActionsParams
) => UseVirtualScrollActionsReturn

export const useVirtualScrollActions: UseVirtualScrollActions = ({
  listKey,
  request,
  paramsExist
}) => {
  const dispatch = useAppDispatch()
  const hasNextPage = useAppSelector((state) => getHasNextPage(state, listKey))
  const isNextPageLoading = useAppSelector((state) =>
    getIsNextPageLoading(state, listKey)
  )

  const loadNextPage = useCallback(() => {
    const requestEnabled = hasNextPage && !isNextPageLoading && paramsExist

    if (requestEnabled) {
      dispatch(
        eventsProviderActions.fetchEventListNextPage({
          listKey,
          request
        })
      )
    }
  }, [dispatch, hasNextPage, isNextPageLoading, listKey, request, paramsExist])

  const resetEventListScroll = useCallback(() => {
    dispatch(
      eventsProviderActions.resetEventListScroll({
        listKey
      })
    )
  }, [dispatch, listKey])

  const loadPrevPage = useCallback(() => {
    if (!paramsExist) return

    dispatch(
      eventsProviderActions.fetchEventListPrevPage({
        listKey,
        request
      })
    )
  }, [dispatch, listKey, request, paramsExist])

  return {
    hasNextPage,
    isNextPageLoading,
    loadPrevPage,
    loadNextPage,
    resetEventListScroll
  }
}
