import React, { FC, PropsWithChildren } from 'react'

import { useInitEventsList } from '../hooks/useInitEventsList'

import { EventsPageProviderProps } from './EventsPageProvider.types'

export const EventsPageProvider: FC<
  PropsWithChildren<EventsPageProviderProps>
> = (props) => {
  const { listKey, config, children } = props
  const { isEventListInitialized } = useInitEventsList({
    listKey,
    config
  })

  if (!isEventListInitialized) {
    return null
  }

  return <>{children}</>
}
