import React, { FC, Fragment, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ESportsCodes,
  MainOutcomeTypes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useGetHotProbsSportMarketOutcomeTypes } from 'astra-core/hooks/useGetHotProbsSportMarketOutcomeTypes'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { ETestData } from 'shared/lib/testData'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { RootState } from 'shared/types/store'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import {
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/Button'
import { Marquee } from 'shared/ui/Marquee'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { TableBetsContext } from 'pages/page-live-events/Line/components/ui/TableBets/TableBets.context'
import { ButtonBack } from 'pages/page-live-events/Line/components/ui/LineHeader/components'
import { useAppSelector, useAppDispatch } from 'store'

import { ETableHeadVariant, ITableHeadProps } from './TableBetsHead.types'
import {
  StyledLineTableHead,
  StyledLineTableHeadChevron,
  StyledLineTableHeadChevronButton,
  StyledSportIcon,
  StyledTableHeadColumn,
  StyledTableHeadColumnTitle
} from './TableBetsHead.styled'

export const TableBetsHead: FC<ITableHeadProps> = ({
  tournament,
  isShowOdds,
  isShowCount,
  isOutcomesOpened,
  isSearch,
  setIsOutcomesOpened,
  variant = ETableHeadVariant.ONE
}) => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const {
    name: sportName,
    code: sportCode,
    genericSportId
  } = useAppSelector(
    (state: RootState) =>
      !!tournament && selectSport(state, tournament?.sportId)
  ) || {}

  const { tableEventHead, isLinkHead } = useContext(TableBetsContext)
  const isGenericEnabled = useFeatureFlag(EFeatureFlags.GENERIC_SPORT_ENABLED)

  const isDisplaySportIcon =
    tableEventHead?.withSportIcon && tournament?.sportId

  const toggleOutcomesOpen = useCallback(
    (e) => {
      e.preventDefault()
      if (setIsOutcomesOpened) {
        setIsOutcomesOpened(!isOutcomesOpened)
      }
    },
    [isOutcomesOpened, setIsOutcomesOpened]
  )

  const resetLineFilter = useCallback(() => {
    dispatch(lineTournamentsContainerActions.resetLineFilter())
  }, [dispatch])

  const buttonBackProps = useMemo(() => {
    let link = `/line/${tournament?.sportId}`

    // TODO: OL-553 Remove isGenericEnabled check after relese
    if (isGenericEnabled && genericSportId) {
      link = `/line/${genericSportId}`
    }

    return {
      link,
      title: tournament?.name,
      onButtonClickAction: resetLineFilter
    }
  }, [
    resetLineFilter,
    tournament?.name,
    tournament?.sportId,
    isGenericEnabled,
    genericSportId
  ])
  const { outcomeTypes } = useGetHotProbsSportMarketOutcomeTypes({
    sportId: tournament?.sportId
  })

  return (
    <StyledLineTableHead variant={variant}>
      <StyledTableHeadColumnTitle>
        <ButtonBack
          link={isLinkHead ? buttonBackProps.link : null}
          testData={ETestData.TestEventBetsButtonBack}
          title={buttonBackProps.title || ''}
          onButtonClickAction={buttonBackProps.onButtonClickAction}
        >
          {isDisplaySportIcon && (
            <StyledSportIcon>
              <SportIcon
                size={16}
                sportCode={sportCode as ESportsCodes}
                isActive
              />
            </StyledSportIcon>
          )}
          {tableEventHead?.withSportName && `${sportName}. `}
          <Marquee
            isMarqueeCustomColors={
              variant === ETableHeadVariant.ONE && !isSearch
            }
            id={tournament?.id}
          >
            {tournament?.name || t('event')}
          </Marquee>
        </ButtonBack>
      </StyledTableHeadColumnTitle>

      {outcomeTypes?.map((outcome) => (
        <Fragment key={outcome.mainOutcomeType}>
          {outcome.mainOutcomeType === MainOutcomeTypes.TO && (
            <StyledTableHeadColumn hide={isShowOdds} viewType="total">
              {t('total')}
            </StyledTableHeadColumn>
          )}

          {outcome.mainOutcomeType === MainOutcomeTypes.TOO && (
            <StyledTableHeadColumn
              hide={isShowOdds}
              sportCode={sportCode as ESportsCodes}
              viewType="total"
            >
              {t('total overtime')}
            </StyledTableHeadColumn>
          )}

          <StyledTableHeadColumn
            hide={isShowOdds}
            viewType={outcome.mainOutcomeType}
          >
            {t(...outcome.title)}
          </StyledTableHeadColumn>
        </Fragment>
      ))}

      {isShowCount ? (
        <StyledLineTableHeadChevron>
          <StyledLineTableHeadChevronButton
            iconProps={{
              positionSide: EButtonIconPositionSides.Right,
              twist: isOutcomesOpened ? 180 : 0,
              size: 10
            }}
            icon={IconChevronDown}
            size={EButtonSizes.S}
            view={EButtonViews.SECONDARY}
            onClick={toggleOutcomesOpen}
          >
            {isShowCount}
          </StyledLineTableHeadChevronButton>
        </StyledLineTableHeadChevron>
      ) : (
        <StyledLineTableHeadChevron />
      )}
    </StyledLineTableHead>
  )
}
