import React, { memo } from 'react'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { useAppSelector } from 'store'

import { UnauthorizedProfileMenu } from './components/UnauthorizedProfileMenu'
import { AuthorizedProfileMenu } from './components/ProfileMenu'
import { StyledLkMenu } from './ProfileMenu.styled'

export const ProfileMenu = memo(() => {
  const user = useAppSelector(selectAccountUser)

  return (
    <StyledLkMenu>
      {user ? <AuthorizedProfileMenu /> : <UnauthorizedProfileMenu />}
    </StyledLkMenu>
  )
})
