import React, { FC, useContext, useMemo } from 'react'
import { selectSport } from 'astra-core/containers/CommonDataProvider'
import { Event } from 'betweb-openapi-axios'

import { useAppSelector } from 'store'
import {
  EventContext,
  IEventContext
} from 'pages/page-event/Event/Event.context'

import { STATS_SPORTS } from './constants'

export const Stats: FC<{ eventId: Event['id'] }> = (props) => {
  const { event } = useContext(EventContext) as IEventContext

  const { code: sportCode } =
    useAppSelector((state) => selectSport(state, +event.tournament.sportId)) ||
    {}

  const SportStats = useMemo(
    () => !!sportCode && STATS_SPORTS[sportCode],
    [sportCode]
  )

  return SportStats ? <SportStats {...props} /> : null
}
