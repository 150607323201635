import styled, { css } from 'styled-components'

import { StyledFooterWrapper } from 'entities/footer-of-page/ui/footer-of-page/footer-of-page.styled'

export const LineEvents = styled.div<{ $isEmpty?: boolean }>`
  flex: 1;
  ${({ $isEmpty }) => {
    if ($isEmpty) {
      return css`
        margin: auto;

        & + ${StyledFooterWrapper} {
          margin-top: initial;
        }
      `
    }
  }}
`
