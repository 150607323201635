import React, { FC, useMemo } from 'react'

import { getRenderedStatistics } from '../../utils'
import {
  EEventStatisticsCodes,
  TGetRenderedStatisticsStatistics
} from '../../EventStatistics.types'
import { EventStatisticSelectRender } from '../EventStatisticSelectRender'

import { IEventStatisticBlockOtherInfoProps } from './EventStatisticBlockOtherInfo.types'
import { StyledEventStatisticBlockOtherInfo } from './EventStatisticBlockOtherInfo.styled'

export const EventStatisticBlockOtherInfo: FC<
  IEventStatisticBlockOtherInfoProps
> = ({ event, adaptiveScoreByPeriods, statisticsCodes }) => {
  const renderedStatistics = useMemo(
    () =>
      getRenderedStatistics(
        event.statistics as TGetRenderedStatisticsStatistics,
        statisticsCodes
      )?.filter((item) => !!item.value),
    [event.statistics, statisticsCodes]
  )

  if (renderedStatistics.length) {
    return (
      <StyledEventStatisticBlockOtherInfo>
        {renderedStatistics.map((item) => (
          <EventStatisticSelectRender
            adaptiveScoreByPeriods={adaptiveScoreByPeriods}
            code={item.code as EEventStatisticsCodes}
            key={item.code}
            sportId={event.tournament.sportId}
            value={item.value}
          />
        ))}
      </StyledEventStatisticBlockOtherInfo>
    )
  } else {
    return null
  }
}
