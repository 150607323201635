import React from 'react'

import { useAppSelector } from 'store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { selectLineIsEmpty } from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { LineEvents } from 'pages/page-live-events/Line/Line'

import { LineProps } from './types'
import { useLineContainerData } from './hooks'

export function LineContainer(_props: LineProps) {
  const isEmpty = useAppSelector(selectLineIsEmpty)

  useLineContainerData()

  return isEmpty ? <LoaderSpinner /> : <LineEvents />
}
