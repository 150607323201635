import React, { memo } from 'react'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'

import { useAppSelector } from 'store'
import { UserAccountBonus } from 'widgets/user-account-bonus'

import { useOnboardingAttrs } from './hooks'
import {
  FavouritesButton,
  HistoryBetsButton,
  NotificationsButton,
  PersonalAccountButton
} from './components'
import {
  StyledButtonBalance,
  StyledComponentOfAuthorized,
  StyledPadding
} from './ComponentOfAuthorized.styled'
import { BonusCouponButton } from './components/BonusCouponButton'

export const ComponentOfAuthorized = memo(() => {
  const isBalanceHidden = useAppSelector((state) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  const {
    onboardingAttrFavourite,
    onboardingAttrPersonalAccount,
    onboardingAttrBalance,
    onboardingAttrBonus,
    onboardingAttrMessages
  } = useOnboardingAttrs()

  return (
    <StyledComponentOfAuthorized>
      <StyledPadding>
        <HistoryBetsButton />
      </StyledPadding>

      <StyledPadding {...onboardingAttrFavourite}>
        <FavouritesButton />
      </StyledPadding>

      <StyledPadding {...onboardingAttrMessages}>
        <NotificationsButton />
      </StyledPadding>

      <StyledPadding>
        <BonusCouponButton />
      </StyledPadding>

      {/* Check if we should show the bonus container, because it returns null if isBalanceHidden and it will break the onboarding */}
      {!isBalanceHidden && (
        <StyledPadding {...onboardingAttrBonus}>
          <UserAccountBonus />
        </StyledPadding>
      )}

      <StyledPadding {...onboardingAttrBalance}>
        <StyledButtonBalance />
      </StyledPadding>

      <StyledPadding {...onboardingAttrPersonalAccount}>
        <PersonalAccountButton />
      </StyledPadding>
    </StyledComponentOfAuthorized>
  )
})
