import React, { FC, useCallback } from 'react'
import { selectAccountBonus } from 'astra-core/containers/AccountProvider'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { ETabTypes } from 'astra-core/containers/BalanceProvider'
import { useHistory } from 'react-router-dom'
import { formatAmountLocaleString } from 'astra-core'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import { ERoutes } from 'shared/types/routes'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'

import { IUserAccountBalanceProps } from './user-account-bonus.types'
import { StyledUserAccountBonus } from './user-account-bonus.styles'

export const UserAccountBonus: FC<IUserAccountBalanceProps> = ({
  formatPrice,
  ...props
}) => {
  const bonus = useAppSelector(selectAccountBonus)!
  const history = useHistory()

  const isBalanceHidden = useAppSelector((state) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  const handleRedirectList = useCallback(
    () => history.push(`${ERoutes.ProfileBalance}${ETabTypes.Bonus}`),
    [history]
  )

  if (isBalanceHidden) {
    return null
  }

  return (
    <StyledUserAccountBonus
      data-test-id={ETestData.TestMainHeaderUserAccountBonus}
      onClick={handleRedirectList}
      {...props}
    >
      {formatAmountLocaleString({
        placeholder: '...',
        ...formatPrice,
        value: bonus
      })}
      <IconBonus />
    </StyledUserAccountBonus>
  )
}
