import React from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import { ELayoutsList, Layout } from 'widgets/Layout'
import { getRouteParamAtIndex } from 'shared/lib/routes/helpers'
import {
  lineTournamentsContainerActions,
  reducerLine,
  sliceKeyLine
} from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { lineTournamentsContainerSaga } from 'pages/page-line-tournament/LineTournamentsContainer/saga'
import { ELineHeaderTypes } from 'pages/page-live-events/Line/components/ui/LineHeader/LineHeader.types'

import { PageLineEventsV2 } from '../line-events-V2'

import { LineContainer } from './LineContainer'
import { getFetchLineReqData } from './LineContainer/utils'

export const PageLine = () => {
  useInjectReducer({ key: sliceKeyLine, reducer: reducerLine })
  useInjectSaga({ key: sliceKeyLine, saga: lineTournamentsContainerSaga })

  const virtualScrollEnabled = useFeatureFlag(EFeatureFlags.NEW_EVENTS_ENDPOINT)

  if (virtualScrollEnabled) {
    return <PageLineEventsV2 />
  }

  return (
    <Layout type={ELayoutsList.SPORT} typeHeader={ELineHeaderTypes.LINE}>
      <LineContainer />
    </Layout>
  )
}

export const pageLinePrefetch = ({ store, req }) => {
  store.dispatch(
    lineTournamentsContainerActions.fetchLine({
      reqData: getFetchLineReqData({
        tournamentId: getRouteParamAtIndex(req.params, 2)
      }),
      params: {
        withCompetitorsLogo: true,
        withLinkedEvents: true
      }
    })
  )
}
