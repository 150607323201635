import { useMemo } from 'react'
import {
  ClientFilteredProbsMapWithOutcomeIds,
  MainOutcomeType,
  selectClientFilteredProbsMap,
  selectMainOutcomeTypes
} from 'astra-core/containers/CommonDataProvider'
import { useProbs } from 'astra-core/hooks/useEvents'
import { Event } from 'betweb-openapi-axios'
import { getMainOutcomeProbability } from 'astra-core/utils/outcomes'

import { useAppSelector } from 'store'

export const useGetEventProbability = ({
  event,
  outcomeTypesName
}: {
  event: Event
  outcomeTypesName: Array<MainOutcomeType>
}) => {
  const mainOutcomeTypes = useAppSelector(selectMainOutcomeTypes)
  const clientFilteredProbsMap = useAppSelector(selectClientFilteredProbsMap) // To filter Handicaps and Totals on client side to show in the events list as "main probs"

  const probs = useProbs(
    event,
    clientFilteredProbsMap as ClientFilteredProbsMapWithOutcomeIds
  )

  const eventProbability = useMemo(() => {
    const matchingMainOutcomeType = outcomeTypesName.find((mainOutcomeType) =>
      getMainOutcomeProbability({ mainOutcomeType, probs, mainOutcomeTypes })
    )
    return getMainOutcomeProbability({
      mainOutcomeType: matchingMainOutcomeType,
      probs,
      mainOutcomeTypes
    })
  }, [outcomeTypesName, probs, mainOutcomeTypes])

  return { eventProbability }
}
