import { getSportById } from 'astra-core/containers/CommonDataProvider'
import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Sport } from 'betweb-openapi-axios'

import { selectFilterSportId } from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { LineTournamentsParams } from 'pages/page-line-tournament/LineTournamentsContainer/types'
import { GenericSportsList } from 'widgets/generic-sport'
import { useAppSelector, useAppDispatch } from 'store'

import { LineTournamentSportListProps } from './LineTournamentSportList.types'

export const LineTournamentSportList: FC<LineTournamentSportListProps> = ({
  sports
}) => {
  const sportIdFilter = useAppSelector(selectFilterSportId)
  const { sportId } = useParams<LineTournamentsParams>()

  const parentSport = useAppSelector((state) => getSportById(state, +sportId))

  const dispatch = useAppDispatch()
  const onFilterClick = useCallback(
    (sportId?: Sport['id']) => {
      dispatch(
        lineTournamentsContainerActions.setFilterSportId({
          sportId
        })
      )
    },
    [dispatch]
  )
  return (
    <GenericSportsList
      activeSportId={sportIdFilter}
      childrenSports={sports}
      parentSport={parentSport!}
      onClickItem={onFilterClick}
    />
  )
}
