import { Event } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'

const selectSliceState = (state: RootState) => {
  return state.eventsContainer
}

const selectEventsUiState = (state: RootState) => {
  return selectSliceState(state).ui
}

export const selectIsEventOutcomesExpanded = (
  state: RootState,
  eventId: Event['id']
) => {
  return selectEventsUiState(state).expandedOutcomes.byId[eventId] ?? false
}
