import { useParams } from 'react-router-dom'
import {
  eventsProviderActions,
  getEventListFilters,
  getIsEventListLoading,
  getIsEventListPolling
} from 'astra-core/containers/EventsProvider'
import { useCallback, useEffect } from 'react'

import { useInterval } from 'hooks'
import { useAppDispatch, useAppSelector } from 'store'

import { LIVE_EVENTS_LIST_KEY, LIVE_POLLING_INTERVAL } from '../constants'

export const usePollLiveEvents = () => {
  const { sportId } = useParams<{ sportId: string }>()
  const dispatch = useAppDispatch()
  const filters = useAppSelector((state) =>
    getEventListFilters(state, LIVE_EVENTS_LIST_KEY)
  )
  const isLoading = useAppSelector((state) =>
    getIsEventListLoading(state, LIVE_EVENTS_LIST_KEY)
  )

  const isPolling = useAppSelector((state) =>
    getIsEventListPolling(state, LIVE_EVENTS_LIST_KEY)
  )

  useEffect(() => {
    if (sportId) {
      dispatch(
        eventsProviderActions.fetchEventList({
          listKey: LIVE_EVENTS_LIST_KEY,
          request: {
            sportIds: [+sportId],
            scheduledToNow: true
          }
        })
      )
    }
  }, [dispatch, sportId, filters])

  const shouldTick = !(isLoading || isPolling)

  const pollLiveEvents = useCallback(() => {
    if (sportId && shouldTick) {
      dispatch(
        eventsProviderActions.pollEventList({
          listKey: LIVE_EVENTS_LIST_KEY,
          request: {
            sportIds: [+sportId],
            scheduledToNow: true
          }
        })
      )
    }
  }, [dispatch, sportId, shouldTick])

  useInterval(pollLiveEvents, LIVE_POLLING_INTERVAL, false)
}
