import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { ESelectInputsNames } from 'containers/CalendarEventsContainer/types'
import {
  selectFilterInputSearch,
  selectFilterStream,
  selectLayoutSelectInputs
} from 'containers/CalendarEventsContainer/selectors'
import { ESelectView } from 'shared/ui/Select'
import { IconStreamFilled } from 'shared/ui/Icon/General/IconStreamFilled'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import { EInputView } from 'shared/ui/Input'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'
import { useAppSelector, useAppDispatch } from 'store'

import { OPTIONS_SELECT_ORDER, OPTIONS_SELECT_PERIOD } from './constants'
import { getSelectArrayItem } from './utils'
import {
  StyledFilters,
  StyledFilterStream,
  StyledIconRightButton,
  StyledInputFind,
  StyledInputFindWrapper,
  StyledSelectPeriod
} from './Filters.styled'

export const Filters = memo(() => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const layoutSelectInputs = useAppSelector(selectLayoutSelectInputs)
  const inputSearchFilter = useAppSelector(selectFilterInputSearch)

  const optionsSelect = useMemo(
    () => ({
      order: OPTIONS_SELECT_ORDER(t),
      period: OPTIONS_SELECT_PERIOD(t)
    }),
    [t]
  )

  const defaultValuesSelect = useMemo(
    () => ({
      order: getSelectArrayItem(
        OPTIONS_SELECT_ORDER(t),
        layoutSelectInputs.order
      ),
      period:
        getSelectArrayItem(
          OPTIONS_SELECT_PERIOD(t),
          layoutSelectInputs.period
        ) || OPTIONS_SELECT_PERIOD(t)[0]
    }),
    [layoutSelectInputs.order, layoutSelectInputs.period, t]
  )

  const handleSelect = useCallback(
    (selectName: ESelectInputsNames) => (data) => {
      if (selectName === ESelectInputsNames.Period) {
        dispatch(calendarEventsContainerActions.setSelectPeriod(data))
      } else {
        dispatch(calendarEventsContainerActions.setSelectOrder(data))
      }
    },
    [dispatch]
  )

  const handleInputSearch = useCallback(
    ({ target }) =>
      dispatch(
        calendarEventsContainerActions.setFilterInputSearch(target.value)
      ),
    [dispatch]
  )

  const clearInputSearch = useCallback(() => {
    dispatch(calendarEventsContainerActions.setResetFilterInputSearch())
  }, [dispatch])

  return (
    <StyledFilters>
      {/* <FilterStream /> */}

      {/* <StyledSelectOrder
        value={defaultValuesSelect.order}
        onChange={handleSelect(ESelectInputsNames.Order)}
        options={optionsSelect.order}
        view={ESelectView.Mini}
      /> */}

      <StyledSelectPeriod
        customHeight={24}
        options={optionsSelect.period}
        placeholder={t('select')}
        value={defaultValuesSelect.period}
        view={ESelectView.Mini}
        onChange={handleSelect(ESelectInputsNames.Period)}
      />
      <StyledInputFindWrapper>
        <StyledInputFind
          icon={IconSearch}
          placeholder={t('search')}
          value={inputSearchFilter}
          view={EInputView.SECONDARY}
          onChange={handleInputSearch}
        />
      </StyledInputFindWrapper>
      <StyledIconRightButton onClick={clearInputSearch}>
        {!!inputSearchFilter.length && (
          <IconClose
            colorProps={{ name: EColorsNames.Primary, value: 50 }}
            size={10}
          />
        )}
      </StyledIconRightButton>
    </StyledFilters>
  )
})

export const FilterStream = () => {
  const dispatch = useAppDispatch()
  const isActive = useAppSelector(selectFilterStream)

  const handleClickStream = useCallback(
    () => dispatch(calendarEventsContainerActions.toggleFilterStream()),
    [dispatch]
  )
  return (
    <StyledFilterStream $isActive={isActive} onClick={handleClickStream}>
      <IconStreamFilled />
    </StyledFilterStream>
  )
}
