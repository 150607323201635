import React, { FC } from 'react'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'

import { useAppSelector } from 'store'

import {
  StyledMatchPhaseName,
  StyledMatchPhaseWrapper
} from './EventMatchPhase.styled'
import { IEventMatchPhaseProps } from './EventMatchPhase.types'

export const EventMatchPhase: FC<IEventMatchPhaseProps> = ({ value }) => {
  const matchPhase = useAppSelector((state) =>
    selectMatchPhase(state, Number(value))
  )

  return (
    <StyledMatchPhaseWrapper>
      <StyledMatchPhaseName>{matchPhase?.name}</StyledMatchPhaseName>
    </StyledMatchPhaseWrapper>
  )
}
