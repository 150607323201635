import React, { FC, useCallback } from 'react'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { formatAmountLocaleString } from 'astra-core'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { ERoutes } from 'shared/types/routes'
import { IconBalanceWithPlus } from 'shared/ui/Icon/General/IconBalanceWithPlus'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconAddCircleFilled } from 'shared/ui/Icon/General/IconAddCircleFilled'

import { StyledUserAccountBalance } from './user-account-balance.styled'
import { IUserAccountBalanceProps } from './user-account-balance.types'

export const UserAccountBalance: FC<IUserAccountBalanceProps> = ({
  topUpButton,
  formatPrice,
  ...props
}) => {
  const balance = useAppSelector(selectAccountBalance)
  const isBalanceHidden = useAppSelector((state) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )
  const currencyIcon = useGetCurrencyIcon()
  const routeRedirect = useRouteRedirect()

  const handleRedirectList = useCallback(
    () => routeRedirect({ route: ERoutes.ProfileBalance }),
    [routeRedirect]
  )

  const isDisplayTopUpButton = !(
    isBalanceHidden || topUpButton?.withoutTopUpButton
  )

  return (
    <StyledUserAccountBalance
      data-test-id={ETestData.TestMainHeaderUserAccountBalance}
      onClick={handleRedirectList}
      {...props}
    >
      {isBalanceHidden ? (
        <IconBalanceWithPlus
          colorProps={{
            name: EColorsNames.Primary,
            type: EColorsTypes.CUSTOM,
            value: 7
          }}
          size={20}
          {...topUpButton?.settings}
        />
      ) : (
        formatAmountLocaleString({
          placeholder: '...',
          currency: currencyIcon,
          ...formatPrice,
          value: balance
        })
      )}

      {isDisplayTopUpButton && (
        <IconAddCircleFilled
          colorProps={{
            name: EColorsNames.Primary,
            type: EColorsTypes.CUSTOM,
            value: 7
          }}
          size={15}
        />
      )}
    </StyledUserAccountBalance>
  )
}
