import React, { FC } from 'react'
import { selectSport } from 'astra-core/containers/CommonDataProvider'

import { useAppSelector } from 'store'

import { EEventStatisticsCodes } from '../../EventStatistics.types'
import {
  EEventStatisticScoreTypes,
  EventStatisticScore
} from '../EventStatisticScore'
import {
  EEventStatisticTimeTypes,
  EventStatisticTime
} from '../EventStatisticTime'

import { IEventStatisticSelectRenderProps } from './EventStatisticSelectRender.types'

export const EventStatisticSelectRender: FC<
  IEventStatisticSelectRenderProps
> = ({ value, code, sportId, adaptiveScoreByPeriods }) => {
  const sport = useAppSelector((state) => selectSport(state, sportId))

  switch (code) {
    case EEventStatisticsCodes.Score: {
      return (
        <EventStatisticScore
          type={EEventStatisticScoreTypes.DEFAULT}
          value={value}
          withDivider
        />
      )
    }

    case EEventStatisticsCodes.MatchPhase: {
      return (
        <EventStatisticScore
          type={EEventStatisticScoreTypes.MATCH_PHASE}
          value={value}
        />
      )
    }

    case EEventStatisticsCodes.ScoresByPeriods: {
      const valueArray = value?.split(', ') || []

      return (
        <EventStatisticScore
          isAdaptive={adaptiveScoreByPeriods}
          type={EEventStatisticScoreTypes.BY_PERIOD}
          value={valueArray}
        />
      )
    }

    case EEventStatisticsCodes.GameScore: {
      return (
        <EventStatisticScore
          sportCode={sport?.code}
          type={EEventStatisticScoreTypes.GAME}
          value={value}
        />
      )
    }

    case EEventStatisticsCodes.CurrentTime: {
      return (
        <EventStatisticTime
          type={EEventStatisticTimeTypes.CURRENT}
          value={value}
        />
      )
    }

    case EEventStatisticsCodes.RemainingTime: {
      return (
        <EventStatisticTime
          type={EEventStatisticTimeTypes.REMAINING}
          value={value}
        />
      )
    }

    case EEventStatisticsCodes.AddTime: {
      return (
        <EventStatisticTime
          type={EEventStatisticTimeTypes.ADDED}
          value={value}
        />
      )
    }

    case EEventStatisticsCodes.ScoresInfo: {
      return (
        <EventStatisticScore
          type={EEventStatisticScoreTypes.INFO}
          value={value}
        />
      )
    }

    default: {
      return null
    }
  }
}
