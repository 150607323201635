import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { useQuickAmountsSettings } from 'astra-core/containers/SettingsProvider/useQuickAmountsSettings'
import { calculatePercentOfBalance } from 'og-web-core/utils/basket'
import { selectCurrencyMinBet } from 'astra-core/containers/CommonDataProvider'
import { getDefaultQuickAmountsSettings } from 'og-web-core/utils/settings/settings'
import { QuickAmountsType } from 'betweb-openapi-axios'
import { adjustAmountForFrontend } from 'astra-core'

import { useAppSelector } from 'store'

import { BasketQuickAmountsProps } from './BasketQuickAmounts.types'
import * as S from './BasketQuickAmounts.styled'

export const BasketQuickAmounts: FC<BasketQuickAmountsProps> = memo((props) => {
  const { onChange, stakeAmount } = props
  const minBet = useAppSelector(selectCurrencyMinBet)
  const [activeQuickAmount, setActiveQuickAmount] = useState<number | null>(
    null
  )
  const currencyIcon = useGetCurrencyIcon()
  const balance = useAppSelector(selectAccountBalance)

  const defaultSettings = getDefaultQuickAmountsSettings(minBet)
  const { quickAmountsSettings } = useQuickAmountsSettings(defaultSettings)

  const { fixedAmounts, balancePercents, quickAmountsType } =
    quickAmountsSettings
  const icon =
    quickAmountsType === QuickAmountsType.FixedAmounts ? currencyIcon : '%'

  useEffect(() => {
    setActiveQuickAmount(null)
  }, [quickAmountsType])

  const amounts = useMemo(() => {
    if (quickAmountsType === QuickAmountsType.FixedAmounts) {
      return fixedAmounts?.map((item) => adjustAmountForFrontend(item))
    }
    return balancePercents
  }, [balancePercents, fixedAmounts, quickAmountsType])

  const calculateAmountValue = (amount: number) =>
    quickAmountsType === QuickAmountsType.BalancePercents
      ? calculatePercentOfBalance(balance, amount)
      : amount

  const handleClickAmount = (amount: number) => {
    const value = calculateAmountValue(amount)

    setActiveQuickAmount(amount)
    onChange(String(value))
  }

  const isAmountActive = (amount: number) => {
    const value = calculateAmountValue(amount)

    return value === stakeAmount && activeQuickAmount === amount
  }

  return (
    <S.BasketQuickBetsAmountWrapper>
      {amounts?.map((item, index) => {
        return (
          <S.BasketQuickBetsAmountItemWrapper
            active={isAmountActive(item)}
            // eslint-disable-next-line react/no-array-index-key
            key={`${item} - ${index}`}
            onClick={() => handleClickAmount(item)}
          >
            <S.BasketQuickBetsAmountItem active={isAmountActive(item)}>
              {item} {icon}
            </S.BasketQuickBetsAmountItem>
          </S.BasketQuickBetsAmountItemWrapper>
        )
      })}
    </S.BasketQuickBetsAmountWrapper>
  )
})
