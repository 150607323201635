import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { selectIsQuickBetActive } from 'astra-core/containers/BetsProvider'

import { useAppSelector } from 'store'
import { BasketQuickBetButton } from 'widgets/right-container/Basket/BasketQuickBetButton/BasketQuickBetButton'
import { ETestData, getCreateTestData } from 'shared/lib/testData'
import { LayoutProps } from 'widgets/Layout/Layout.types'
import { Auth } from 'widgets/Auth/Auth'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { Header } from 'widgets/header'
import { ProfileMenu } from 'widgets/profile-menu'
import { RightBasketContainer } from 'containers/BasketContainer'
import { ERoutes } from 'shared/types/routes'

import { StyledContentTitle } from '../Common'

import {
  StyledContentProfile,
  StyledLayoutProfile,
  StyledQuickBetButtonWrapper
} from './Profile.styled'

export const LayoutProfile: FC<LayoutProps> = memo(
  ({ pageTitle, children }) => {
    const [t] = useTranslation()
    const isQuickBetActive = useAppSelector(selectIsQuickBetActive)
    const { pathname } = useLocation()
    const basketQuickBetButtonVisible =
      isQuickBetActive && pathname === ERoutes.Profile

    return (
      <StyledLayoutProfile>
        <Auth />
        <Header />

        <ProfileMenu />

        <NotificationsPanel />

        <StyledContentProfile>
          {pageTitle?.text && (
            <StyledContentTitle
              data-test-id={getCreateTestData(
                ETestData.TestPageTitle,
                pageTitle.text
              )}
              {...pageTitle}
            >
              {t(pageTitle.text)}
            </StyledContentTitle>
          )}
          {children}
        </StyledContentProfile>
        {basketQuickBetButtonVisible && (
          <>
            <StyledQuickBetButtonWrapper>
              <BasketQuickBetButton />
            </StyledQuickBetButtonWrapper>
            <RightBasketContainer />
          </>
        )}
      </StyledLayoutProfile>
    )
  }
)
