import { selectConfig } from 'astra-core/containers/ConfigProvider/selectors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { IconAndroid } from 'shared/ui/Icon/General/IconAndroid'

import { useLandingAppUrl } from '../utils'

import {
  StyledButtonLink,
  StyledButtonWrapper,
  StyledText,
  StyledTitle
} from './open-landing.styled'

export const OpenLanding = () => {
  const [t] = useTranslation()

  const config = useAppSelector(selectConfig)
  const landingUrl = useLandingAppUrl(config)

  return (
    <StyledButtonWrapper>
      <StyledTitle>{t('application for')}</StyledTitle>
      <StyledButtonLink href={landingUrl} rel="noreferrer" target="_blank">
        <IconAndroid />
        <StyledText>Android</StyledText>
      </StyledButtonLink>
    </StyledButtonWrapper>
  )
}
