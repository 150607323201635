import React, { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import {
  getBeforePageEventIds,
  getPage1EventIds,
  getPage2EventIds
} from 'astra-core/containers/EventsProvider'

import { EventRow } from 'widgets/event-row'
import { useToggleState } from 'hooks/useToggleState'
import { TableBetsContext } from 'widgets/table-bets-context'
import { OutcomesFetchType } from 'entities/outcomes/model/types'
import { useAppSelector } from 'store'

import { RowWrapper } from '../RowWrapper'
import { formatEventDate } from '../../model/utils'

import { DateGroupProps } from './DateGroup.types'
import * as S from './DateGroup.styled'

export const DateGroup: FC<DateGroupProps> = ({
  eventGroup,
  page1StartRef,
  page1EndRef,
  page2StartRef,
  page2EndRef,
  beforeStartRef,
  beforeEndRef
}) => {
  const { isLive, listKey, isFavourite, position } =
    useContext(TableBetsContext)

  const [isShowEvent, toggleIsShowEvent] = useToggleState(true)
  const [t] = useTranslation()

  const formattedDate = formatEventDate(eventGroup.date, t)

  const outcomeFetchType: OutcomesFetchType | undefined = useMemo(() => {
    if (position === 'live' || position === 'line') {
      return {
        kind: 'STREAM'
      }
    }

    return undefined
  }, [position])

  const page1EventIds = useAppSelector((state) =>
    getPage1EventIds(state, listKey)
  )
  const page2EventIds = useAppSelector((state) =>
    getPage2EventIds(state, listKey)
  )
  const beforeEventIds = useAppSelector((state) =>
    getBeforePageEventIds(state, listKey)
  )

  return (
    <S.EventsTable key={eventGroup.date}>
      {!isLive && !isFavourite && (
        <S.EventDate onClick={toggleIsShowEvent}>
          <span>{formattedDate}</span>
          {dayjs(eventGroup.date).format('DD MMMM')}
          <S.ExpandButton twist={isShowEvent ? 180 : 0} />
        </S.EventDate>
      )}

      <S.EventItem isShow={isShowEvent}>
        {eventGroup.items.map((event) => (
          <RowWrapper
            beforeEndRef={beforeEndRef}
            beforeEventIds={beforeEventIds}
            beforeStartRef={beforeStartRef}
            eventId={event.id}
            key={event.id}
            page1EndRef={page1EndRef}
            page1EventIds={page1EventIds}
            page1StartRef={page1StartRef}
            page2EndRef={page2EndRef}
            page2EventIds={page2EventIds}
            page2StartRef={page2StartRef}
          >
            {({ isOverview }) => (
              <EventRow
                event={event}
                isOverview={isOverview}
                outcomesFetchType={outcomeFetchType}
              />
            )}
          </RowWrapper>
        ))}
      </S.EventItem>
    </S.EventsTable>
  )
}
