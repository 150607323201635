import React, { FC, memo, useEffect } from 'react'
import { useOutcomeInfo } from 'og-web-core/providers/basket/quick-bet/hooks/useOutcomeInfo'
import { ErrorCode, OutcomeIdentifier } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'
import {
  betsProviderActions,
  selectQuickBetStatusByOutcomeId
} from 'astra-core/containers/BetsProvider'

import { ETestData } from 'shared/lib/testData'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { TCustomToastContentProps } from 'shared/lib/Toastify'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { IconClockAnimated } from 'shared/ui/Icon/IconAnimated'
import { IconWarning2 } from 'shared/ui/Icon/General/IconWarning2'
import { useAppSelector, useAppDispatch } from 'store'

import { StyledToastNotificationItem } from './BasketQuickBetNotification.styled'

const autoCloseTime = 5000

export const BasketQuickBetNotificationWrapper: FC<
  TCustomToastContentProps<{ outcomeId: string }>
> = memo(({ data, closeToast }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const betStatus = useAppSelector((state) =>
    data?.outcomeId
      ? selectQuickBetStatusByOutcomeId(state, data?.outcomeId)
      : { loading: false }
  )

  const { loading, error } = betStatus

  useEffect(() => {
    if (!loading) {
      const timerId = setTimeout(() => {
        if (closeToast) {
          closeToast()
        }
        if (data?.outcomeId) {
          dispatch(
            betsProviderActions.clearMakeQuickBetState({
              outcomeId: data?.outcomeId
            })
          )
        }
      }, autoCloseTime)

      return () => clearTimeout(timerId)
    }
  }, [closeToast, data?.outcomeId, dispatch, loading])

  if (loading) {
    return (
      <StyledToastNotificationItem
        data-test-id={ETestData.TestQuickBetNotificationPending}
      >
        <IconClockAnimated />
        <span>{t('bet processing')}</span>
      </StyledToastNotificationItem>
    )
  }

  if (error) {
    const isBetsPayMaxExceeded = error.code === ErrorCode.BetsPayMaxExceeded
    const message = isBetsPayMaxExceeded
      ? 'bet limit exceeded'
      : 'bet not accepted'

    return (
      <StyledToastNotificationItem
        data-test-id={ETestData.TestQuickBetNotificationError}
      >
        <IconWarning2
          colorProps={{
            name: EColorsNames.Yellow,
            value: 10,
            type: EColorsTypes.CUSTOM
          }}
          size={18}
        />
        <span>{t(message)}</span>
      </StyledToastNotificationItem>
    )
  }

  const betResponse = betStatus.result?.outcomes

  if (betResponse) {
    const outcome = betResponse[0]
    const { outcomeCompositeId, coefficient } = outcome

    return outcomeCompositeId ? (
      <BasketQuickBetNotification
        coefficient={coefficient}
        outcomeCompositeId={outcomeCompositeId}
      />
    ) : null
  }

  return null
})

export const BasketQuickBetNotification: FC<{
  outcomeCompositeId: OutcomeIdentifier
  coefficient?: number
}> = ({ outcomeCompositeId, coefficient }) => {
  const { competitorsNames, basketOutcomeName } =
    useOutcomeInfo(outcomeCompositeId)

  const { t } = useTranslation()

  return (
    <StyledToastNotificationItem
      data-test-id={ETestData.TestQuickBetNotificationSuccess}
    >
      <IconCheckCircleFilled
        colorProps={{
          name: EColorsNames.Green,
          type: EColorsTypes.FIXED,
          value: 4
        }}
      />
      <span>{`${competitorsNames} ${basketOutcomeName} ${coefficient}`}</span>
      <span>{t('bet accepted')}</span>
    </StyledToastNotificationItem>
  )
}
