import React, { FC, memo, useCallback } from 'react'
import { actions } from 'astra-core/containers/ResultsProviderV2/slice'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { selectResultCountersListV3 } from 'astra-core/containers/SportCountersProvider/selectors'
import { selectEventResultsFilterSportId } from 'astra-core/containers/ResultsProviderV2/selectors'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { ERoutes } from 'shared/types/routes'
import { eventResultsContainerActions } from 'containers/EventResultsContainer/slice'

import { ResultsMenuItemProps } from './ResultsMenuListV2.types'
import {
  StyledSportsMenuItemCount,
  StyledSportsMenuItemLink,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './ResultsMenuListV2.styled'

export const ResultsMenuListV2 = () => {
  const sportsWithCounter = useAppSelector((state) => {
    return selectResultCountersListV3(state, ESportsCounterType.RESULTS)
  })
  const currentSportId = useAppSelector(selectEventResultsFilterSportId)

  const dispatch = useAppDispatch()
  const changeCurrentSportId = useCallback(
    (sportId) => {
      dispatch(eventResultsContainerActions.reset())
      dispatch(
        actions.setFilters({
          filters: { sportIds: [sportId], tournamentIds: undefined }
        })
      )
    },
    [dispatch]
  )

  return (
    <StyledSportsMenuList>
      {sportsWithCounter.map((sportWithCounter) => (
        <ResultsMenuItem
          active={currentSportId === sportWithCounter.id}
          key={sportWithCounter.id}
          sportWithCounter={sportWithCounter}
          onClickSport={changeCurrentSportId}
        />
      ))}
    </StyledSportsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(
  ({ sportWithCounter, active, onClickSport }) => {
    const { id, top, code, name, count } = sportWithCounter
    return (
      <StyledSportsMenuItemLink
        $active={active}
        to={`${ERoutes.Results}`}
        onClick={() => onClickSport(id)}
      >
        <SportIcon
          isActive={top}
          sportCode={code as ESportsCodes}
          withBackground
        />
        <StyledSportsMenuItemText>{name}</StyledSportsMenuItemText>
        <StyledSportsMenuItemCount>{count}</StyledSportsMenuItemCount>
      </StyledSportsMenuItemLink>
    )
  }
)
