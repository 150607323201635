import React, { FC } from 'react'

import {
  EventStatisticBlockLiveTime,
  EventStatisticBlockOtherInfo
} from './components'
import { EventStatisticsProps } from './EventStatistics.types'
import { StyledEventStatistics } from './EventStatistics.styled'
import { EVENT_STATISTIC_CODES } from './components/EventStatisticBlockOtherInfo/constants'

export const EventStatistics: FC<EventStatisticsProps> = (props) => (
  <StyledEventStatistics>
    <EventStatisticBlockLiveTime {...props} />
    <EventStatisticBlockOtherInfo
      {...props}
      statisticsCodes={EVENT_STATISTIC_CODES}
    />
  </StyledEventStatistics>
)
