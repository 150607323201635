import React, { FC, memo, useCallback } from 'react'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
import { ESportsCounterType } from 'astra-core/containers/SportCountersProvider/types'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'

import { SportIcon } from 'shared/ui/Icon/SportIcon'
import {
  selectCalendarCountersList,
  selectCalendarCountersListV2,
  selectCalendarEventsPagination,
  selectFilterSelectInputPeriod
} from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { useAppSelector, useAppDispatch } from 'store'

import { ResultsMenuItemProps } from './CalendarMenuList.types.ts'
import {
  StyledSportsMenuItem,
  StyledSportsMenuItemCount,
  StyledSportsMenuItemText,
  StyledSportsMenuList
} from './CalendarMenuList.styled'

export const CalendarMenuList = () => {
  const isNewCountersEndpoint = useFeatureFlag(
    EFeatureFlags.NEW_COUNTERS_ENDPOINT
  )
  const calendarCountersList = useAppSelector((state) =>
    isNewCountersEndpoint
      ? selectCalendarCountersListV2(state, ESportsCounterType.CALENDAR)
      : selectCalendarCountersList(state)
  )

  return (
    <StyledSportsMenuList>
      {calendarCountersList.map((sport) => (
        // @ts-ignore
        <ResultsMenuItem key={sport.id} sport={sport}>
          {sport.name}
        </ResultsMenuItem>
      ))}
    </StyledSportsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(({ sport }) => {
  const dispatch = useAppDispatch()
  const filterPeriod = useAppSelector(selectFilterSelectInputPeriod)
  const pagination = useAppSelector(selectCalendarEventsPagination)

  const changeCurrentSportId = useCallback(() => {
    dispatch(
      calendarEventsContainerActions.setCurrentSportIdFilter({
        ...filterPeriod,
        ...pagination,
        sportId: sport.id
      })
    )
  }, [dispatch, sport.id, filterPeriod, pagination])

  return (
    <StyledSportsMenuItem
      // @ts-ignore
      $active={sport.isActive}
      onClick={changeCurrentSportId}
    >
      <SportIcon
        isActive={sport.top}
        sportCode={sport.code as ESportsCodes}
        withBackground
      />

      <StyledSportsMenuItemText>{sport.name}</StyledSportsMenuItemText>

      <StyledSportsMenuItemCount>{sport.count}</StyledSportsMenuItemCount>
    </StyledSportsMenuItem>
  )
})
