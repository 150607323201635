import loadable from '@loadable/component'

import { fallback } from '../../ui/Fallback'

export const AboutPage = loadable(() => import('../../../pages/page-about'), {
  resolveComponent: (components) => components.PageAbout,
  fallback
})

export const NotFoundPage = loadable(() => import('../../../pages/page-404'), {
  resolveComponent: (components) => components.Page404,
  fallback
})

export const MainPage = loadable(() => import('../../../pages/page-main'), {
  resolveComponent: (components) => components.PageMain,
  fallback
})

export const LinePage = loadable(() => import('../../../pages/page-line'), {
  resolveComponent: (components) => components.PageLine,
  fallback
})

export const EventPage = loadable(() => import('../../../pages/page-event'), {
  resolveComponent: (components) => components.PageEvent,
  fallback
})

export const LineTournamentPage = loadable(
  () => import('../../../pages/page-line-tournament'),
  {
    resolveComponent: (components) => components.PageLineTournament,
    fallback
  }
)

export const LivePage = loadable(() => import('../../../pages/page-live'), {
  resolveComponent: (components) => components.PageLive,
  fallback
})

export const LiveEventsPage = loadable(
  () => import('../../../pages/page-live-events'),
  {
    resolveComponent: (components) => components.PageLiveEvents,
    fallback
  }
)

export const CalendarPage = loadable(
  () => import('../../../pages/page-calendar'),
  {
    resolveComponent: (components) => components.PageCalendar,
    fallback
  }
)

export const RefundPolicyPage = loadable(
  () => import('../../../pages/page-refund-policy'),
  {
    resolveComponent: (components) => components.PageRefundPolicy,
    fallback
  }
)

export const BalancePage = loadable(
  () => import('../../../pages/page-balance'),
  {
    resolveComponent: (components) => components.PageBalance,
    fallback
  }
)

export const BetsHistoryPage = loadable(
  () => import('../../../pages/page-bets-history'),
  {
    resolveComponent: (components) => components.PageBetsHistory,
    fallback
  }
)

export const ResultsPage = loadable(() => import('../../../pages/results'), {
  resolveComponent: (components) => components.PageResults,
  fallback
})

export const StatisticsPage = loadable(
  () => import('../../../pages/page-statistics'),
  {
    resolveComponent: (components) => components.PageStatistics,
    fallback
  }
)

export const GamesBetPage = loadable(
  () => import('../../../pages/page-games-bet'),
  {
    resolveComponent: (components) => components.PageGamesBet,
    fallback
  }
)

export const GamesLivePage = loadable(
  () => import('../../../pages/page-games-live'),
  {
    resolveComponent: (components) => components.PageGamesLive,
    fallback
  }
)

export const GamesTurboPage = loadable(
  () => import('../../../pages/page-games-turbo'),
  {
    resolveComponent: (components) => components.PageGamesTurbo,
    fallback
  }
)

export const GamesTurboGamePage = loadable(
  () => import('../../../pages/page-games-turbo-game'),
  {
    resolveComponent: (components) => components.PageGamesTurboGame,
    fallback
  }
)

export const GamesTwainPage = loadable(
  () => import('../../../pages/page-games-twain'),
  {
    resolveComponent: (components) => components.PageGamesTwain,
    fallback
  }
)

export const GamesCyberbetPage = loadable(
  () => import('../../../pages/page-games-cyberbet/ui'),
  {
    resolveComponent: (components) => components.PageGamesCyberbet,
    fallback
  }
)

export const GamesVsportPage = loadable(
  () => import('../../../pages/page-games-vsport/ui'),
  {
    resolveComponent: (components) => components.PageGamesVsport,
    fallback
  }
)

export const GamesVsportGamePage = loadable(
  () => import('../../../pages/page-games-vsport-game/ui'),
  {
    resolveComponent: (components) => components.PageGamesVsportGame,
    fallback
  }
)

export const SportGamesPage = loadable(
  () => import('../../../pages/page-sport-games'),
  {
    resolveComponent: (components) => components.PageSportGames,
    fallback
  }
)

export const CasinoPage = loadable(() => import('../../../pages/page-casino'), {
  resolveComponent: (components) => components.PageCasino,
  fallback
})

export const FriggPage = loadable(() => import('../../../pages/page-frigg'), {
  resolveComponent: (components) => components.FriggPage,
  fallback
})

export const FavouriteEventsPage = loadable(
  () => import('../../../pages/page-favourite-events'),
  {
    resolveComponent: (components) => components.PageFavouriteEvents,
    fallback
  }
)

export const ProfilePage = loadable(
  () => import('../../../pages/page-profile'),
  {
    resolveComponent: (components) => components.PageProfile,
    fallback
  }
)

export const NotificationPage = loadable(
  () => import('../../../pages/page-notification'),
  {
    resolveComponent: (components) => components.PageNotification,
    fallback
  }
)

export const PromotionPage = loadable(
  () => import('../../../pages/page-promotion'),
  {
    resolveComponent: (components) => components.PagePromotion,
    fallback
  }
)

export const PromotionAndBonusesPage = loadable(
  () => import('../../../pages/page-promotion-and-bonuses'),
  {
    resolveComponent: (components) => components.PagePromotionAndBonuses,
    fallback
  }
)

export const ResponsibleGamblingPage = loadable(
  () => import('../../../pages/page-responsible-gambling'),
  {
    resolveComponent: (components) => components.PageResponsibleGambling,
    fallback
  }
)

export const PolicyPrivacyPage = loadable(
  () => import('../../../pages/page-policy-privacy'),
  {
    resolveComponent: (components) => components.PagePolicyPrivacy,
    fallback
  }
)

export const PolicyAmlPage = loadable(
  () => import('../../../pages/page-policy-aml'),
  {
    resolveComponent: (components) => components.PagePolicyAml,
    fallback
  }
)

export const TermsAndConditionsPage = loadable(
  () => import('../../../pages/page-terms-and-conditions'),
  {
    resolveComponent: (components) => components.PageTermsAndConditions,
    fallback
  }
)

export const AuthSuccessPage = loadable(
  () => import('../../../pages/page-auth-success'),
  {
    resolveComponent: (components) => components.PageAuthSuccess,
    fallback
  }
)

export const AuthSuccessSignUpPage = loadable(
  () => import('../../../pages/page-auth-success-sign-up'),
  {
    resolveComponent: (components) => components.PageAuthSuccessSignUp,
    fallback
  }
)

export const HeadToHeadPage = loadable(
  () => import('../../../pages/page-head-to-head'),
  {
    resolveComponent: (components) => components.PageHeadToHead,
    fallback
  }
)

export const SearchPage = loadable(() => import('../../../pages/page-search'), {
  resolveComponent: (components) => components.PageSearch,
  fallback
})

export const EventCompletedPage = loadable(
  () => import('../../../pages/page-event-completed'),
  {
    resolveComponent: (components) => components.PageEventCompleted,
    fallback
  }
)
