import { useCallback } from 'react'

import { matchTrackerModel } from 'entities/match-tracker'
import { useAppDispatch } from 'store'

export const useHandleClickLiveTracker = ({
  eventSourceId
}: Partial<
  Pick<
    Parameters<
      typeof matchTrackerModel.liveMatchTrackerActions.setCurrentMatch
    >[0],
    'eventSourceId'
  >
>) => {
  const dispatch = useAppDispatch()
  const { liveMatchTrackerActions } = matchTrackerModel

  return useCallback(() => {
    if (eventSourceId) {
      dispatch(
        liveMatchTrackerActions.setCurrentMatch({
          eventSourceId
        })
      )
    }
  }, [dispatch, eventSourceId, liveMatchTrackerActions])
}
