import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EFeatureFlags,
  selectConfig,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { useAppSelector } from 'store'
import { ERoutes } from 'shared/types/routes'
import { filterRoutes } from 'shared/lib/routes'
import { EMenuView } from 'widgets/header/Menu/Menu.types'

import { Additional } from './components'
import { FIXED_HEADER_ROUTES_LIST, HeaderRouteItem } from './routes'
import { StyledFixedHeader, StyledMenu } from './FixedHeader.styled'
import { LandingButton } from './components/LandingButton'

export const FixedHeader: FC = memo(() => {
  const [t] = useTranslation()
  const config = useAppSelector(selectConfig)
  const isCasinoEnabled = !!config.CASINO_CLIENT_URL
  const isVsportEnabled = useFeatureFlag(EFeatureFlags.VSPORT_ENABLED)
  // TODO: Remove after release OL-614 to production
  const isFriggEnabled = useFeatureFlag(EFeatureFlags.FRIGG_ENABLED)

  const isEnabledRoute = useCallback(
    (route: HeaderRouteItem) => {
      const underFeatureFlags = {
        [ERoutes.Casino]: isCasinoEnabled,
        [ERoutes.VsportGames]: isVsportEnabled,
        [ERoutes.Frigg]: isFriggEnabled
      }

      return underFeatureFlags[route.path] ?? true
    },
    [isCasinoEnabled, isVsportEnabled, isFriggEnabled]
  )

  const menuItems = useMemo(() => {
    const aviatorItem = {
      path: ERoutes.TurboGamesAviator,
      name: 'Aviator',
      translatedName: t('aviator')
    }

    const routes = filterRoutes({
      routeList: FIXED_HEADER_ROUTES_LIST.filter(isEnabledRoute),
      t
    })

    return [...routes.slice(0, -1), aviatorItem, ...routes.slice(-1)]
  }, [isEnabledRoute, t])

  const isLanding = useFeatureFlag(EFeatureFlags.LANDING_APP)

  return (
    <StyledFixedHeader>
      <ul />
      {isLanding && <LandingButton />}
      <StyledMenu list={menuItems} view={EMenuView.DARK} />
      <Additional />
    </StyledFixedHeader>
  )
})
