import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import {
  resultsProviderActions,
  selectChampionshipsMenuFilter,
  selectFilterIsLiveResults,
  selectResultsFilterChampionshipId,
  selectResultsFilterDate
} from 'astra-core/containers/ResultsProvider'
import { defaultResultsStatuses } from 'astra-core/containers/ResultsProvider/constants'
import { isFeatureFlagEnabled } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'

import { EInputSize, EInputView } from 'shared/ui/Input'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import {
  defaultSelectAllChampionships,
  getReactSelectValue
} from 'shared/lib/results'
import { ESelectView } from 'shared/ui/Select'
import { CustomButtonRange, DatePicker } from 'shared/ui/DatePicker'
import { Checkbox } from 'shared/ui/checkbox'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { RESULTS_DATE_FORMAT } from 'entities/results/model/constants'
import { useAppSelector, useAppDispatch } from 'store'

import {
  StyledCheckboxText,
  StyledCheckboxWrapper,
  StyledHeaderResults,
  StyledHeaderWrapper,
  StyledIconResults,
  StyledInputWrapper,
  StyledLive,
  StyledSearchInput,
  StyledSelect,
  StyledSelectWrapper
} from './Header.styled'

export const Header = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const currentChampionshipId = useAppSelector(
    selectResultsFilterChampionshipId
  )
  const isLiveResults = useAppSelector(selectFilterIsLiveResults)
  const date = useAppSelector(selectResultsFilterDate)

  const isNewResultsEndpoint = isFeatureFlagEnabled(
    EFeatureFlags.NEW_RESULTS_ENDPOINT
  )

  const setSearchResultsFilter = useCallback(
    (event) => {
      dispatch(
        resultsProviderActions.setSearchResultsFilter({
          text: event.target.value
        })
      )
    },
    [dispatch]
  )

  const handleSearchResults = useMemo(
    () =>
      debounce(setSearchResultsFilter, 500, { leading: true, trailing: true }),
    [setSearchResultsFilter]
  )

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      handleSearchResults.cancel()
    }
  }, [handleSearchResults])

  const handleFilter = useCallback(
    (selectedOption) => {
      dispatch(
        resultsProviderActions.setChampionshipFilter({
          championshipId: selectedOption.value
        })
      )
    },
    [dispatch]
  )

  const handleChangeDateFilter = useCallback(
    (selectedDate) => {
      if (isNewResultsEndpoint) {
        dispatch(
          resultsProviderActions.setDateFilter({
            date: selectedDate,
            reqData: {
              // @ts-ignore
              statuses: defaultResultsStatuses
            }
          })
        )
      } else {
        dispatch(resultsProviderActions.setDateFilter({ date: selectedDate }))
      }
    },
    [dispatch, isNewResultsEndpoint]
  )

  const handleChangeIsLiveResultsFilter = useCallback(() => {
    dispatch(resultsProviderActions.toggleIsLiveEventsFilter())
  }, [dispatch])

  const championships = useAppSelector(selectChampionshipsMenuFilter)

  // TODO move it to useSelector
  const selectOptions = useMemo(() => {
    return [defaultSelectAllChampionships(t), ...championships]
  }, [championships, t])

  const selectValue = useMemo(
    () =>
      getReactSelectValue({
        championships,
        championshipId: currentChampionshipId,
        t
      }),
    [championships, t, currentChampionshipId]
  )

  const onResetDatePicker = useCallback(() => {
    dispatch(resultsProviderActions.resetDatePickerRangeValues())

    if (isNewResultsEndpoint) {
      dispatch(
        resultsProviderActions.fetchResults({
          withLinkedEvents: false,
          reqData: {
            // @ts-ignore
            statuses: defaultResultsStatuses
          }
        })
      )
    } else {
      dispatch(resultsProviderActions.fetchResults({ withLinkedEvents: false }))
    }
  }, [dispatch, isNewResultsEndpoint])

  return (
    <StyledHeaderWrapper>
      <StyledSelectWrapper>
        <StyledSelect
          getOptionLabel={(e) => (
            <>
              {e.label}
              {e.isLive && <StyledLive>{t('Live')}</StyledLive>}
            </>
          )}
          isSearchable={false}
          options={selectOptions}
          value={selectValue}
          view={ESelectView.Results}
          onChange={handleFilter}
        />
      </StyledSelectWrapper>

      <DatePicker
        customInput={
          <CustomButtonRange
            singleDateFormat={RESULTS_DATE_FORMAT}
            startDate={date}
            isSingleDate
            onReset={onResetDatePicker}
          />
        }
        maxDate={new Date()}
        selected={date}
        onChange={handleChangeDateFilter}
      />
      <StyledCheckboxWrapper>
        <Checkbox
          checked={isLiveResults}
          onChange={handleChangeIsLiveResultsFilter}
        />
        <StyledCheckboxText>{t('current only')}</StyledCheckboxText>
      </StyledCheckboxWrapper>
      <StyledInputWrapper>
        <StyledSearchInput
          customSize={EInputSize.M}
          icon={IconSearch}
          placeholder={t('search')}
          view={EInputView.SECONDARY}
          onChange={handleSearchResults}
        />
      </StyledInputWrapper>
      {/* <StyledCollapseAllText onClick={handleToggleCollapseAllText}>
        {buttonToggleCollapseText}
      </StyledCollapseAllText> */}
    </StyledHeaderWrapper>
  )
}

export const HeaderResults = () => {
  const [t] = useTranslation()

  const IconCalendar = (
    <StyledIconResults
      colorProps={{
        name: EColorsNames.Green,
        type: EColorsTypes.DEFAULT,
        value: 50
      }}
      size={24}
    />
  )
  return (
    <StyledHeaderResults>
      <TitleLinePage iconComponent={IconCalendar} title={t('results')}>
        <Header />
      </TitleLinePage>
    </StyledHeaderResults>
  )
}
