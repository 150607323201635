import styled from 'styled-components'

import { IconList } from 'shared/ui/Icon/General/IconList'

export const StyledClearResult = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  line-height: 16px;
`
export const StyledClearResultIcon = styled(IconList)`
  margin-bottom: 16px;
`
export const StyledClearResultIconTitle = styled.div`
  margin-bottom: 4px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`
export const StyledClearResultIconDescription = styled.div`
  text-align: center;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.fixed.primary[50]};
  `}
`
