import React, { FC } from 'react'

import { StyledTableCellFirst } from '../../../coefficient/ui/table/coefficient-table.styled'

import {
  StyledEventRowInfoCell,
  StyledTableRowOutcomes,
  StyledLineTableRowWrapper,
  EventRowActionsStyled,
  EventRowOutcomesValues
} from './event-row.styled'
import { ITableColumnHeaderListProps } from './event-row.types'

export const EventRow: FC<ITableColumnHeaderListProps> = ({
  handleMouseEnter,
  handleMouseLeave,
  coefficientsItems,
  infoCellComponents,
  lastCell,
  message,
  expandedData,
  outcomesComponent,
  linkedEvents,
  isOverview
}) => {
  const hasMessage = !!message
  return (
    <>
      <StyledLineTableRowWrapper isOverview={isOverview}>
        <StyledTableRowOutcomes
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <StyledTableCellFirst>
            <StyledEventRowInfoCell>
              {infoCellComponents}
            </StyledEventRowInfoCell>
          </StyledTableCellFirst>

          <EventRowOutcomesValues hasMessage={hasMessage}>
            {hasMessage && (
              <EventRowActionsStyled>{message}</EventRowActionsStyled>
            )}

            {coefficientsItems}

            {lastCell}
          </EventRowOutcomesValues>
        </StyledTableRowOutcomes>

        {expandedData}

        {outcomesComponent}
      </StyledLineTableRowWrapper>
      {linkedEvents}
    </>
  )
}
