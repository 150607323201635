import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'
import { lineTournamentsContainerActions } from 'pages/page-line-tournament/LineTournamentsContainer/slice'
import { eventContainerActions } from 'containers/EventContainer/slice'

export const eventsProviderSideEffects = {
  liveEvents: {
    onSuccess: liveContainerActions.fetchLiveSuccess,
    onError: liveContainerActions.fetchLiveError
  },
  lineTournaments: {
    onSuccess: lineTournamentsContainerActions.fetchLineTournamentsSuccess,
    onError: lineTournamentsContainerActions.fetchLineTournamentsError,
    onLoading: lineTournamentsContainerActions.fetchLine
  },
  lineEvents: {
    onSuccess: lineTournamentsContainerActions.fetchLineSuccess,
    onError: lineTournamentsContainerActions.fetchLineError,
    onLoading: lineTournamentsContainerActions.fetchLine
  },
  event: {
    onSuccess: eventContainerActions.fetchEventSuccess,
    onError: eventContainerActions.fetchEventError
  },
  calendarEvents: {
    onSuccess: calendarEventsContainerActions.fetchScheduledEventsSuccess,
    onError: calendarEventsContainerActions.fetchScheduledEventsError
  }
}
