import React, { FC, useMemo } from 'react'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'

import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { EventsFilters } from 'features/events-filters'
import { useAppSelector } from 'store'

import * as S from './EventsPageHeader.styled'
import { EventsPageHeaderProps } from './EventsPageHeader.types'

export const EventsPageHeader: FC<EventsPageHeaderProps> = ({
  sportId,
  ...filterProps
}) => {
  const sport = useAppSelector((state) => selectSport(state, +sportId))

  const Icon = useMemo(
    () => (
      <SportIcon
        sportCode={sport?.code as ESportsCodes}
        isActive
        withBackground
      />
    ),
    [sport?.code]
  )

  return (
    <S.Header>
      <TitleLinePage iconComponent={Icon} title={sport?.name}>
        <EventsFilters {...filterProps} />
      </TitleLinePage>
    </S.Header>
  )
}
