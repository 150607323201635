import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BetType, VirtualGameVendor } from 'betweb-openapi-axios'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import {
  EBetStatusText,
  formatAmountLocaleString,
  formatDate,
  getBetAmountFormatted,
  getBetOutcomeId,
  getBetPreview,
  getFormatBetSystemType
} from 'astra-core'
import {
  selectLoyaltyById,
  selectLoyaltyCurrencyRestrictionById
} from 'astra-core/containers/AccountProvider'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { ETestData } from 'shared/lib/testData'
import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'
import { IconCoins } from 'shared/ui/Icon/General/IconCoins'
import { EColorsNames } from 'shared/types/theme'
import { selectBetsHistoryBasketsPagination } from 'containers/BetsHistoryBasketContainer/selectors'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { useAppSelector, useAppDispatch } from 'store'

import { BetCardHistory, CommonBlockUnderHead } from '../BasketCommon/Common'

import { selectBetsHistoryBasketValues } from './selectors'
import {
  BasketHistoryItemProps,
  BasketHistoryItemResultProps,
  TextAndNumberProps
} from './BasketHistoryBody.types'
import {
  StyledBasketHistoryBodyWrapper,
  StyledBasketHistoryItemBorder,
  StyledBasketHistoryItemHeaderBetTime,
  StyledBasketHistoryItemHeaderBetType,
  StyledBasketHistoryItemHeaderWrapper,
  StyledBasketHistoryItemResultWrapper,
  StyledBasketHistoryItemWrapper,
  StyledBetsInfo,
  StyledBetsStatus,
  StyledBetsValue,
  StyledBonusWalletIconWrapper,
  StyledDataList,
  StyledDataListEmpty,
  StyledDataListText,
  StyledDataListTitle,
  StyledNumberRightSide,
  StyledTextAndNumberWrapper,
  StyledTextLeftSide,
  StyledWideBorder
} from './BasketHistoryBody.styled'
import { PAGINATION_SETTINGS } from './constants'
import { BasketHistoryBonusCoupon, BasketHistoryCashout } from './components'

export const BasketHistoryBody: FC = () => {
  const dispatch = useAppDispatch()
  const isCashoutBasketHistoryEnabled = useFeatureFlag(
    EFeatureFlags.CASHOUT_BASKET_HISTORY_ENABLED
  )

  // we reset our basket history and history filter then we change Basket Tab in "BasketHeaderItem" component
  const basketBets = useAppSelector(selectBetsHistoryBasketValues)

  const pagination = useAppSelector(selectBetsHistoryBasketsPagination)

  useEffect(() => {
    dispatch(
      betsHistoryBasketContainerActions.fetchHistoryBetsBasket({
        limit: pagination.limit,
        offset: pagination.offset,
        gameVendor: VirtualGameVendor.Sportsbook
      })
    )
  }, [dispatch, pagination])

  useEffect(() => {
    if (isCashoutBasketHistoryEnabled) {
      dispatch(betsHistoryProviderActions.subscribeCashoutSSE())

      return () => {
        dispatch(betsHistoryProviderActions.unsubscribeCashoutSSE())
      }
    }
  }, [dispatch, isCashoutBasketHistoryEnabled])

  return (
    <StyledBasketHistoryBodyWrapper
      data-test-id={ETestData.TestBasketHistoryBody}
    >
      <CommonBlockUnderHead
        leftText="all types"
        rightText="all history"
        historyTab
      />

      <StyledDataList pagination={PAGINATION_SETTINGS}>
        {basketBets.length > 0 ? (
          basketBets.map((bet, index) => (
            <BasketHistoryItem bet={bet} index={index} key={bet.id} />
          ))
        ) : (
          <BasketDataListEmpty />
        )}
      </StyledDataList>
    </StyledBasketHistoryBodyWrapper>
  )
}

export const BasketHistoryItem: FC<BasketHistoryItemProps> = ({
  bet,
  index
}) => {
  const [t] = useTranslation()
  const { betType, timestamp, outcomes, coefficient, appliedCouponId, id } = bet
  const bonus = false

  const coefficientValue = coefficient ? +coefficient?.toFixed(2) : 0

  const systemType = getFormatBetSystemType(bet)

  return (
    <>
      <StyledBasketHistoryItemWrapper
        data-test-id={`${ETestData.TestBasketHistoryItem}-${index}`}
      >
        <StyledBasketHistoryItemHeaderWrapper>
          <StyledBasketHistoryItemHeaderBetType>
            {t(betType.toLocaleLowerCase()).toUpperCase()} {systemType}
          </StyledBasketHistoryItemHeaderBetType>
          <StyledBasketHistoryItemHeaderBetTime>
            {formatDate(timestamp)}
          </StyledBasketHistoryItemHeaderBetTime>
        </StyledBasketHistoryItemHeaderWrapper>

        {outcomes.map((outcome) => {
          const { event, outcomeTypeId, parameters } = outcome
          const betOutcomeId = getBetOutcomeId({
            eventId: event?.id,
            outcomeTypeId,
            parameters
          })

          return <BetCardHistory key={betOutcomeId} outcome={outcome} />
        })}

        {betType !== BetType.Ordinar && (
          <>
            <StyledBasketHistoryItemBorder />
            <TextAndNumber
              number={coefficientValue}
              text={t('overall coefficient')}
            />
          </>
        )}

        {appliedCouponId && (
          <BasketHistoryBonusCoupon appliedCouponId={appliedCouponId} />
        )}

        {bonus && <TextAndNumber number={+'1,02'} text={t('bonus')} />}

        <BasketHistoryItemResult bet={bet} />

        <BasketHistoryCashout id={id} />
      </StyledBasketHistoryItemWrapper>

      <StyledWideBorder />
    </>
  )
}

const TextAndNumber: FC<TextAndNumberProps> = ({ text, number }) => (
  <StyledTextAndNumberWrapper>
    <StyledTextLeftSide>{text}</StyledTextLeftSide>
    <StyledNumberRightSide>{number}</StyledNumberRightSide>
  </StyledTextAndNumberWrapper>
)

const BasketHistoryItemResult: FC<BasketHistoryItemResultProps> = ({ bet }) => {
  const [t] = useTranslation()
  const { status, appliedCouponId } = bet
  const typeTheme = useAppSelector(selectUserSettingLayoutTheme)
  const currencyIcon = useGetCurrencyIcon()
  const couponLoyalty = useAppSelector((state) =>
    selectLoyaltyById(state, appliedCouponId)
  )
  const loyaltyCurrencyRestriction = useAppSelector((state) =>
    selectLoyaltyCurrencyRestrictionById(state, appliedCouponId)
  )

  const betAmountFormatted = getBetAmountFormatted({
    bet,
    couponLoyalty,
    currencyIcon,
    loyaltyCurrencyRestriction
  })

  const { isLongSumValue, isTwoLinesPreview } = getBetPreview({
    bet,
    betAmountFormatted
  })

  return (
    <StyledBasketHistoryItemResultWrapper
      isTwoLines={isTwoLinesPreview}
      status={status}
      typeTheme={typeTheme}
    >
      <StyledBetsInfo
        isLongSumValue={isLongSumValue}
        isTwoLines={isTwoLinesPreview}
      >
        {isLongSumValue && (
          <IconCoins
            colorProps={{ name: EColorsNames.Red, value: 90 }}
            size={10}
          />
        )}
        {!isLongSumValue && (
          <StyledBetsStatus>{t('bets amount')}</StyledBetsStatus>
        )}

        <StyledBetsValue>
          {formatAmountLocaleString({
            value: bet.bet,
            currency: !bet.bonusWallet ? currencyIcon : undefined
          })}
          {bet.bonusWallet && (
            <StyledBonusWalletIconWrapper>
              <IconBonus />
            </StyledBonusWalletIconWrapper>
          )}
        </StyledBetsValue>
      </StyledBetsInfo>

      <StyledBetsInfo
        isLongSumValue={isLongSumValue}
        isTwoLines={isTwoLinesPreview}
      >
        <StyledBetsStatus status={status}>
          {t(EBetStatusText[status])}
        </StyledBetsStatus>

        <StyledBetsValue>{betAmountFormatted}</StyledBetsValue>
      </StyledBetsInfo>
    </StyledBasketHistoryItemResultWrapper>
  )
}

const BasketDataListEmpty = () => {
  const [t] = useTranslation()
  return (
    <StyledDataListEmpty>
      <StyledDataListTitle>{t('no bets!')}</StyledDataListTitle>
      <StyledDataListText>{t('history bets is empty')}</StyledDataListText>
    </StyledDataListEmpty>
  )
}
