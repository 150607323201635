import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { EInputSize, EInputView } from 'shared/ui/Input'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames } from 'shared/types/theme'

import * as S from './SearchEventsInput.styled'
import { SearchInputProps } from './SearchEventsInput.types'

export const SearchEventsInput: FC<SearchInputProps> = memo(
  ({
    placeholder = 'search',
    initialValue = '',
    debounceTime = 300,
    onChange
  }) => {
    const { t } = useTranslation()

    useEffect(() => {
      setSearch(initialValue)
    }, [initialValue])

    const [search, setSearch] = useState(initialValue)

    const debouncedChangeHandler = useDebouncedCallback((value: string) => {
      onChange(value)
    }, debounceTime)

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setSearch(value)
        debouncedChangeHandler(value)
      },
      [debouncedChangeHandler]
    )

    const clearInputSearch = useCallback(() => {
      setSearch('')
      onChange('')
    }, [onChange])

    return (
      <S.Search>
        <S.Input
          customSize={EInputSize.M}
          icon={IconSearch}
          placeholder={t(placeholder)}
          rightPadding={28}
          testData={ETestData.TestTournamentsSearchInput}
          value={search}
          view={EInputView.SECONDARY}
          onChange={handleChange}
        />
        <S.IconRightButton onClick={clearInputSearch}>
          {!!search.length && (
            <IconClose
              colorProps={{ name: EColorsNames.Primary, value: 50 }}
              size={10}
            />
          )}
        </S.IconRightButton>
      </S.Search>
    )
  }
)
