import React from 'react'

import { EventsTable } from '../EventsTable'
import { usePollLineEvents } from '../../model/hooks'

export function LineContainer() {
  usePollLineEvents()

  return <EventsTable />
}
