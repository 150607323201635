import { ETestData } from 'og-web-core/utils/test-data'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFilterByPeriod } from 'astra-core/containers/EventsProvider/hooks/list-filters'

import { ESelectView } from 'shared/ui/Select'

import * as S from './TimePeriodFilter.styled'
import { TimePeriodFilterProps } from './TimePeriodFilter.types'

export const TimePeriodFilter: FC<TimePeriodFilterProps> = ({
  periodOptions,
  listKey
}) => {
  const { value: period, onChangeTimePeriod } = useFilterByPeriod({
    listKey,
    items: periodOptions
  })

  const { t } = useTranslation()

  return (
    <S.SelectPeriod
      isSearchable={false}
      options={periodOptions}
      placeholder={t('select')}
      testData={ETestData.TestLineSelectPeriod}
      value={period}
      view={ESelectView.Mini}
      onChange={onChangeTimePeriod}
    />
  )
}
