import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import React, { FC, useMemo } from 'react'

import { useAppSelector } from 'store'
import { SportIcon } from 'shared/ui/Icon/SportIcon'

import { LineTournamentSportProps } from './LineTournamentSport.types'
import { StyledLineTournamentSport } from './LineTournamentSport.styled'

export const LineTournamentSport: FC<LineTournamentSportProps> = ({
  tournament
}) => {
  const { sportId } = tournament
  const { code, name } =
    useAppSelector((state) => selectSport(state, +sportId)) || {}

  const Icon = useMemo(
    () => <SportIcon size={16} sportCode={code as ESportsCodes} isActive />,
    [code]
  )

  return (
    <StyledLineTournamentSport>
      {Icon} {name}.
    </StyledLineTournamentSport>
  )
}
