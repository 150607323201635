import React from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { useAppSelector } from 'store'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { GenericLiveTable } from '../Line/GenericLiveTable'
import { LiveTable } from '../Line/Live'

import { selectLiveIsEmpty } from './selectors'
import { useLiveContainerData } from './hooks'

export function LiveContainer() {
  const isEmpty = useAppSelector(selectLiveIsEmpty)
  // TODO:PLAT-867 Remove after release
  const isGenericSportEnabled = useFeatureFlag(
    EFeatureFlags.GENERIC_SPORT_ENABLED
  )

  useLiveContainerData()
  if (isEmpty) return <LoaderSpinner />

  if (isGenericSportEnabled) {
    return <GenericLiveTable />
  } else {
    return <LiveTable />
  }
}
