import styled from 'styled-components'

import { Input as TextInput } from 'shared/ui/Input'

export const Input = styled(TextInput)`
  width: 144px;
`

export const IconRightButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  outline: none;
  padding: 0 8px;
  position: absolute;
  right: 0;
  top: 0;
`

export const Search = styled.div`
  position: relative;
`
