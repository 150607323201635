import React, { Fragment } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { ETestData, getCreateTestData } from 'shared/lib/testData'
import { getAboutPageDocuments } from 'pages/page-about/AboutPage/constants'
import {
  menuListModel,
  StyledFooterBottomMenuListLink,
  StyledFooterBottomMenuListLinkA
} from 'entities/footer-of-page'

export const FooterOfPageMenuItems = () => {
  const [t] = useTranslation()
  const config = useAppSelector(selectConfig)
  const menuItems = menuListModel.useGetMenuList()
  const aboutPageDocumentsFromModel = getAboutPageDocuments()

  return (
    <>
      {menuItems.map(({ name, translatedName, path }) => (
        <StyledFooterBottomMenuListLink
          data-test-id={ETestData.TestFooterAboutUs}
          key={name}
          to={path}
        >
          {translatedName}
        </StyledFooterBottomMenuListLink>
      ))}

      {aboutPageDocumentsFromModel.map((document) => (
        <Fragment key={document.link + document.locale}>
          {document.to ? (
            <StyledFooterBottomMenuListLink
              data-test-id={getCreateTestData(
                ETestData.TestFooter,
                document.locale
              )}
              to={document.to}
            >
              {t(document.locale)}
            </StyledFooterBottomMenuListLink>
          ) : (
            <StyledFooterBottomMenuListLinkA
              data-test-id={getCreateTestData(
                ETestData.TestFooter,
                document.locale
              )}
              href={`${config.CDN_URL}/${config.RULES_CDN_FOLDER}/${document.link}`}
              target="_blank"
            >
              {t(document.locale)}
            </StyledFooterBottomMenuListLinkA>
          )}
        </Fragment>
      ))}
    </>
  )
}
