import React, { FC, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGroupedTournamentsBySport } from 'astra-core/hooks/useEvents'
import { selectIsGenericSport } from 'astra-core/containers/CommonDataProvider'
import {
  getEventList,
  getIsEventListFilterUsed,
  getIsNextPageLoading
} from 'astra-core/containers/EventsProvider'
import { EventsApiGetEventsV2Request } from 'astra-core/containers/EventsProvider/types'
import {
  useFilterBySearch,
  useResetFilters
} from 'astra-core/containers/EventsProvider/hooks/list-filters'
import { SportTournamentsGroup } from 'astra-core/hooks/useEvents/types'

import { useAppSelector } from 'store'
import { useVirtualScrollActions } from 'features/virtual-scroll/model/hooks'
import { InfinityPagination } from 'shared/ui/infinityPagination'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { ClearResultSearch } from 'shared/ui/ClearResult'
import { EmptyPageFilter } from 'shared/ui/EmptyPageFilter'
import { EventsTableWithVirtualScroll } from 'widgets/events-table-virtual-scroll'
import { SportGroupHeader } from 'widgets/events-table-virtual-scroll/ui'

import { LIVE_EVENTS_LIST_KEY } from '../../model/constants'

export const EventsTable: FC = () => {
  const { sportId } = useParams<{ sportId: string }>()

  const events = useAppSelector((state) =>
    getEventList(state, LIVE_EVENTS_LIST_KEY)
  )

  const { value: searchText } = useFilterBySearch(LIVE_EVENTS_LIST_KEY)

  const { onResetFilters } = useResetFilters(LIVE_EVENTS_LIST_KEY)

  const isLoading = useAppSelector((state) =>
    getIsNextPageLoading(state, LIVE_EVENTS_LIST_KEY)
  )

  const isLiveFilterUsed = useAppSelector((state) =>
    getIsEventListFilterUsed(state, LIVE_EVENTS_LIST_KEY)
  )

  const showSportHeader = useAppSelector((state) =>
    selectIsGenericSport(state, +sportId)
  )

  const sportGroups = useGroupedTournamentsBySport(events)

  const renderSportGroupHeader = useCallback(
    (group: SportTournamentsGroup) => {
      return showSportHeader ? <SportGroupHeader group={group} /> : null
    },
    [showSportHeader]
  )

  const request: EventsApiGetEventsV2Request = useMemo(
    () => ({
      sportIds: [+sportId],
      scheduledToNow: true
    }),
    [sportId]
  )

  const {
    loadPrevPage,
    hasNextPage,
    isNextPageLoading,
    loadNextPage,
    resetEventListScroll
  } = useVirtualScrollActions({
    listKey: LIVE_EVENTS_LIST_KEY,
    request,
    paramsExist: !!sportId
  })

  if (events.length === 0) {
    if (isLoading) {
      return <LoaderSpinner />
    }

    if (searchText) {
      return <ClearResultSearch />
    }

    if (isLiveFilterUsed) {
      return <EmptyPageFilter onButtonClickResetFilter={onResetFilters} />
    }
    return null
  }

  return (
    <InfinityPagination
      hasMore={hasNextPage}
      isPaginationLoading={isNextPageLoading}
      variant="fullHeight"
      onLoadMore={loadNextPage}
    >
      <EventsTableWithVirtualScroll
        eventsGroups={sportGroups}
        listKey={LIVE_EVENTS_LIST_KEY}
        loadPrevPage={loadPrevPage}
        position="live"
        renderGroupHeader={renderSportGroupHeader}
        resetEventListScroll={resetEventListScroll}
      />
    </InfinityPagination>
  )
}
