import React, { FC } from 'react'
import { useGetCurrencyIcon } from 'astra-core'
import { useTranslation } from 'react-i18next'
import { selectLoyaltyById } from 'astra-core/containers/AccountProvider'
import { LayoutTheme } from 'betweb-openapi-axios'
import { useTheme } from 'styled-components'

import {
  IconBonusCouponHistoryDark,
  IconBonusCouponHistoryLight
} from 'shared/ui/Icon/General/IconBonusCouponHistory'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'
import { IconProps } from 'shared/ui/Icon'
import { useAppSelector } from 'store'

import {
  BasketHistoryBonusCouponText,
  BasketHistoryBonusCouponWrapper
} from './BasketHistoryBonusCoupon.styled'
import { IBasketHistoryBonusCouponProps } from './BasketHistoryBonusCoupon.types'

const getIconBonusCoupon = (props: IconProps) => ({
  [LayoutTheme.Light]: <IconBonusCouponHistoryLight {...props} />,
  [LayoutTheme.Dark]: <IconBonusCouponHistoryDark {...props} />,
  [LayoutTheme.Unknown]: <IconBonusCouponHistoryLight {...props} />
})

export const BasketHistoryBonusCoupon: FC<IBasketHistoryBonusCouponProps> = ({
  appliedCouponId
}) => {
  const currencyIcon = useGetCurrencyIcon()
  const { t } = useTranslation()
  const { typeLayoutTheme } = useTheme()
  const couponLoyalty = useAppSelector((state) =>
    selectLoyaltyById(state, appliedCouponId)
  )

  if (!couponLoyalty) {
    return null
  }

  const {
    possibleAmount,
    coupon: { amount }
  } = couponLoyalty

  const couponText = getFormattedBonusCouponAmount(
    t('used coupon for'),
    possibleAmount ?? amount,
    currencyIcon
  )

  return (
    <BasketHistoryBonusCouponWrapper>
      {getIconBonusCoupon({ size: 16 })[typeLayoutTheme]}
      <BasketHistoryBonusCouponText>{couponText}</BasketHistoryBonusCouponText>
    </BasketHistoryBonusCouponWrapper>
  )
}
