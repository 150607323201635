import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import React, { useCallback } from 'react'
import { selectBasketOutcomes } from 'astra-core/containers/BasketProvider'
import { setLocalStorageItem } from 'og-web-core/utils/storage/local-storage'
import { StorageKeys } from 'og-web-core/utils/storage/constants'
import { selectAccount } from 'astra-core/containers/AccountProvider/selectors'
import {
  betsProviderActions,
  selectIsQuickBetActive
} from 'astra-core/containers/BetsProvider'

import { useAppSelector, useAppDispatch } from 'store'

import { BasketBody, NotAuthorizedBasket } from '../BasketBody/BasketBody'
import { BasketQuickBet } from '../BasketQuickBet/BasketQuickBet'
import { BasketClearAll } from '../BasketClearAll/BasketClearAll'

export const BasketBodyWrapper = () => {
  const isBasketQuickBetEnabled = useFeatureFlag(
    EFeatureFlags.BASKET_QUICK_BETS_ENABLED
  )
  const outcomes = useAppSelector(selectBasketOutcomes)
  const account = useAppSelector(selectAccount)
  const isQuickBetActive = useAppSelector(selectIsQuickBetActive)
  const dispatch = useAppDispatch()

  const basketBodyVisible = !isQuickBetActive

  const handleSwitchQuickBet = useCallback(() => {
    if (isQuickBetActive) {
      setLocalStorageItem(StorageKeys.isQuickBetActive, false)
    }
    dispatch(
      betsProviderActions.setQuickBetActive({ isActive: !isQuickBetActive })
    )
  }, [dispatch, isQuickBetActive])

  const basketQuickBet =
    outcomes.length && isQuickBetActive ? (
      <BasketClearAll onClickCancel={handleSwitchQuickBet} />
    ) : (
      <BasketQuickBet
        handleSwitchQuickBet={handleSwitchQuickBet}
        isActive={isQuickBetActive}
      />
    )

  if (!account) {
    return <NotAuthorizedBasket />
  }

  return (
    <>
      {isBasketQuickBetEnabled && basketQuickBet}
      {basketBodyVisible && <BasketBody />}
    </>
  )
}
