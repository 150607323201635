import { EventListInfo, ListKey } from 'astra-core/containers/EventsProvider'
import { ActiveEventStatus } from 'betweb-openapi-axios'

export const LIVE_EVENTS_LIST_KEY: ListKey = 'live'
export const MIN_SEARCH_LENGTH = 3
export const LIVE_POLLING_INTERVAL = 2000

export const LIVE_EVENTS_LIST_CONFIG: EventListInfo['config'] = {
  statuses: [
    ActiveEventStatus.Suspended,
    ActiveEventStatus.Trading,
    ActiveEventStatus.Planning,
    ActiveEventStatus.Open
  ].map((el) => el.replace('EVENT_', '')) as unknown as ActiveEventStatus[],
  sort: [
    'sportOrder.asc',
    'groupOrder.asc',
    'tournamentId.asc',
    'scheduledAt.asc'
  ],
  pageSize: 20,
  live: true
}
