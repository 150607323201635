import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Tournament } from 'betweb-openapi-axios'

import {
  EventResultsContainerState,
  UpdateTournamentOptionsPayload
} from './types'

const sliceName = 'eventResultsContainer'

export const initialState: EventResultsContainerState = {
  tournamentId: undefined,
  tournamentOptionById: {},
  tournamentIds: []
}

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setTournamentId(
      state,
      action: PayloadAction<{ tournamentId: Tournament['id'] | undefined }>
    ) {
      state.tournamentId = action.payload.tournamentId
    },
    updateTournamentOptions(
      state,
      action: PayloadAction<UpdateTournamentOptionsPayload>
    ) {
      const { ids, tournamentOptionById } = action.payload

      ids.forEach((id) => {
        if (state.tournamentOptionById[id] === undefined) {
          state.tournamentIds.push(id)
          state.tournamentOptionById[id] = tournamentOptionById[id]
        }
      })
    },
    reset(state) {
      Object.assign(state, initialState)
    }
  }
})

export const { actions: eventResultsContainerActions, reducer, name } = slice
