import React, { FC, useCallback, useMemo } from 'react'
import {
  basketProviderActions,
  selectBasketBonusWallet,
  selectCanMakeComplexBet,
  selectDisabledButtonExpressSystem,
  selectExpressOutcomeValue,
  selectExpressRate,
  selectMakeBetErrorsBottom,
  selectMaxBetExpressSystem
} from 'astra-core/containers/BasketProvider'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { formatAmountForBackend } from 'astra-core'
import { getComplexBasketOutcomeRestriction } from 'astra-core/domain/basket/validation'

import { ETestData } from 'shared/lib/testData'
import { useAppSelector, useAppDispatch } from 'store'

import {
  BasketButton,
  BasketError,
  BetCardElement,
  CommonBlockClearAll
} from '../../BasketCommon/Common'
import {
  StyledBetCardElementBorder,
  StyledQuickAmounts
} from '../../BasketCommon/Common.styled'
import { BasketBonusCoupons } from '../../BasketCommon/components'
import BasketInput from '../../BasketCommon/components/BasketInput'
import { BasketQuickAmounts } from '../../BasketQuickAmounts/BasketQuickAmounts'

import { ExpressProps } from './Express.types'

export const Express: FC<ExpressProps> = ({ outcomes }) => {
  const isBetsAmountEnabled = useFeatureFlag(EFeatureFlags.BETS_AMOUNT_ENABLED)
  const outcomeValue = useAppSelector(selectExpressOutcomeValue)
  const expressRate = useAppSelector(selectExpressRate)
  const maxBetExpressSystem = useAppSelector(selectMaxBetExpressSystem)
  const errorsBottom = useAppSelector(selectMakeBetErrorsBottom)

  const isEventsExclusionEnabled = useFeatureFlag(
    EFeatureFlags.EVENTS_EXCLUSION_ENABLED
  )
  const isOnlyOrdinarEnabled = useFeatureFlag(
    EFeatureFlags.ONLY_ORDINAR_ENABLED
  )
  const disabledButton = useAppSelector((state) => {
    if (isEventsExclusionEnabled || isOnlyOrdinarEnabled) {
      return !selectCanMakeComplexBet(state)
    } else {
      return selectDisabledButtonExpressSystem(state)
    }
  })
  const isBonusWallet = useAppSelector(selectBasketBonusWallet)
  const dispatch = useAppDispatch()

  const setOutcomeAmountNew = useCallback(
    (value: string) => {
      dispatch(
        basketProviderActions.setExpressAmount({
          amount: formatAmountForBackend(value)
        })
      )
    },
    [dispatch]
  )

  const calcCoefficient: number = useMemo(
    () =>
      (outcomeValue !== '' && (expressRate - +isBonusWallet) * +outcomeValue) ||
      0,
    [expressRate, outcomeValue, isBonusWallet]
  )

  return (
    <>
      <StyledBetCardElementBorder />

      {outcomes.map((outcome) => (
        <BetCardElement
          key={outcome.id}
          outcome={outcome}
          restriction={getComplexBasketOutcomeRestriction(outcome)}
        />
      ))}

      <CommonBlockClearAll />

      <BasketBonusCoupons />
      <BasketInput
        isBonusWallet={isBonusWallet}
        maxBet={maxBetExpressSystem}
        stakeAmount={outcomeValue}
        testData={ETestData.TestBetInputExpress}
        winSum={calcCoefficient}
        onAmountChange={setOutcomeAmountNew}
      />
      {isBetsAmountEnabled && (
        <StyledQuickAmounts>
          <BasketQuickAmounts
            stakeAmount={+outcomeValue}
            onChange={setOutcomeAmountNew}
          />
        </StyledQuickAmounts>
      )}
      {errorsBottom.map((error) => (
        <BasketError error={error} key={error.code} />
      ))}

      <BasketButton disabledButton={disabledButton} />
    </>
  )
}
