import React, { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { Switch } from 'shared/ui/Switch'
import { selectFilterIsTop } from 'pages/page-live-events/LiveContainer/selectors'
import { liveContainerActions } from 'pages/page-live-events/LiveContainer/slice'
import { useAppSelector, useAppDispatch } from 'store'

import { SearchInput } from '../SearchInput'

import {
  StyledInputWrapper,
  StyledLiveHeaderFilters
} from './LiveHeaderFilters.styled'

export const LiveHeaderFilters: FC = memo(() => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const isTop = useAppSelector(selectFilterIsTop)

  const toggleFilterTop = useCallback(
    () => dispatch(liveContainerActions.toggleFilterIsTop()),
    [dispatch]
  )

  return (
    <StyledLiveHeaderFilters>
      <Switch
        active={isTop}
        label={t('top')}
        testData={ETestData.TestLiveTopButton}
        onClick={toggleFilterTop}
      />

      <StyledInputWrapper>
        <SearchInput isTournaments />
      </StyledInputWrapper>
    </StyledLiveHeaderFilters>
  )
})
