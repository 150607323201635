import React, { FC, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalStorageItem } from 'og-web-core/utils/storage'
import { BetSaveAny } from 'betweb-openapi-axios'
import { StorageKeys } from 'og-web-core/utils/storage/constants'
import { setLocalStorageItem } from 'og-web-core/utils/storage/local-storage'
import { selectSettingsDataFetchItem } from 'astra-core/containers/SettingsProvider'

import { useAppSelector } from 'store'
import { Switch } from 'shared/ui/Switch'
import { IconLightning } from 'shared/ui/Icon/General/IconLightning'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconSettingSmall } from 'shared/ui/Icon/General/IconSettingSmall'
import { Tooltip } from 'shared/ui/Tooltip'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { ESwitchView } from 'shared/ui/Switch/types'
import { ETestData } from 'shared/lib/testData'

import { SettingRates } from '../BasketHeader/BasketHeader'

import {
  StyledBasketQuickBetButton,
  StyledBasketQuickBetWrapper,
  StyledLoaderWrapper,
  StyledQuickBetFormWrapper
} from './BasketQuickBet.styled'
import { BasketQuickBetForm } from './components/BasketQuickBetForm/BasketQuickBetForm'
import { BasketQuickBetProps } from './BasketQuickBet.types'

export const BasketQuickBet: FC<BasketQuickBetProps> = memo(
  ({ handleSwitchQuickBet, isActive }) => {
    const { t } = useTranslation()

    const [betSaveAny, setBetSaveAny] = useState(() =>
      getLocalStorageItem(StorageKeys.quickBetSaveAnyStatus, BetSaveAny.Always)
    )
    const handleChangeBetSaveAny = (status: BetSaveAny) => {
      setLocalStorageItem(StorageKeys.quickBetSaveAnyStatus, status)
      setBetSaveAny(status)
    }

    const { loading } = useAppSelector(selectSettingsDataFetchItem)

    const [isSettingRatesShown, setIsSettingRatesShown] =
      useState<boolean>(false)

    const toggleSettingRatesModal = useCallback(() => {
      setIsSettingRatesShown((prev) => !prev)
    }, [])

    const icon = isActive ? (
      <IconSettingSmall
        colorProps={{
          name: EColorsNames.Primary,
          type: EColorsTypes.CUSTOM,
          value: 17
        }}
        data-test-id={ETestData.TestQuickBetSaveAny}
        size={14}
        buttonProps
        onClick={toggleSettingRatesModal}
      />
    ) : (
      <IconLightning
        colorProps={{
          type: EColorsTypes.DEFAULT,
          name: EColorsNames.Blue,
          value: 0
        }}
      />
    )

    return loading ? (
      <StyledLoaderWrapper>
        <LoaderPoints />
      </StyledLoaderWrapper>
    ) : (
      <>
        <StyledBasketQuickBetWrapper active={isActive}>
          <Tooltip
            isCommon={isActive}
            title={t('ability to place bets in one click')}
          >
            <StyledBasketQuickBetButton active={isActive}>
              <Switch
                active={isActive}
                label={t('one click bet')}
                testData={ETestData.TestQuickBetSwitch}
                view={ESwitchView.QUICK_BET}
                onClick={handleSwitchQuickBet}
              />
              {icon}
            </StyledBasketQuickBetButton>
          </Tooltip>

          {isActive && (
            <StyledQuickBetFormWrapper>
              <BasketQuickBetForm />
            </StyledQuickBetFormWrapper>
          )}
        </StyledBasketQuickBetWrapper>

        {isSettingRatesShown && (
          <SettingRates
            activeStatus={betSaveAny}
            rect={{ top: 100 }}
            toggleSettingRatesModal={toggleSettingRatesModal}
            onChange={handleChangeBetSaveAny}
          />
        )}
      </>
    )
  }
)
