import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsTypes
} from 'astra-core/containers/BetsHistoryProvider'

import { SelectCheckbox } from 'shared/ui/SelectCheckbox'
import { useAppSelector, useAppDispatch } from 'store'

import { BET_TYPES_OPTIONS } from './constants'

export const SelectBetTypes = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const betsTypes = useAppSelector(selectBetsHistoryBetsTypes)

  const optionsSelectCheckbox = useMemo(() => BET_TYPES_OPTIONS(t), [t])

  const handleBetsTypesChange = useCallback(
    (value) => {
      dispatch(betsHistoryProviderActions.setFilterBetsTypes(value))
    },
    [dispatch]
  )

  return (
    <SelectCheckbox
      isSearchable={false}
      options={optionsSelectCheckbox}
      value={betsTypes}
      onChange={handleBetsTypesChange}
    />
  )
}
