import React from 'react'

import { EventsTable } from '../EventsTable'
import { usePollLiveEvents } from '../../model/hooks'

export function LiveContainer() {
  usePollLiveEvents()

  return <EventsTable />
}
