import { selectCompetitorsRenameDate } from 'astra-core/containers/ConfigProvider/selectors'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { isFeatureFlagEnabled } from 'astra-core/containers/ConfigProvider/utils'

import { useAppSelector } from 'store'

export const useCompetitorsRenamed = () => {
  const renameDate = useAppSelector(selectCompetitorsRenameDate)

  const isNewSbCompetitorsNameEnabled = isFeatureFlagEnabled(
    EFeatureFlags.NEW_SB_COMPETITORS_NAME_ENABLED
  )

  return {
    renameDate,
    isNewSbCompetitorsNameEnabled
  }
}
