import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledClearResult,
  StyledClearResultIcon,
  StyledClearResultIconDescription,
  StyledClearResultIconTitle
} from './ClearResults.styled'

export const ClearResult = memo(() => {
  const [t] = useTranslation()
  return (
    <StyledClearResult>
      <StyledClearResultIcon size={64} />
      <StyledClearResultIconTitle>{t('no results')}</StyledClearResultIconTitle>
      <StyledClearResultIconDescription
        dangerouslySetInnerHTML={{ __html: t('change filtering') }}
      />
    </StyledClearResult>
  )
})
