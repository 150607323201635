import { Event, EventProbability } from 'betweb-openapi-axios'
import { selectAccountId } from 'astra-core/containers/AccountProvider/selectors'
import { useHandleAddToBasket } from 'astra-core/containers/BasketProvider/add-to-basket'
import { useCallback } from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { rightBasketContainerState } from 'containers/BasketContainer/slice'
import { selectIsShowBasketOnSmallWindow } from 'containers/BasketContainer/selectors'
import { useNotifyMakeQuickBet } from 'hooks/useNotifyMakeQuickBet'
import { makeBet } from 'containers/BetContainer/saga'
import { useAppSelector, useAppDispatch } from 'store'

export interface IAddToBasketProps {
  eventStatus: Event['status']
  eventId: Event['id']
  eventProbability: EventProbability | undefined
  live: Event['live']
}

// TODO move to astra core
export const useHandleAddCoefficientToBasket = () => {
  const dispatch = useAppDispatch()
  const accountId = useAppSelector(selectAccountId)
  const isShowBasket = useAppSelector(selectIsShowBasketOnSmallWindow)
  const handleAddToBasket = useHandleAddToBasket()
  const notifyCallback = useNotifyMakeQuickBet()
  const quickBetsEnabled = useFeatureFlag(
    EFeatureFlags.BASKET_QUICK_BETS_ENABLED
  )

  const callback = useCallback(() => {
    if (!accountId) {
      dispatch(
        rightBasketContainerState.toggleBasketOnSmallWindow({
          isShow: !isShowBasket
        })
      )
    }
  }, [accountId, dispatch, isShowBasket])

  return useCallback(
    ({ eventId, eventStatus, eventProbability, live }: IAddToBasketProps) => {
      if (quickBetsEnabled) {
        dispatch(
          makeBet({
            eventId,
            eventProbability,
            eventStatus,
            live,
            notifyCallback
          })
        )
        return
      }

      handleAddToBasket({
        eventId,
        eventStatus,
        eventProbability,
        callback
      })
    },
    [callback, dispatch, handleAddToBasket, notifyCallback, quickBetsEnabled]
  )
}
