import {
  EventListInfo,
  eventsProviderActions,
  getIsEventListInitialized,
  ListKey
} from 'astra-core/containers/EventsProvider'
import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'store'

interface UseInitEventsListReturn {
  isEventListInitialized: boolean
}

interface UseInitEventsListParams {
  listKey: ListKey
  config: EventListInfo['config']
  filters?: EventListInfo['filters']
}

type UseInitEventsList = (
  params: UseInitEventsListParams
) => UseInitEventsListReturn

export const useInitEventsList: UseInitEventsList = ({
  listKey,
  config,
  filters
}) => {
  const dispatch = useAppDispatch()
  const isEventListInitialized = useAppSelector((state) =>
    getIsEventListInitialized(state, listKey)
  )

  useEffect(() => {
    if (isEventListInitialized) {
      return
    }

    dispatch(
      eventsProviderActions.setEventListInfo({
        listKey,
        config,
        filters
      })
    )
  }, [config, filters, dispatch, isEventListInitialized, listKey])

  useEffect(() => {
    return () => {
      dispatch(
        eventsProviderActions.removeEventListInfo({
          listKey
        })
      )
    }
  }, [dispatch, listKey])

  return { isEventListInitialized }
}
