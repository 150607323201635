import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { selectLoyalty } from 'astra-core/containers/AccountProvider/selectors'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import { IconExit } from 'shared/ui/Icon/General/IconExit'
import {
  EButtonIconPositionSides,
  EButtonSizes,
  EButtonViews
} from 'shared/ui/Button'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { SideMenu } from 'shared/ui/SideMenu'

import { getProfileMenuRoutesList } from './routes'
import { getLogoutUrl } from './constants'
import { StyledButtonExit } from './AuthorizedProfileMenu.styled'
import { PersonalData } from './PersonalData'

export const AuthorizedProfileMenu = memo(() => {
  const [t] = useTranslation()

  const config = useAppSelector(selectConfig)
  const bonusCoupons = useAppSelector(selectLoyalty)

  const hasBonuses = bonusCoupons.length > 0

  return (
    <>
      <PersonalData />

      <SideMenu
        routeList={getProfileMenuRoutesList({ hasBonuses })}
        authorized
      />

      <form action={getLogoutUrl(config)} method="POST">
        <StyledButtonExit
          iconProps={{
            colorProps: {
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 17
            },
            positionSide: EButtonIconPositionSides.Left
          }}
          data-test-id={ETestData.TestProfileButtonExit}
          icon={IconExit}
          size={EButtonSizes.S}
          type="submit"
          view={EButtonViews.NONE}
        >
          {t('to exit')}
        </StyledButtonExit>
      </form>
    </>
  )
})
