import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'
import { BasketOutcome, BetOutcomeStatus, BetType } from 'betweb-openapi-axios'
import {
  basketProviderActions,
  EBetTypes,
  EMakeBetStatus,
  IBasketError,
  selectBasketAppliedCoupon,
  selectBasketBetTypeTab,
  selectBasketHeaderTab,
  selectBasketMakeBetStatus,
  selectBasketOutcomesLength,
  selectDependentEventGroupsLength,
  selectExpressRate,
  selectExpressRateWithBonus,
  selectIsDependedHighlightedOutcome,
  selectIsDependentOutcomes
} from 'astra-core/containers/BasketProvider'
import { ALL_BASKET_ERROR_MESSAGES } from 'astra-core/containers/BasketProvider/constants'
import {
  formatAmount as formatAmountCore,
  formatAmountLocaleString,
  formatDate
} from 'astra-core'
import { useContainerDimensions, useGetCurrencyIcon } from 'astra-core/hooks'
import {
  selectCurrencyMinBet,
  selectOutcomeCategoryById,
  selectOutcomeTypeById,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useTheme } from 'styled-components'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { useGetEventFromHistoryLink } from 'og-web-core/utils/events'
import {
  getBasketCardEventStatus,
  getIsEventEnded,
  getIsEventPending
} from 'og-web-core/utils/basket'
import {
  getBetHomeAwayCompetitorsRenamed,
  getHomeAwayCompetitorsRenamed
} from 'astra-core/utils/events'

import { ETestData, getCreateTestData } from 'shared/lib/testData'
import { actions as betsHistoryBasketContainerActions } from 'containers/BetsHistoryBasketContainer/slice'
import { OddModes } from 'pages/page-live-events/Line/Line.types'
import { useCompetitorsRenamed, useOddHighlight } from 'hooks'
import { IconSortAsc } from 'shared/ui/Icon/General/IconSortAsc'
import { IconBin } from 'shared/ui/Icon/General/IconBin'
import { IconDependentOutcomes } from 'shared/ui/Icon/General/IconDependentOutcomes'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { ERoutes } from 'shared/types/routes'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { EButtonViews } from 'shared/ui/Button'
import {
  getEventStatisticScoreByPeriod,
  getEventStatisticScoreCurrent
} from 'shared/lib/statistics'
import { IconInfoCircle } from 'shared/ui/Icon/General/IconInfoCircle'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { Tooltip } from 'shared/ui/Tooltip'
import { SelectBetTypes } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Filter/components'
import { routesWithoutPrevPath } from 'features/event/row/open-event-page/lib'
import { useAppSelector, useAppDispatch } from 'store'

import { EBasketHeaderTypes } from '../Basket.types'
import { SingleBetAmount } from '../BasketBody/Ordinar/Ordinar'
import { BASKET_ERROR_ACTIONS } from '../constants'

import { BasketBetCardHistoryStatistic } from './components'
import {
  iconCoefficientChangeColorProps,
  showIconCoefficientChangeList
} from './constants'
import {
  BasketButtonProps,
  BasketErrorBottomProps,
  BasketOutcomeSingleErrorProps,
  BetCardCouponProps,
  BetCardElementProps,
  BetCardHistoryProps,
  CommonBlockUnderHeadProps,
  HoverMaxBetTextProps,
  HoverTextProps,
  InputMaxBetProps,
  LoaderEventPendingProps,
  MapNewBetListItemsParams,
  MaxBetHoverTextProps,
  PossibleWinningSumProps,
  TotalRateProps
} from './Common.types'
import {
  SingleBetAmountWrapper,
  StyledBasketButtonClearAll,
  StyledBasketButtonSingleBetSize,
  StyledBasketButtonWrapper,
  StyledBasketErrorAction,
  StyledBasketErrorText,
  StyledBasketErrorWrapper,
  StyledBetCardCouponWrapper,
  StyledBetCardElementBorder,
  StyledBetCardElementWrapper,
  StyledBetCardHistoryLeftSide,
  StyledBetCardHistoryWrapper,
  StyledBetCardIcon,
  StyledBetCardLeftSide,
  StyledBetCardOdd,
  StyledBetCardOddWrapper,
  StyledBetCardRightSide,
  StyledBetCardWrapper,
  StyledBetPrevOdd,
  StyledButton,
  StyledButtonText,
  StyledButtonWrapper,
  StyledCleanBasketWrapper,
  StyledCommonBlockClearAllButtonsWrapper,
  StyledCommonBlockClearAllWrapper,
  StyledHoverMaxBetText,
  StyledHoverMaxBetTextTriangle,
  StyledHoverMaxBetTextWrapper,
  StyledHoverText,
  StyledHoverTextWrapper,
  StyledIconDependentOutcomes,
  StyledIconWrapper,
  StyledInfoIconWrapper,
  StyledInputMaxBet,
  StyledLink,
  StyledLoaderEventPendingContainer,
  StyledLoaderEventPendingWrapper,
  StyledMaxBetHoverText,
  StyledMaxBetHoverTextWrapper,
  StyledMaxBetHoverTriangle,
  StyledPossibleWinningSum,
  StyledPossibleWinningSumText,
  StyledPossibleWinningSumWrapper,
  StyledTeamName,
  StyledText,
  StyledTime,
  StyledTotalRate,
  StyledTotalRateBonusSum,
  StyledTotalRateBonusWrapper,
  StyledTotalRateWrapper
} from './Common.styled'
import { BasketBonus } from './components/BasketBonus'
import BonusOnExpress from './components/BonusOnExpress/BonusOnExpress'
import { BasketCardEventStatus } from './components/BasketCardEventStatus'
import { OutcomeName as OutcomeNameBasket } from './components/OutcomeNameInBasket'
import { OutcomeName as OutcomeNameBetsHistory } from './components/OutcomeNameInBetsHistory'

export const CommonBlockUnderHead: FC<CommonBlockUnderHeadProps> = memo(
  ({ historyTab, leftText, rightText, wideBorder = false }) => {
    const [t] = useTranslation()
    const history = useHistory()
    const dispatch = useAppDispatch()
    const headerTab = useAppSelector(selectBasketHeaderTab)

    const historySelect = useMemo(
      () => ({
        betsHistory: () => {
          dispatch(
            betsHistoryBasketContainerActions.setInitialStateBetsHistoryBasket()
          )
          dispatch(betsHistoryProviderActions.clearFilterBetsTypes())

          return history.push(ERoutes.BetsHistory)
        }
      }),
      [dispatch, history]
    )

    const deleteBasket = useCallback(() => {
      dispatch(basketProviderActions.deleteBasket())
    }, [dispatch])

    const leftHeaderTabClick = useCallback(
      () =>
        headerTab === EBasketHeaderTypes.History
          ? historySelect.betsHistory()
          : deleteBasket(),
      [headerTab, historySelect, deleteBasket]
    )

    return (
      <StyledCleanBasketWrapper wideBorder={wideBorder}>
        {historyTab && !!leftText && <SelectBetTypes />}
        <StyledButton oneRightButton={!leftText} onClick={leftHeaderTabClick}>
          <StyledText data-test-id={ETestData.TestCouponAllHistory}>
            {t(rightText)}
          </StyledText>
        </StyledButton>
      </StyledCleanBasketWrapper>
    )
  }
)
const RightPartViewByBetType = ({ toggleSingleBetPanel }) => {
  const [t] = useTranslation()
  const expressRate = useAppSelector(selectExpressRate)

  // TODO: Delete after release iframe for kz, need only for testing
  const bonusOnExpressEnabled = useFeatureFlag(
    EFeatureFlags.BONUS_ON_EXPRESS_ENABLED
  )
  const expressRateWithBonus = useAppSelector(selectExpressRateWithBonus)

  return {
    [EBetTypes.Ordinar]: (
      <StyledBasketButtonSingleBetSize
        data-test-id={ETestData.TestBasketButtonSingleBetSize}
        view={EButtonViews.SECONDARY}
        onClick={toggleSingleBetPanel}
      >
        {t('a single bet size')}
      </StyledBasketButtonSingleBetSize>
    ),
    [EBetTypes.Express]: (
      <TotalRate
        coefficient={bonusOnExpressEnabled ? expressRateWithBonus : expressRate}
      />
    ),
    [EBetTypes.System]: null
  }
}

export const CommonBlockClearAll = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const isBetsAmountEnabled = useFeatureFlag(EFeatureFlags.BETS_AMOUNT_ENABLED)

  const [isShownSingleBetPanel, setIsShownSingleBetPanel] = useState(false)
  const betTypeTab = useAppSelector(selectBasketBetTypeTab)
  // TODO: Delete after release iframe for kz, need only for testing
  const bonusOnExpressEnabled = useFeatureFlag(
    EFeatureFlags.BONUS_ON_EXPRESS_ENABLED
  )
  const isExpress = betTypeTab === EBetTypes.Express
  const toggleSingleBetPanel = useCallback(() => {
    setIsShownSingleBetPanel((prev) => !prev)
  }, [])

  const deleteBasket = useCallback(() => {
    dispatch(basketProviderActions.deleteBasket())
  }, [dispatch])

  return (
    <StyledCommonBlockClearAllWrapper
      isShownSingleBetPanel={isShownSingleBetPanel}
    >
      {isExpress && bonusOnExpressEnabled && <BonusOnExpress />}
      <StyledCommonBlockClearAllButtonsWrapper>
        <StyledBasketButtonClearAll
          data-test-id={ETestData.TestBasketButtonClearAll}
          view={EButtonViews.SECONDARY}
          onClick={deleteBasket}
        >
          {t('clear all')}
        </StyledBasketButtonClearAll>
        {RightPartViewByBetType({ toggleSingleBetPanel })[betTypeTab]}
      </StyledCommonBlockClearAllButtonsWrapper>

      {isShownSingleBetPanel && (
        <SingleBetAmountWrapper isBetsAmountEnabled={isBetsAmountEnabled}>
          <SingleBetAmount />
        </SingleBetAmountWrapper>
      )}
    </StyledCommonBlockClearAllWrapper>
  )
}

export const BetCardCoupon: FC<BetCardCouponProps> = memo(
  ({ outcome, restriction }) => {
    const [isHover, setIsHover] = useState<boolean>(false)
    const { event, probability, id: outcomeId } = outcome
    const { tradingStatus, odd } = probability
    const { status: eventStatus, id: eventId } = event
    const outcomeType = useAppSelector((state) =>
      selectOutcomeTypeById(state, probability.outcomeTypeId)
    )
    const outcomeCategory = useAppSelector((state) =>
      selectOutcomeCategoryById(state, outcomeType?.categoryId)
    )
    const isShowIconDependentOutcomes = useAppSelector((state) =>
      selectIsDependentOutcomes(state, { status: eventStatus, id: eventId })
    )
    const dependentEventGroups = useAppSelector(
      selectDependentEventGroupsLength
    )
    const isDependedHighlightedOutcome = useAppSelector((state) =>
      selectIsDependedHighlightedOutcome(state, eventId)
    )
    const { renameDate, isNewSbCompetitorsNameEnabled } =
      useCompetitorsRenamed()

    const { name: sportName } =
      useAppSelector((state) => selectSport(state, event.tournament.sportId)) ||
      {}

    const { mode, prevOdd } = useOddHighlight(probability)

    const dispatch = useAppDispatch()

    const status = useMemo(
      () => getBasketCardEventStatus(eventStatus, tradingStatus),
      [eventStatus, tradingStatus]
    )

    const isEventEnded = useMemo(() => {
      return getIsEventEnded(status)
    }, [status])

    const isEventPending = useMemo(() => {
      return getIsEventPending(status)
    }, [status])

    const isEventExclusionEnabled = useFeatureFlag(
      EFeatureFlags.EVENTS_EXCLUSION_ENABLED
    )

    const isOnlyOrdinarEnabled = useFeatureFlag(
      EFeatureFlags.ONLY_ORDINAR_ENABLED
    )

    let isRestricted = false
    if (isEventExclusionEnabled && restriction === 'excluded') {
      isRestricted = true
    } else if (isOnlyOrdinarEnabled && restriction === 'onlyOrdinar') {
      isRestricted = true
    }

    const shouldShowDeleteButton =
      isEventEnded || isEventPending || isRestricted
    const isDeleteButtonVisible = shouldShowDeleteButton || isHover
    const isOddVisible = !isEventEnded && !isRestricted

    const toggleIsHover = useCallback(
      (flag: boolean) => {
        if (!isEventEnded) {
          setIsHover(flag)
        }
      },
      [isEventEnded]
    )

    const onMouseOver = () => toggleIsHover(true)
    const onMouseLeave = () => toggleIsHover(false)

    const deleteBasketOutcome = useCallback(() => {
      dispatch(basketProviderActions.deleteBasketOutcome({ outcomeId }))
    }, [dispatch, outcomeId])

    const showPrevOdd = useMemo(() => odd !== prevOdd, [odd, prevOdd])

    const competitors = useMemo(() => {
      const { homeCompetitors, awayCompetitors } =
        getHomeAwayCompetitorsRenamed({
          event,
          renameDate,
          isNewSbCompetitorsNameEnabled
        })

      return [...homeCompetitors, ...awayCompetitors]
    }, [event, renameDate, isNewSbCompetitorsNameEnabled])

    const highlightDependentOutcomes = useCallback(() => {
      if (dependentEventGroups) {
        dispatch(basketProviderActions.highlightDependentOutcomes({ eventId }))
      }
    }, [dispatch, eventId, dependentEventGroups])

    const doNotHighlightDependentOutcomes = useCallback(() => {
      if (dependentEventGroups) {
        dispatch(basketProviderActions.doNotHighlightDependentOutcomes())
      }
    }, [dispatch, dependentEventGroups])

    return (
      <>
        <StyledBetCardCouponWrapper
          isDependedHighlightedOutcome={isDependedHighlightedOutcome}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
        >
          <Tooltip title={`${sportName}. ${event.tournament.name}`}>
            <StyledBetCardLeftSide
              isEventEnded={isEventEnded || isRestricted}
              isEventPending={isEventPending}
            >
              {competitors.map((competitor) => (
                <StyledTeamName key={competitor.id}>
                  {competitor.name}
                </StyledTeamName>
              ))}

              <OutcomeNameBasket
                event={event}
                outcomeCategory={outcomeCategory}
                outcomeType={outcomeType}
                probability={probability}
              />
            </StyledBetCardLeftSide>
          </Tooltip>

          <StyledBetCardRightSide>
            <StyledBetCardWrapper>
              {showIconCoefficientChangeList.includes(mode) && (
                <IconSortAsc
                  colorProps={iconCoefficientChangeColorProps[mode]}
                  twist={mode === OddModes.Decrease ? 180 : 0}
                />
              )}
              {isOddVisible && (
                <StyledBetCardOddWrapper
                  isEventEnded={isEventEnded}
                  isEventPending={isEventPending}
                >
                  <StyledBetCardOdd $mode={mode}>{odd}</StyledBetCardOdd>
                  {showPrevOdd && (
                    <StyledBetPrevOdd>{prevOdd}</StyledBetPrevOdd>
                  )}
                </StyledBetCardOddWrapper>
              )}
              {isDeleteButtonVisible && (
                <StyledBetCardIcon
                  data-test-id={ETestData.TestBasketBetCardHoverIcon}
                  onClick={deleteBasketOutcome}
                >
                  <IconBin />
                </StyledBetCardIcon>
              )}
            </StyledBetCardWrapper>
            {isShowIconDependentOutcomes && (
              <StyledIconDependentOutcomes
                data-test-id={ETestData.TestIconDependentOutcomes}
                onMouseEnter={highlightDependentOutcomes}
                onMouseLeave={doNotHighlightDependentOutcomes}
              >
                <IconDependentOutcomes />
              </StyledIconDependentOutcomes>
            )}
          </StyledBetCardRightSide>
        </StyledBetCardCouponWrapper>
        <BasketCardEventStatus restriction={restriction} status={status} />
      </>
    )
  }
)

export const LoaderEventPending: FC<LoaderEventPendingProps> = memo(
  ({ testData }) => {
    const { colors } = useTheme()

    return (
      <StyledLoaderEventPendingWrapper data-test-id={testData}>
        <StyledLoaderEventPendingContainer>
          <LoaderPoints
            colors={{
              start: colors[EColorsTypes.DEFAULT].primary[90],
              end: colors[EColorsTypes.CUSTOM].primary[26]
            }}
            settingsAnimation={{
              timeAfterEnd: 2.4,
              scaleActivePoint: 1
            }}
            count={3}
            margin={2}
            size={4}
          />
        </StyledLoaderEventPendingContainer>
      </StyledLoaderEventPendingWrapper>
    )
  }
)

export const BetCardHistory: FC<BetCardHistoryProps> = ({ outcome }) => {
  const { coefficient, status, event, live: isOutcomeOfLive } = outcome
  const { basicStatistics } = outcome.event || {}
  const outcomeCategory = useAppSelector((state) =>
    selectOutcomeCategoryById(state, outcome.outcomeCategoryId)
  )
  const { renameDate, isNewSbCompetitorsNameEnabled } = useCompetitorsRenamed()

  const { homeCompetitors, awayCompetitors } = getBetHomeAwayCompetitorsRenamed(
    {
      event,
      renameDate,
      isNewSbCompetitorsNameEnabled
    }
  )

  const date = event?.eventDate ? formatDate(event?.eventDate) : ''

  const outcomeTypeData = useAppSelector((state) =>
    selectOutcomeTypeById(state, outcome.outcomeTypeId)
  )

  const isDisplayStatistic =
    isOutcomeOfLive || (!isOutcomeOfLive && status === BetOutcomeStatus.Win)

  const scoresByPeriod = getEventStatisticScoreByPeriod(
    basicStatistics?.scoresByPeriod
  )
  const currentScore = getEventStatisticScoreCurrent(
    basicStatistics?.currentScore
  )

  const link = useGetEventFromHistoryLink({
    event: outcome.event,
    routesWithoutPrevPath
  })

  return (
    <StyledBetCardHistoryWrapper>
      <StyledBetCardHistoryLeftSide>
        <Link to={link}>
          <StyledTeamName>{homeCompetitors[0]?.name}</StyledTeamName>
          <StyledTeamName>{awayCompetitors[0]?.name}</StyledTeamName>
          <OutcomeNameBetsHistory
            outcome={outcome}
            outcomeCategory={outcomeCategory}
            outcomeType={outcomeTypeData}
          />
        </Link>

        {isDisplayStatistic ? (
          <BasketBetCardHistoryStatistic
            currentScore={currentScore}
            scoresByPeriod={scoresByPeriod}
          />
        ) : (
          <StyledTime>{date}</StyledTime>
        )}
      </StyledBetCardHistoryLeftSide>

      <StyledBetCardRightSide>
        {outcome.recalculationReason?.id && (
          <Tooltip title={outcome.recalculationReason?.message ?? ''}>
            <StyledInfoIconWrapper>
              <IconInfoCircle
                colorProps={{
                  type: EColorsTypes.FIXED,
                  name: EColorsNames.Yellow,
                  value: 49
                }}
              />
            </StyledInfoIconWrapper>
          </Tooltip>
        )}
        <StyledBetCardOdd>{coefficient}</StyledBetCardOdd>
      </StyledBetCardRightSide>
    </StyledBetCardHistoryWrapper>
  )
}

// TODO we will take this value from back end in the future
const MAX_WIN_SUM = 100000000

export const PossibleWinningSum: FC<PossibleWinningSumProps> = memo(
  ({ winSum }) => {
    const [t] = useTranslation()
    const currencyIcon = useGetCurrencyIcon()

    const possibleWinSum = useMemo(
      () =>
        formatAmountLocaleString({
          value: winSum < MAX_WIN_SUM ? winSum : MAX_WIN_SUM
        }),
      [winSum]
    )

    return (
      <StyledPossibleWinningSumWrapper>
        <StyledPossibleWinningSumText>
          {t('possibility gain full')} ({currencyIcon})
        </StyledPossibleWinningSumText>
        <StyledPossibleWinningSum>{possibleWinSum}!!!</StyledPossibleWinningSum>
      </StyledPossibleWinningSumWrapper>
    )
  }
)

export const BasketButton: FC<BasketButtonProps> = memo(
  ({ disabledButton }) => {
    const [t] = useTranslation()
    const dispatch = useAppDispatch()
    const makeBetStatus = useAppSelector(selectBasketMakeBetStatus)
    const outcomesLength = useAppSelector(selectBasketOutcomesLength)
    const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)

    const makeBet = useCallback(() => {
      dispatch(basketProviderActions.makeBet())
    }, [dispatch])

    const isLoading = useMemo(
      () => EMakeBetStatus.loading === makeBetStatus,
      [makeBetStatus]
    )

    const isAddedExtraSpace = useMemo(
      () => outcomesLength === 1,
      [outcomesLength]
    )

    return (
      <>
        <BasketBonus />

        <StyledButtonWrapper
          data-test-id={ETestData.TestBasketButtonMakeABet}
          disabled={disabledButton}
          isAddedExtraSpace={isAddedExtraSpace}
          isAppliedCoupon={!!appliedCoupon}
          onClick={makeBet}
        >
          {isLoading ? (
            <StyledBasketButtonWrapper>
              <LoaderPoints />
            </StyledBasketButtonWrapper>
          ) : (
            <StyledButtonText
              disabled={disabledButton}
              isAppliedCoupon={!!appliedCoupon}
            >
              {t('make a bet')}
            </StyledButtonText>
          )}
        </StyledButtonWrapper>
      </>
    )
  }
)

// TODO add real data
export const TotalRate: FC<TotalRateProps> = memo(({ coefficient = 0 }) => {
  const bonus = false

  return (
    <StyledTotalRateWrapper>
      {bonus && (
        <StyledTotalRateBonusWrapper>
          <StyledTotalRateBonusSum>BONUS 1.02</StyledTotalRateBonusSum>
        </StyledTotalRateBonusWrapper>
      )}
      <StyledTotalRate>{coefficient.toFixed(2)}</StyledTotalRate>
    </StyledTotalRateWrapper>
  )
})

export const BetCardElement: FC<BetCardElementProps> = memo(
  ({ outcome, restriction }) => (
    <StyledBetCardElementWrapper>
      <BetCardCoupon outcome={outcome} restriction={restriction} />
      <StyledBetCardElementBorder />
    </StyledBetCardElementWrapper>
  )
)

export const HoverText: FC<HoverTextProps> = ({ text }) => (
  <StyledHoverTextWrapper>
    <StyledHoverText>{text}</StyledHoverText>
  </StyledHoverTextWrapper>
)

export const BasketOutcomeSingleError: FC<BasketOutcomeSingleErrorProps> = ({
  message
}) => {
  const [t] = useTranslation()

  return (
    <StyledBasketErrorWrapper>
      <StyledIconWrapper>
        <IconInfoCircleFilled />
      </StyledIconWrapper>
      {message && (
        <StyledBasketErrorText
          data-test-id={getCreateTestData(
            ETestData.TestBasketErrorText,
            message
          )}
        >
          {t(message)}
        </StyledBasketErrorText>
      )}
    </StyledBasketErrorWrapper>
  )
}

const getBasketErrorTrans = (message: IBasketError['message']) => {
  if (message === ALL_BASKET_ERROR_MESSAGES.BETS_INSUFFICIENT_BALANCE) {
    return BASKET_ERROR_ACTIONS[message]?.map(({ id, action }) => (
      <StyledLink key={id} to={action} />
    ))
  } else if (message === ALL_BASKET_ERROR_MESSAGES.CLIENT_BETS_BLOCK) {
    return BASKET_ERROR_ACTIONS[message]?.map(({ id, action }) => (
      <StyledBasketErrorAction key={id} onClick={action} />
    ))
  } else {
    return []
  }
}

export const BasketError: FC<BasketErrorBottomProps> = ({ error }) => {
  const [t] = useTranslation()
  const { message } = error

  return (
    <StyledBasketErrorWrapper>
      <StyledIconWrapper>
        <IconInfoCircleFilled />
      </StyledIconWrapper>
      <StyledBasketErrorText
        data-test-id={getCreateTestData(ETestData.TestBasketErrorText, message)}
      >
        <Trans
          components={getBasketErrorTrans(message)}
          i18nKey={message}
          t={t}
        />
      </StyledBasketErrorText>
    </StyledBasketErrorWrapper>
  )
}

export const InputMaxBet: FC<InputMaxBetProps> = ({
  maxBet,
  setMaxBetBlockWidth
}) => {
  const minBet = useAppSelector(selectCurrencyMinBet)
  const appliedCoupon = useAppSelector(selectBasketAppliedCoupon)

  const [dimensions, inputMaxBetRef] = useContainerDimensions()

  useEffect(() => {
    if (dimensions?.width) {
      setMaxBetBlockWidth(dimensions.width)
    }
  }, [dimensions, setMaxBetBlockWidth])

  const minMaxBet = useMemo(() => {
    return appliedCoupon
      ? ''
      : `${formatAmountLocaleString({
          value: minBet
        })} - ${formatAmountLocaleString({
          value: maxBet
        })}`
  }, [minBet, maxBet, appliedCoupon])

  return <StyledInputMaxBet ref={inputMaxBetRef}>{minMaxBet}</StyledInputMaxBet>
}

export const MaxBetHoverText: FC<MaxBetHoverTextProps> = ({ text }) => (
  <StyledMaxBetHoverTextWrapper>
    <StyledMaxBetHoverTriangle />
    <StyledMaxBetHoverText>{text}</StyledMaxBetHoverText>
  </StyledMaxBetHoverTextWrapper>
)

const transformOutcomes = (outcomes: BasketOutcome[]) =>
  outcomes.map((outcome) => ({
    outcomeId: outcome.id,
    coefficient: outcome.probability.odd
  }))

export const mapNewBetListItems = ({
  outcomes,
  systemSize,
  betAmount,
  betType
}: MapNewBetListItemsParams) => [
  {
    outcomes: transformOutcomes(outcomes),
    systemSize,
    bet: betAmount,
    betType: BetType[betType]
  }
]

export const HoverMaxBetText: FC<HoverMaxBetTextProps> = ({ maxBet }) => {
  const minBet = useAppSelector(selectCurrencyMinBet)

  const minMaxBet = useMemo(
    () =>
      `${formatAmountLocaleString({
        value: minBet
      })} - ${formatAmountCore({
        value: maxBet
      })}`,
    [minBet, maxBet]
  )

  return (
    <StyledHoverMaxBetTextWrapper>
      <StyledHoverMaxBetText>{minMaxBet}</StyledHoverMaxBetText>
      <StyledHoverMaxBetTextTriangle />
    </StyledHoverMaxBetTextWrapper>
  )
}
