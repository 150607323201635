import React, { FC, useState } from 'react'
import { useGroupedEventsDate } from 'astra-core/containers/EventsProvider'

import { ETestData } from 'shared/lib/testData'
import { ETableHeadVariant, TableBetsHead } from 'widgets/event-row-head'

import { DateGroup } from '../DateGroup'

import * as S from './TournamentGroup.styled'
import { TournamentGroupProps } from './TournamentGroup.types'

export const TournamentGroup: FC<TournamentGroupProps> = ({
  eventsGroup,
  page1StartRef,
  page1EndRef,
  page2StartRef,
  page2EndRef,
  beforeStartRef,
  beforeEndRef
}) => {
  const eventGroups = useGroupedEventsDate(eventsGroup.events)
  const [isOutcomesOpened, setIsOutcomesOpened] = useState(true)

  return (
    <S.TournamentGroup
      data-test-id={`${ETestData.TestTableEventBetsGroup}-${eventsGroup.id}`}
    >
      <TableBetsHead
        isOutcomesOpened={isOutcomesOpened}
        isShowCount={eventsGroup.events.length}
        setIsOutcomesOpened={setIsOutcomesOpened}
        tournament={eventsGroup}
        variant={ETableHeadVariant.TWO}
      />

      {isOutcomesOpened &&
        eventGroups.map((eventGroup) => (
          <DateGroup
            beforeEndRef={beforeEndRef}
            beforeStartRef={beforeStartRef}
            eventGroup={eventGroup}
            key={eventGroup.date}
            page1EndRef={page1EndRef}
            page1StartRef={page1StartRef}
            page2EndRef={page2EndRef}
            page2StartRef={page2StartRef}
          />
        ))}
    </S.TournamentGroup>
  )
}
