import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { selectAccountId } from 'astra-core/containers/AccountProvider'

import { ETestData } from 'shared/lib/testData'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import {
  SettingsWindow,
  StyledSettingsList,
  StyledSettingsListItems,
  StyledSettingsListTitle
} from 'widgets/user-account-settings/components/SettingsList/SettingsList.styled'
import { useAppSelector, useAppDispatch } from 'store'

import {
  SettingBalance,
  SettingDisplayLiveOrLineSection,
  SettingTheme
} from '..'

export const SettingsList = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const modalWrapperRef = useRef<HTMLDivElement>(null)
  const accountId = useAppSelector(selectAccountId)

  const handleModalClose = useCallback(() => {
    dispatch(layoutContainerActions.setIsDisplayModalSettings(false))
  }, [dispatch])

  const backDropHandler = useCallback(
    (e) => {
      if (modalWrapperRef?.current === e.target) {
        handleModalClose()
      }
    },
    [handleModalClose]
  )

  return (
    <SettingsWindow ref={modalWrapperRef} onClick={backDropHandler}>
      <StyledSettingsList>
        <StyledSettingsListTitle>
          {t('site settings')}

          <IconClose
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.FIXED,
              value: 50
            }}
            data-test-id={ETestData.TestSettingsClose}
            size={9}
            onClick={handleModalClose}
          />
        </StyledSettingsListTitle>

        <StyledSettingsListItems>
          {accountId && <SettingBalance />}
          <SettingTheme />
          <SettingDisplayLiveOrLineSection />
        </StyledSettingsListItems>
      </StyledSettingsList>
    </SettingsWindow>
  )
}
