import { createSelector } from '@reduxjs/toolkit'
import {
  selectEventResultsFilterIsLive,
  selectEventResultsFilterSearch,
  selectEventResultsTournamentsOptions
} from 'astra-core/containers/ResultsProviderV2/selectors'

import { RootState } from 'shared/types/store'

import { TournamentOption } from './types'

export const selectEventResultsContainerState = (state: RootState) => {
  return state.eventResultsContainer
}

export const selectCurrentTournamentId = (state: RootState) => {
  return selectEventResultsContainerState(state).tournamentId
}

export const selectTournamentIds = (state: RootState) => {
  return selectEventResultsContainerState(state).tournamentIds
}

export const selectTournamentOptions = (state: RootState) => {
  return selectEventResultsContainerState(state).tournamentOptionById
}

export const selectTournamentOptionsList = createSelector(
  [
    selectTournamentIds,
    selectTournamentOptions,
    selectEventResultsFilterIsLive,
    selectEventResultsFilterSearch,
    selectEventResultsTournamentsOptions
  ],
  (ids, optionsById, isLive, searchQuery, optionsFilteredByBackend) => {
    if (isLive === true || Boolean(searchQuery)) {
      return optionsFilteredByBackend
    }

    const options: TournamentOption[] = []
    ids.forEach((id) => {
      const option = optionsById[id]
      return options.push(option)
    })
    return options
  }
)
