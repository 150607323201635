import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'

import { useTimer } from 'hooks'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import {
  ENotificationContainerIds,
  TCustomToastContentProps,
  ToastifyContent
} from 'shared/lib/Toastify'
import { useAppDispatch } from 'store'

import { StyledDeleteHistoryHint } from './NotificationClearHistory.styled'

export const NotificationClearHistoryProgress: FC<TCustomToastContentProps> = ({
  toastProps
}) => {
  const [t] = useTranslation()
  const seconds = useTimer(5)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!seconds) {
      dispatch(betsHistoryProviderActions.deleteBets())

      toast.update(toastProps.toastId, {
        render: <NotificationClearHistorySuccess />,
        closeButton: false,
        containerId: ENotificationContainerIds.SYSTEM,
        autoClose: 1500,
        draggable: false,
        hideProgressBar: true,
        closeOnClick: false
      })
    }
  }, [dispatch, seconds, toastProps.toastId])

  return (
    <StyledDeleteHistoryHint>
      {t('deleted history', { times: seconds })}
    </StyledDeleteHistoryHint>
  )
}

export const NotificationClearHistorySuccess: FC = () => {
  const [t] = useTranslation()

  return (
    <ToastifyContent
      leftContent={
        <IconCheckCircleFilled
          colorProps={{
            name: EColorsNames.Green,
            type: EColorsTypes.FIXED,
            value: 4
          }}
        />
      }
    >
      {t('bets history deleted')}
    </ToastifyContent>
  )
}
