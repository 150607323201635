import { TFunction } from 'i18next'

import { GetReactSelectValueArgs } from 'pages/page-calendar/CalendarEvents/components/Filters/types'

export const defaultSelectAllChampionships = (t: TFunction) => ({
  value: null,
  label: t('all championships')
})

export const getReactSelectValue = ({
  championships,
  championshipId,
  t
}: GetReactSelectValueArgs) => {
  const value = championships.find(({ value }) => value === championshipId)

  return value ?? defaultSelectAllChampionships(t)
}
