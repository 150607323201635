import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { actions } from 'astra-core/containers/ResultsProviderV2/slice'
import {
  selectEventResultsFilterIsLive,
  selectEventResultsFilterDate
} from 'astra-core/containers/ResultsProviderV2/selectors'
import { dateToFilterDateRange } from 'astra-core/containers/ResultsProviderV2/utils'
import { CompletionStatus } from 'betweb-openapi-axios'

import { EInputSize, EInputView } from 'shared/ui/Input'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import {
  defaultSelectAllChampionships,
  getReactSelectValue
} from 'shared/lib/results'
import { ESelectView } from 'shared/ui/Select'
import { CustomButtonRange, DatePicker } from 'shared/ui/DatePicker'
import { Checkbox } from 'shared/ui/checkbox'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { RESULTS_DATE_FORMAT } from 'entities/results/model/constants'
import { useAppDispatch, useAppSelector } from 'store'
import { eventResultsContainerActions } from 'containers/EventResultsContainer/slice'
import {
  selectCurrentTournamentId,
  selectTournamentOptionsList
} from 'containers/EventResultsContainer/selectors'

import {
  StyledCheckboxText,
  StyledCheckboxWrapper,
  StyledHeaderResults,
  StyledHeaderWrapper,
  StyledIconResults,
  StyledInputWrapper,
  StyledLive,
  StyledSearchInput,
  StyledSelect,
  StyledSelectWrapper
} from './HeaderV2.styled'

export const HeaderV2 = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()

  /** isLive filter */
  const isLiveResults = useAppSelector(selectEventResultsFilterIsLive)
  const onChangeIsLive = useCallback(() => {
    const completionStatus = isLiveResults ? undefined : CompletionStatus.Live
    dispatch(
      actions.setFilters({
        filters: {
          completionStatus
        }
      })
    )
  }, [dispatch, isLiveResults])

  /** Query search filter */
  const onSearhQueryChange = useCallback(
    (event) => {
      dispatch(
        actions.setFilters({
          filters: {
            searchQuery: event.target.value ? event.target.value : undefined
          }
        })
      )
    },
    [dispatch]
  )

  const onSearhQueryChangeDebounced = useMemo(
    () => debounce(onSearhQueryChange, 500, { leading: true, trailing: true }),
    [onSearhQueryChange]
  )

  useEffect(() => {
    return () => {
      onSearhQueryChangeDebounced.cancel()
    }
  }, [onSearhQueryChangeDebounced])

  /** Tournament filter */
  const tournamentId = useAppSelector(selectCurrentTournamentId)
  const tournaments = useAppSelector(selectTournamentOptionsList)

  const selectOptions = useMemo(() => {
    return [defaultSelectAllChampionships(t), ...tournaments]
  }, [tournaments, t])

  const selectValue = useMemo(() => {
    return getReactSelectValue({
      championships: tournaments,
      championshipId: tournamentId,
      t
    })
  }, [tournaments, t, tournamentId])

  const onChangeTournament = useCallback(
    (selectedOption) => {
      const tournamentId = selectedOption.value
      if (tournamentId) {
        dispatch(
          eventResultsContainerActions.setTournamentId({
            tournamentId: selectedOption.value
          })
        )
        dispatch(
          actions.setFilters({
            filters: {
              tournamentIds: [tournamentId]
            }
          })
        )
      } else {
        dispatch(
          eventResultsContainerActions.setTournamentId({
            tournamentId: undefined
          })
        )
        dispatch(
          actions.setFilters({
            filters: {
              tournamentIds: undefined
            }
          })
        )
      }
    },
    [dispatch]
  )

  /** Date filter */
  const date = useAppSelector(selectEventResultsFilterDate)
  const setDateFilter = useCallback(
    (date: Date) => {
      dispatch(eventResultsContainerActions.reset())
      dispatch(
        actions.setFilters({
          filters: {
            ...dateToFilterDateRange(date),
            tournamentIds: undefined
          },
          shouldUpdateCounters: true
        })
      )
    },
    [dispatch]
  )
  const onChangeDate = useCallback(
    (date) => {
      setDateFilter(date)
    },
    [setDateFilter]
  )
  const onResetDatePicker = useCallback(() => {
    setDateFilter(new Date())
  }, [setDateFilter])

  return (
    <StyledHeaderWrapper>
      <StyledSelectWrapper>
        <StyledSelect
          getOptionLabel={(e) => (
            <>
              {e.label}
              {e.isLive && <StyledLive>{t('Live')}</StyledLive>}
            </>
          )}
          isSearchable={false}
          options={selectOptions}
          value={selectValue}
          view={ESelectView.Results}
          onChange={onChangeTournament}
        />
      </StyledSelectWrapper>

      <DatePicker
        customInput={
          <CustomButtonRange
            singleDateFormat={RESULTS_DATE_FORMAT}
            startDate={date}
            isSingleDate
            onReset={onResetDatePicker}
          />
        }
        maxDate={new Date()}
        selected={date}
        onChange={onChangeDate}
      />
      <StyledCheckboxWrapper>
        <Checkbox checked={isLiveResults} onChange={onChangeIsLive} />
        <StyledCheckboxText>{t('current only')}</StyledCheckboxText>
      </StyledCheckboxWrapper>
      <StyledInputWrapper>
        <StyledSearchInput
          customSize={EInputSize.M}
          icon={IconSearch}
          placeholder={t('search')}
          view={EInputView.SECONDARY}
          onChange={onSearhQueryChangeDebounced}
        />
      </StyledInputWrapper>
      {/* <StyledCollapseAllText onClick={handleToggleCollapseAllText}>
        {buttonToggleCollapseText}
      </StyledCollapseAllText> */}
    </StyledHeaderWrapper>
  )
}

export const HeaderResultsV2 = () => {
  const [t] = useTranslation()

  const IconCalendar = (
    <StyledIconResults
      colorProps={{
        name: EColorsNames.Green,
        type: EColorsTypes.DEFAULT,
        value: 50
      }}
      size={24}
    />
  )
  return (
    <StyledHeaderResults>
      <TitleLinePage iconComponent={IconCalendar} title={t('results')}>
        <HeaderV2 />
      </TitleLinePage>
    </StyledHeaderResults>
  )
}
