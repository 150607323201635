import { all, select, takeEvery, put, call } from 'redux-saga/effects'
import { createAction, PayloadAction } from '@reduxjs/toolkit'
import { BetPayload } from 'og-web-core/providers/bet/types'
import { selectAccountId } from 'astra-core/containers/AccountProvider/selectors'
import { ogBetSaga } from 'og-web-core/providers/bet/saga'

import { rightBasketContainerState } from '../BasketContainer/slice'
import { selectIsShowBasketOnSmallWindow } from '../BasketContainer/selectors'

const ACTION_TYPE = 'betContainer/makeBet'

export function* betSaga(action: PayloadAction<BetPayload>) {
  const accountId = yield select(selectAccountId)
  const isShowBasket = yield select(selectIsShowBasketOnSmallWindow)

  if (!accountId) {
    return yield put(
      rightBasketContainerState.toggleBasketOnSmallWindow({
        isShow: !isShowBasket
      })
    )
  }

  yield call(ogBetSaga, action.payload)
}

export const makeBet = createAction<BetPayload>(ACTION_TYPE)

export function* betContainerSaga() {
  yield all([takeEvery(makeBet, betSaga)])
}
