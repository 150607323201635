import React, { FC, PropsWithChildren } from 'react'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

import { InfinityPaginationProps } from './infinityPagination.types'
import * as S from './InfinityPaginationProps.styled'

export const InfinityPagination: FC<
  PropsWithChildren<InfinityPaginationProps>
> = ({
  children,
  isPaginationLoading,
  hasMore,
  onLoadMore,
  variant = 'default'
}) => {
  const { loadingRef } = useInfiniteScroll(onLoadMore, isPaginationLoading)

  return (
    <>
      {children}

      {hasMore && (
        <S.LoaderWrapper $variant={variant} ref={loadingRef}>
          {isPaginationLoading && <LoaderSpinner />}
        </S.LoaderWrapper>
      )}
    </>
  )
}
