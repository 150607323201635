import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react'
import {
  eventsProviderActions,
  selectEventStatistics
} from 'astra-core/containers/EventsProvider'
import { getHomeAwayCompetitorsRenamed } from 'astra-core/utils/events'

import { LayoutEventContext } from 'widgets/Layout/components/Event/context'
import { useCompetitorsRenamed, useInterval } from 'hooks'
import { EEventStatisticsCodes } from 'widgets/event-statistics'
import {
  EventContext,
  IEventContext
} from 'pages/page-event/Event/Event.context'
import { EVENT_STATISTICS_POLLING_INTERVAL } from 'pages/page-event/Event/components/EventBanner/constants'
import { getBannerScore } from 'pages/page-event/Event/components/EventBanner/utils'
import { useAppSelector, useAppDispatch } from 'store'

export const useEventBannerData = () => {
  const dispatch = useAppDispatch()
  const { event } = useContext(EventContext) as IEventContext
  const containerRef = useRef(null)
  const { getCoordsEventBanner } = useContext(LayoutEventContext) || {}
  const { renameDate, isNewSbCompetitorsNameEnabled } = useCompetitorsRenamed()

  useLayoutEffect(() => {
    getCoordsEventBanner?.(containerRef)
  }, [getCoordsEventBanner])

  const competitors = useMemo(
    () =>
      getHomeAwayCompetitorsRenamed({
        event,
        renameDate,
        isNewSbCompetitorsNameEnabled
      }),
    [event, renameDate, isNewSbCompetitorsNameEnabled]
  )

  const fetchEventStatistics = useCallback(() => {
    if (event.live) {
      dispatch(
        eventsProviderActions.fetchEventStatistics({ eventId: event.id })
      )
    }
  }, [dispatch, event.id, event.live])

  useInterval(
    fetchEventStatistics,
    event.live ? EVENT_STATISTICS_POLLING_INTERVAL : null,
    true
  )

  const eventStatistics = useAppSelector((state) =>
    selectEventStatistics(state, event.id)
  )

  const bannerScore = useMemo(
    () => getBannerScore(eventStatistics, event.live),
    [event.live, eventStatistics]
  )

  const currentServer = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.CurrentServer
  )

  const matchPhase = event.statistics.find(
    (stat) => stat.code === EEventStatisticsCodes.MatchPhase
  )

  return { bannerScore, competitors, containerRef, currentServer, matchPhase }
}
