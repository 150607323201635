import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  sportCountersProviderActions,
  ESportsCounterType
} from 'astra-core/containers/SportCountersProvider'
import { DefaultCountersReq } from 'astra-core/containers/SportCountersProvider/constants'

import { useAppDispatch } from 'store'

import { ResultsMenuListV2 } from '../ResultsMenuListV2'

import {
  StyledResultsMenuTitle,
  StyledSportsMenu,
  StyledTabsWrapper,
  StyledWrapper
} from './ResultsSportCountersV2.styled'

export const ResultsSportCountersV2 = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()

  useEffect(() => {
    const reqData = DefaultCountersReq.RESULTS

    dispatch(
      sportCountersProviderActions.fetchEventResultCountersV2({
        key: ESportsCounterType.RESULTS,
        reqData
      })
    )
  }, [dispatch])

  return (
    <StyledSportsMenu>
      <StyledWrapper>
        <StyledTabsWrapper>
          <StyledResultsMenuTitle>{t('kind of sport')}</StyledResultsMenuTitle>
        </StyledTabsWrapper>
        <ResultsMenuListV2 />
      </StyledWrapper>
    </StyledSportsMenu>
  )
}
