import React, { FC, memo, useCallback, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import * as Templates from 'astrabet-templates-kit'
import {
  makeMarketOutcomesSelector,
  makeSelectEventProbabilityByMarketFilteredV3,
  makeEventCompetitorsRenameSelector,
  selectEventCompetitors
} from 'astra-core/containers/EventsProvider'
import { EventProbabilityTradingStatus } from 'betweb-openapi-axios'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { ProbabilityWithOutcome } from 'astra-core/utils/outcomes'

import { useAppSelector } from 'store'

import { OutcomesTable } from '../OutcomesTable'
import { Outcome } from '../Outcome'

import { MarketProps, MarketV2Props } from './Market.types'

export const Market: FC<MarketProps> = memo(
  ({ eventId, market, isFirst, isLast, isMerged }) => {
    const isShowAllEventProbs = useFeatureFlag(
      EFeatureFlags.SHOW_ALL_EVENT_PROBS
    )

    const competitors = useAppSelector((state) =>
      selectEventCompetitors(state, eventId)
    )

    const selector = makeSelectEventProbabilityByMarketFilteredV3()
    const outcomes = useAppSelector((state) =>
      selector(
        state,
        eventId,
        market.filteredBy.marketId,
        market.id,
        isShowAllEventProbs ? undefined : EventProbabilityTradingStatus.Trading
      )
    )

    const TemplatedMarket = useMemo(
      () =>
        Templates[
          `${market.outcomesGroup.renderTemplateId ?? 'K1Template'}New`
        ] ?? Templates.K1TemplateNew,
      [market.outcomesGroup.renderTemplateId]
    )

    const renderOdd = useCallback(
      (outcome: ProbabilityWithOutcome) => (
        <Outcome
          eventProbability={{
            ...outcome,
            eventId
          }}
        />
      ),
      [eventId]
    )

    return (
      <TemplatedMarket
        categoryMarket={market}
        competitors={competitors}
        isLast={isLast}
        OddsTableComponent={OutcomesTable}
        probabilities={outcomes}
        renderOdd={renderOdd}
        withoutDivider={isFirst || isMerged}
        withoutTitle={isMerged}
      />
    )
  },
  isEqual
)

export const MarketV2: FC<MarketV2Props> = memo((props) => {
  const { eventId, market, isFirst, isLast, isMerged } = props
  const isShowAllEventProbs = useFeatureFlag(EFeatureFlags.SHOW_ALL_EVENT_PROBS)

  const renameSelector = useMemo(
    () => makeEventCompetitorsRenameSelector(eventId),
    [eventId]
  )
  const competitors = useAppSelector((state) => renameSelector(state))

  const selector = makeMarketOutcomesSelector()
  const outcomes = useAppSelector((state) => {
    return selector(
      state,
      eventId,
      market.id,
      isShowAllEventProbs ? undefined : EventProbabilityTradingStatus.Trading
    )
  })

  const TemplatedMarket = useMemo(
    () =>
      Templates[
        `${market.outcomesGroup.renderTemplateId ?? 'K1Template'}New`
      ] ?? Templates.K1TemplateNew,
    [market.outcomesGroup.renderTemplateId]
  )

  const renderOdd = useCallback(
    (outcome: ProbabilityWithOutcome) => (
      <Outcome
        eventProbability={{
          ...outcome,
          eventId
        }}
      />
    ),
    [eventId]
  )

  return (
    <TemplatedMarket
      categoryMarket={market}
      competitors={competitors}
      isLast={isLast}
      OddsTableComponent={OutcomesTable}
      probabilities={outcomes}
      renderOdd={renderOdd}
      withoutDivider={isFirst || isMerged}
      withoutTitle={isMerged}
    />
  )
}, isEqual)

Market.displayName = 'Market'
MarketV2.displayName = 'MarketV2'
