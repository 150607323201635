import { useParams } from 'react-router-dom'
import {
  eventsProviderActions,
  getEventListFilters,
  getIsEventListLoading,
  getIsEventListPolling
} from 'astra-core/containers/EventsProvider'
import { useCallback, useEffect } from 'react'

import { useInterval } from 'hooks'
import { useAppDispatch, useAppSelector } from 'store'

import { LINE_EVENTS_LIST_KEY, LINE_POLLING_INTERVAL } from '../constants'
import { LineParams } from '../types'

export const usePollLineEvents = () => {
  const dispatch = useAppDispatch()
  const filters = useAppSelector((state) =>
    getEventListFilters(state, LINE_EVENTS_LIST_KEY)
  )
  const isLoading = useAppSelector((state) =>
    getIsEventListLoading(state, LINE_EVENTS_LIST_KEY)
  )

  const isPolling = useAppSelector((state) =>
    getIsEventListPolling(state, LINE_EVENTS_LIST_KEY)
  )

  const { tournamentId, sportId } = useParams<LineParams>()

  useEffect(() => {
    if (sportId) {
      dispatch(
        eventsProviderActions.fetchEventList({
          listKey: LINE_EVENTS_LIST_KEY,
          request: {
            tournamentIds: [+tournamentId],
            scheduledFromNow: true
          }
        })
      )
    }
  }, [dispatch, sportId, filters, tournamentId])

  const shouldTick = !(isLoading || isPolling)

  const pollLiveEvents = useCallback(() => {
    if (sportId && shouldTick) {
      dispatch(
        eventsProviderActions.pollEventList({
          listKey: LINE_EVENTS_LIST_KEY,
          request: {
            tournamentIds: [+tournamentId],
            scheduledFromNow: true
          }
        })
      )
    }
  }, [sportId, tournamentId, shouldTick, dispatch])

  useInterval(pollLiveEvents, LINE_POLLING_INTERVAL, false)
}
