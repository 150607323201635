import React, { FC } from 'react'
import { selectSport } from 'astra-core/containers/CommonDataProvider/selectors'

import SportHeader from 'shared/ui/SportHeader/SportHeader'
import { useAppSelector } from 'store'

import { SportGroupHeaderProps } from './SportGroupHeader.types'

export const SportGroupHeader: FC<SportGroupHeaderProps> = ({ group }) => {
  const sport = useAppSelector((s) => selectSport(s, group.sportId))

  return <SportHeader sport={sport} />
}
