import React, { useCallback, useEffect } from 'react'
import { useGroupedCalendarEvents } from 'astra-core/hooks/useEvents'

import {
  selectCalendarEventsDataEntries,
  selectCalendarEventsPagination,
  selectFilterInputSearch,
  selectFilterSelectInputPeriod
} from 'containers/CalendarEventsContainer/selectors'
import { actions as calendarEventsContainerActions } from 'containers/CalendarEventsContainer/slice'
import { TableCalendar } from 'pages/page-calendar/CalendarEvents/components'
import { ClearResultSearch } from 'shared/ui/ClearResult'
import { EmptyPageFilter } from 'shared/ui/EmptyPageFilter'
import { useAppSelector, useAppDispatch } from 'store'

import {
  StyledCalendarEvents,
  StyledClearResultSearch,
  StyledDataList
} from './CalendarEvents.styled'
import { PAGINATION_SETTINGS } from './constants'

export const CalendarEvents = () => {
  const dispatch = useAppDispatch()
  const filterPeriod = useAppSelector(selectFilterSelectInputPeriod)
  const events = useAppSelector(selectCalendarEventsDataEntries)
  const searchText = useAppSelector(selectFilterInputSearch)
  const groupedEvents = useGroupedCalendarEvents(events)
  const pagination = useAppSelector(selectCalendarEventsPagination)

  useEffect(() => {
    dispatch(
      calendarEventsContainerActions.fetchScheduledEvents({
        ...filterPeriod,
        ...pagination
      })
    )
  }, [dispatch, filterPeriod, pagination])

  const clearInputSearch = useCallback(() => {
    dispatch(calendarEventsContainerActions.setResetFilterInputSearch())
  }, [dispatch])

  return (
    <StyledCalendarEvents>
      <StyledDataList pagination={PAGINATION_SETTINGS}>
        {groupedEvents.length ? (
          <TableCalendar groupedEvents={groupedEvents} />
        ) : searchText ? (
          <StyledClearResultSearch>
            <ClearResultSearch />
          </StyledClearResultSearch>
        ) : (
          <EmptyPageFilter onButtonClickResetFilter={clearInputSearch} />
        )}
      </StyledDataList>
    </StyledCalendarEvents>
  )
}
