import React, { FC, memo, useCallback, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import * as Templates from 'astrabet-templates-kit'
import {
  selectEventCompetitors,
  selectEventProbabilityByMarketFiltered
} from 'astra-core/containers/EventsProvider'
import { EventProbabilityTradingStatus } from 'betweb-openapi-axios'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { ProbabilityWithOutcome } from 'astra-core/utils/outcomes'

import { useAppSelector } from 'store'

import { OutcomesTable } from '../OutcomesTable'
import { Outcome } from '../Outcome'

import { MarketProps } from './Market.types'

export const Market: FC<MarketProps> = memo(
  ({ eventId, market, isFirst, isLast, isMerged }) => {
    const isShowAllEventProbs = useFeatureFlag(
      EFeatureFlags.SHOW_ALL_EVENT_PROBS
    )

    const competitors = useAppSelector((state) =>
      selectEventCompetitors(state, eventId)
    )

    const outcomes = useAppSelector((state) =>
      selectEventProbabilityByMarketFiltered(
        state,
        eventId,
        market.marketId,
        market.id,
        isShowAllEventProbs ? undefined : EventProbabilityTradingStatus.Trading
      )
    )

    const TemplatedMarket = useMemo(
      () =>
        Templates[market.templateId ?? 'K1Template'] ?? Templates.K1Template,
      [market.templateId]
    )

    const renderOdd = useCallback(
      (outcome: ProbabilityWithOutcome) => (
        <Outcome
          eventProbability={{
            ...outcome,
            eventId
          }}
        />
      ),
      [eventId]
    )

    return (
      <TemplatedMarket
        categoryMarket={market}
        competitors={competitors}
        isLast={isLast}
        OddsTableComponent={OutcomesTable}
        probabilities={outcomes}
        renderOdd={renderOdd}
        withoutDivider={isFirst || isMerged}
        withoutTitle={isMerged}
      />
    )
  },
  isEqual
)

Market.displayName = 'Market'
