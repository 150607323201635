import { EEventStatisticsCodes } from '../../EventStatistics.types'

export const EVENT_STATISTIC_CODES = [
  EEventStatisticsCodes.Score,
  EEventStatisticsCodes.GameScore,
  EEventStatisticsCodes.ScoresByPeriods,
  EEventStatisticsCodes.AddTime,
  EEventStatisticsCodes.ScoresInfo
]

export const LINKED_EVENT_STATISTIC_CODES = [
  EEventStatisticsCodes.Score,
  EEventStatisticsCodes.ScoresByPeriods
]
