import styled, { css } from 'styled-components'

import { InfinityPaginationProps } from './infinityPagination.types'

export const LoaderWrapper = styled.div<{
  $variant?: InfinityPaginationProps['variant']
}>`
  ${({ $variant }) => {
    if ($variant === 'fullHeight') {
      return css`
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      `
    }
  }}
`
