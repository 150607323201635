import React from 'react'
import { useTranslation } from 'react-i18next'

import { ETestData } from 'shared/lib/testData'
import { IconClearResultSearch } from 'shared/ui/Icon/General/IconClearResultSearch'

import {
  StyledClearResultSearch,
  StyledClearResultSearchIcon,
  StyledClearResultSearchText,
  StyledClearResultSearchTextNotResults
} from './ClearResult.styled'

export const ClearResultSearch = () => {
  const { t } = useTranslation()
  return (
    <StyledClearResultSearch>
      <StyledClearResultSearchIcon>
        <IconClearResultSearch size={64} />
      </StyledClearResultSearchIcon>
      <StyledClearResultSearchTextNotResults
        data-test-id={ETestData.TestSearchSearchHasNotGivenAnyResults}
      >
        {t('search has not given any results')}
      </StyledClearResultSearchTextNotResults>
      <StyledClearResultSearchText
        dangerouslySetInnerHTML={{ __html: t('try other keywords') }}
      />
    </StyledClearResultSearch>
  )
}
