import React from 'react'
import { useParams } from 'react-router-dom'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { getRouteParamAtIndex } from 'shared/lib/routes/helpers'
import { useRedirectToSport } from 'hooks/useRedirectToSport'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { PageLiveEventsV2 } from '../live-events-V2'

import {
  liveContainerActions,
  reducerLive,
  sliceKeyLive
} from './LiveContainer/slice'
import { liveContainerSaga } from './LiveContainer/saga'
import { getFetchLiveReqData } from './LiveContainer/utils'
import { LiveParams } from './LiveContainer/types'
import { LiveContainer } from './LiveContainer'
import { ELineHeaderTypes } from './Line/components/ui/LineHeader/LineHeader.types'

export const PageLiveEvents = () => {
  useInjectReducer({ key: sliceKeyLive, reducer: reducerLive })
  useInjectSaga({ key: sliceKeyLive, saga: liveContainerSaga })

  const { sportId } = useParams<LiveParams>()
  useRedirectToSport({
    sportId: +sportId,
    currentTabMenu: ESportsMenuTabTypes.LIVE
  })

  const virtualScrollEnabled = useFeatureFlag(EFeatureFlags.NEW_EVENTS_ENDPOINT)

  if (virtualScrollEnabled) {
    return <PageLiveEventsV2 />
  }

  return (
    <Layout type={ELayoutsList.SPORT} typeHeader={ELineHeaderTypes.LIVE}>
      <LiveContainer />
    </Layout>
  )
}

export const pageLiveEventsPrefetch = ({ store, req }) => {
  store.dispatch(
    liveContainerActions.fetchLive(
      getFetchLiveReqData({ sportId: getRouteParamAtIndex(req.params, 1) })
    )
  )
}
