import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Event, EventStatus } from 'betweb-openapi-axios'
import { getEventStatusType } from 'astra-core/utils/events'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import { HotProbsMarketsOutcomesMapItem } from 'astra-core/containers/CommonDataProvider'
import isEqual from 'react-fast-compare'
import { useGetHotProbsSportMarketOutcomeTypes } from 'astra-core/hooks/useGetHotProbsSportMarketOutcomeTypes'
import { useLocation } from 'react-router-dom'

import { ETestData } from 'shared/lib/testData'
import {
  CoefficientTableCellStyled,
  EventRow as EventRowEntity,
  EventRowExpandButton
} from 'entities/event'
import { useEventRowHandleHover } from 'features/event'
import {
  Outcomes,
  OutcomesComponentType
} from 'pages/page-event/Event/components/Outcomes'
import {
  Outcomes as OutcomesV3,
  OutcomesComponentType as OutcomesComponentTypeV3,
  OutcomesWithFetchType
} from 'pages/page-event/Event/components/OutcomesV3'
import { ERoutes } from 'shared/types/routes'
import { useAppDispatch, useAppSelector } from 'store'
import { selectIsEventOutcomesExpanded } from 'containers/EventsContainer/selectors'
import { eventExpandActions } from 'containers/EventsContainer/slice'

import { TableBetsRowContext } from '../../lib/context'
import { TeamWrapper } from '../teams-names'
import { EventRowCoefficient } from '../EventRowCoefficient'
import { LinkedEvents } from '../linked-events'
import { OutcomesHead } from '../outcomes-head'
import { EventInfo } from '../event-info'
import { EventRowMessage } from '../message'
import { EventStatisticBlockOtherInfo } from '../../../event-statistics'
import { LINKED_EVENT_STATISTIC_CODES } from '../../../event-statistics/components/EventStatisticBlockOtherInfo/constants'

import { EventRowProps } from './event-row.types'
import { LinkedEventInfo } from './event-row.styled'

const CoefficientsList: FC<{
  outcomeTypes?: HotProbsMarketsOutcomesMapItem[]
  event: Event
}> = ({ outcomeTypes, event }) => {
  return (
    <>
      {outcomeTypes?.map((coefficientData) => (
        <EventRowCoefficient
          {...coefficientData}
          event={event}
          key={coefficientData.outcomeTypesName[0]}
        />
      ))}
    </>
  )
}

const toggleTestData = {
  expand: ETestData.TestEventRowFilterExpandAll,
  collapsed: ETestData.TestEventRowFilterCollapseAll
}

export const EventRow: FC<EventRowProps> = memo(
  ({ event, isMainPage, parentEventId, isOverview, outcomesFetchType }) => {
    const isOutcomesOpened = useAppSelector((state) =>
      selectIsEventOutcomesExpanded(state, event.id)
    )

    const isShowAllEventProbs = useFeatureFlag(
      EFeatureFlags.SHOW_ALL_EVENT_PROBS
    )

    const isLinkedEventsEnabled = useFeatureFlag(
      EFeatureFlags.LINKED_EVENTS_ENABLED
    )

    const newOutcomesEnabled = useFeatureFlag(
      EFeatureFlags.NEW_OUTCOMES_ENABLED
    )
    const newOutcomesStructEnabled = useFeatureFlag(
      EFeatureFlags.NEW_OUTCOMES_STRUCT_ENABLED
    )

    const [isLinkedEventsOpened, setIsLinkedEventsOpen] = useState(true)
    const toggleLinkedEventsOpen = useCallback(() => {
      setIsLinkedEventsOpen((prev) => !prev)
    }, [])

    const { pathname } = useLocation()
    const isNotSearchPage = pathname !== ERoutes.Search
    const outcomesRef = useRef(null)

    const isSuspended = event.status === EventStatus.Suspended

    const dispatch = useAppDispatch()
    const toggleOutcomesOpen = useCallback(() => {
      // Do not open suspended event
      if (isSuspended && isOutcomesOpened === false) {
        return
      }
      dispatch(
        eventExpandActions.toggleEvent({
          nextState: !isOutcomesOpened,
          eventId: event.id
        })
      )
    }, [event.id, isOutcomesOpened, dispatch, isSuspended])

    const isOutcomesOpenedRef = React.useRef(isOutcomesOpened)
    useEffect(() => {
      isOutcomesOpenedRef.current = isOutcomesOpened
    }, [isOutcomesOpened])
    useEffect(() => {
      return () => {
        if (isOutcomesOpenedRef.current) {
          dispatch(
            eventExpandActions.close({
              eventId: event.id
            })
          )
        }
      }
    }, [dispatch, event.id])

    const showLinkedEvents =
      isLinkedEventsOpened && isLinkedEventsEnabled && isNotSearchPage

    const { handleMouseEnter, handleMouseLeave } = useEventRowHandleHover({
      eventId: event.id,
      isHoverAvailable: !isOutcomesOpened
    })

    const isLoadingOutcomes = isShowAllEventProbs
      ? !event
      : event.status === EventStatus.Suspended

    const { outcomeTypes } = useGetHotProbsSportMarketOutcomeTypes({
      sportId: event.tournament.sportId
    })

    const { eventStatusType } = getEventStatusType(event)

    return (
      <TableBetsRowContext.Provider value={{ event }}>
        <EventRowEntity
          coefficientsItems={
            <CoefficientsList event={event} outcomeTypes={outcomeTypes} />
          }
          expandedData={
            isOutcomesOpened && (
              <OutcomesHead
                event={event}
                inputTestData={ETestData.TestEventRowInputSearch}
                outcomesRef={outcomesRef}
                toggleTestData={toggleTestData}
              />
            )
          }
          infoCellComponents={
            parentEventId ? (
              <LinkedEventInfo>
                <TeamWrapper event={event}>
                  {event?.eventType?.name}
                </TeamWrapper>
                <EventStatisticBlockOtherInfo
                  event={event}
                  statisticsCodes={LINKED_EVENT_STATISTIC_CODES}
                  adaptiveScoreByPeriods
                />
              </LinkedEventInfo>
            ) : (
              <EventInfo
                event={event}
                isLinkedEventsOpened={isLinkedEventsOpened}
                isMainPage={isMainPage}
                toggleLinkedEventsOpen={toggleLinkedEventsOpen}
              />
            )
          }
          lastCell={
            <CoefficientTableCellStyled withExpandButton>
              <EventRowExpandButton onClick={toggleOutcomesOpen}>
                {event.outcomesCount}
              </EventRowExpandButton>
            </CoefficientTableCellStyled>
          }
          linkedEvents={
            showLinkedEvents &&
            event.linkedEvents && (
              <LinkedEvents
                linkedEvents={event.linkedEvents}
                outcomesFetchType={outcomesFetchType}
                parentEventId={event.id}
              />
            )
          }
          outcomesComponent={
            isOutcomesOpened ? (
              newOutcomesEnabled ? (
                newOutcomesStructEnabled ? (
                  <OutcomesWithFetchType
                    dataFetchType={outcomesFetchType}
                    eventId={event.id}
                    eventStatusType={eventStatusType}
                    isLoading={isLoadingOutcomes}
                    outcomesRef={outcomesRef}
                  />
                ) : (
                  <OutcomesV3
                    eventId={event.id}
                    eventStatusType={eventStatusType}
                    isLoading={isLoadingOutcomes}
                    outcomesRef={outcomesRef}
                    type={OutcomesComponentTypeV3.EVENTS_LIST}
                  />
                )
              ) : (
                <Outcomes
                  eventId={event.id}
                  eventStatusType={eventStatusType}
                  isLoading={isLoadingOutcomes}
                  outcomesRef={outcomesRef}
                  type={OutcomesComponentType.EVENTS_LIST}
                />
              )
            ) : null
          }
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          isOverview={isOverview}
          message={!!outcomeTypes?.length && isSuspended && <EventRowMessage />}
        />
      </TableBetsRowContext.Provider>
    )
  },
  isEqual
)
