import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import { useAppDispatch } from 'store'

export const useAuthTypeModal = () => {
  const dispatch = useAppDispatch()

  const setAuthTypeModal = (types: boolean) => {
    dispatch(layoutContainerActions.setIsAuthTypeModal(types))
  }

  return setAuthTypeModal
}
