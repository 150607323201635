import React, { FC, memo, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import {
  clearPerformanceMeasurements,
  EPerformanceEvent,
  markEvent
} from 'astra-core'
import { selectEventStatusById } from 'astra-core/containers/EventsProvider'
import { selectEventById } from 'astra-core/containers/EventsProvider/selectors'

import { useAppSelector } from 'store'
import { useEventMode, useTitleMergedColumns } from 'hooks/events'
import { useFontOptions } from 'hooks/useFontOptions'
import { useHandleAddCoefficientToBasket } from 'features/event'
import {
  StyledEventOdd,
  StyledEventOddLabel,
  StyledEventOddTitle,
  StyledOddWrapper
} from 'pages/page-live-events/Line/components/ui/TableBets/TableBets.styled'
import { OddModes } from 'pages/page-live-events/Line/Line.types'

import { OutcomeProps } from './Outcome.types'

export const Outcome: FC<OutcomeProps> = memo(({ eventProbability }) => {
  const { labelInButton, eventId } = eventProbability

  const { options, stringRef, containerRef } = useFontOptions()

  const eventStatus = useAppSelector((state) =>
    selectEventStatusById(state, eventId)
  )

  const event = useAppSelector((state) => selectEventById(state, eventId))

  const outcomeMode = useEventMode({ eventProbability, eventStatus })
  const title = useTitleMergedColumns(eventProbability)

  const handleAddOutcomeToBasket = useHandleAddCoefficientToBasket()

  const onClickAddOutcomeToBasket = useCallback(() => {
    if (outcomeMode !== OddModes.Disabled) {
      clearPerformanceMeasurements() // To start a new measurement of Adding Outcome Flow
      markEvent(EPerformanceEvent.OutcomeClick) // Performance Tests

      handleAddOutcomeToBasket({
        eventProbability,
        eventId,
        eventStatus,
        live: event.live
      })
    }
  }, [
    outcomeMode,
    handleAddOutcomeToBasket,
    eventProbability,
    eventId,
    eventStatus,
    event.live
  ])

  return (
    <StyledOddWrapper
      $mode={outcomeMode}
      labelInButton={labelInButton}
      onClick={onClickAddOutcomeToBasket}
    >
      {!!labelInButton && (
        <StyledEventOddLabel ref={containerRef}>
          <StyledEventOddTitle
            style={{
              fontSize: options.fontSize,
              lineClamp: options.lineClamp,
              whiteSpace: options.whiteSpace
            }}
            ref={stringRef}
          >
            {title}
          </StyledEventOddTitle>
        </StyledEventOddLabel>
      )}

      <StyledEventOdd $mode={outcomeMode}>
        {eventProbability?.odd}
      </StyledEventOdd>
    </StyledOddWrapper>
  )
}, isEqual)

Outcome.displayName = 'Outcome'
