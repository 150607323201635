import React, { FC } from 'react'

import { useAppSelector } from 'store'
import { selectIsDisplayModalChangeLanguage } from 'containers/LayoutContainer/selectors'

import { LanguagesList } from './components'

export const ChangeLanguage: FC = () => {
  const isDisplayModal = useAppSelector(selectIsDisplayModalChangeLanguage)

  return isDisplayModal ? <LanguagesList /> : null
}
