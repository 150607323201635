import React, { useMemo } from 'react'
import { Event, OutcomeCategory, OutcomeType } from 'betweb-openapi-axios'
import { EventProbabilityWithPlayerName } from 'astra-core/utils/basket/types'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { useFeatureFlag } from 'astra-core/containers/ConfigProvider/utils'
import { EFeatureFlags } from 'astra-core/containers/ConfigProvider/types'
import {
  selectVirtualCategoryInfo,
  selectVirtualMarketInfo,
  selectVirtualMarketInfosMap
} from 'astra-core/containers/CommonDataProvider/selectors'
import { getOutcomeNameInBasket } from 'astra-core/utils/outcomes/computeEventProbabilitiesByMarket/v3'
import { selectConfig } from 'astra-core/containers/ConfigProvider/selectors'
import { getHomeAwayCompetitorsRenamed } from 'astra-core/utils/events'
import { selectPlayers } from 'astra-core/containers/PlayersProvider/selectors'

import { useAppSelector } from 'store'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconInfoCircle } from 'shared/ui/Icon/General/IconInfoCircle'
import { useCompetitorsRenamed } from 'hooks'

import { StyledEventType, StyledEventTypeWrapper } from './OutcomeName.styled'

interface Props {
  event: Event
  outcomeCategory: OutcomeCategory | undefined
  outcomeType: OutcomeType | undefined
  probability: EventProbabilityWithPlayerName
}

export const OutcomeName = ({
  event,
  outcomeCategory,
  outcomeType,
  probability
}: Props) => {
  const newOutcomesEnabled = useFeatureFlag(EFeatureFlags.NEW_OUTCOMES_ENABLED)
  return newOutcomesEnabled ? (
    <OutcomeNameNew
      event={event}
      outcomeType={outcomeType}
      probability={probability}
    />
  ) : (
    <OutcomeNameDeprecated
      event={event}
      outcomeCategory={outcomeCategory}
      outcomeType={outcomeType}
      probability={probability}
    />
  )
}

const OutcomeNameNew = ({
  event,
  outcomeType,
  probability
}: {
  event: Event
  outcomeType: OutcomeType | undefined
  probability: EventProbabilityWithPlayerName
}) => {
  const virtualMarketInfosMap = useAppSelector(selectVirtualMarketInfosMap)
  const virtualMarketInfo = useAppSelector((state) => {
    if (probability.virtualMarketId) {
      return selectVirtualMarketInfo(state, probability.virtualMarketId)
    }
  })
  const virtualCategoryInfo = useAppSelector((state) => {
    if (virtualMarketInfo) {
      return selectVirtualCategoryInfo(state, virtualMarketInfo.vCategoryId)
    }
  })

  const config = useAppSelector(selectConfig)
  const playersMap = useAppSelector(selectPlayers)
  const { renameDate, isNewSbCompetitorsNameEnabled } = useCompetitorsRenamed()

  const outcomeName = useMemo(() => {
    if (!outcomeType) return ''
    const eventCompetitors = getHomeAwayCompetitorsRenamed({
      event,
      renameDate,
      isNewSbCompetitorsNameEnabled
    })
    return getOutcomeNameInBasket(
      probability,
      virtualMarketInfosMap,
      outcomeType,
      config.CLIENT_TYPE,
      playersMap,
      eventCompetitors,
      virtualMarketInfo,
      virtualCategoryInfo
    )
  }, [
    outcomeType,
    event,
    renameDate,
    isNewSbCompetitorsNameEnabled,
    probability,
    virtualMarketInfosMap,
    config.CLIENT_TYPE,
    playersMap,
    virtualMarketInfo,
    virtualCategoryInfo
  ])

  return (
    <StyledEventTypeWrapper>
      <IconInfoCircle
        colorProps={{
          type: EColorsTypes.CUSTOM,
          name: EColorsNames.Primary,
          value: 9
        }}
      />
      <StyledEventType>{outcomeName}</StyledEventType>
    </StyledEventTypeWrapper>
  )
}

const OutcomeNameDeprecated = ({
  event,
  outcomeCategory,
  outcomeType,
  probability
}: Props) => {
  const basketOutcomeName = useMemo(() => {
    if (!outcomeType) return ''
    return getBasketOutcomeName({
      probWithOutcome: {
        ...probability,
        outcomeTypeData: outcomeType
      },
      event,
      outcomeCategory
    })
  }, [event, outcomeCategory, outcomeType, probability])
  return (
    <StyledEventTypeWrapper>
      <IconInfoCircle
        colorProps={{
          type: EColorsTypes.CUSTOM,
          name: EColorsNames.Primary,
          value: 9
        }}
      />
      <StyledEventType>{basketOutcomeName}</StyledEventType>
    </StyledEventTypeWrapper>
  )
}
