import React, { FC, useState } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useRouteMatch } from 'react-router-dom'

import { useAppSelector } from 'store'
import { ERoutes } from 'shared/types/routes'

import { IconLogoStyled, StyledLogoSkeleton } from './icon-logo.styled'

const CASINO_LOGO_URL = 'casino-logo.svg'

export const useIconLogoUrl = () => {
  const casinoMatch = useRouteMatch(ERoutes.Casino)
  const config = useAppSelector(selectConfig)

  const typeTheme = useAppSelector(selectUserSettingLayoutTheme)

  return {
    url: `${config.CDN_URL}/logo/${
      config.CLIENT_TYPE
    }/${typeTheme.toLowerCase()}/${
      casinoMatch ? CASINO_LOGO_URL : config.LOGO_ICON_URL
    }`,
    height: casinoMatch ? 14 : 16
  }
}

export const IconLogo: FC = () => {
  const { url, height } = useIconLogoUrl()
  const [loading, setLoading] = useState(true)

  return (
    <>
      {loading && <StyledLogoSkeleton />}
      <IconLogoStyled
        parseFileFromServerProps={{
          url
        }}
        height={height}
        loadingState={setLoading}
      />
    </>
  )
}
