import React, { FC, Fragment, useEffect, useRef } from 'react'
import { getHasBeforeEventIds } from 'astra-core/containers/EventsProvider'

import { useAppSelector } from 'store'
import { ETestData } from 'shared/lib/testData'
import {
  TableBetsContextProps,
  TableBetsProvider
} from 'widgets/table-bets-context'

import { useInfiniteScrollPagesInfo } from '../../model/hooks'
import { TournamentGroup } from '../TournamentGroup'

import { EventsTableVirtualScrollProps } from './EventsTableWithVirtualScroll.types'

export const EventsTableWithVirtualScroll: FC<
  EventsTableVirtualScrollProps
> = ({
  eventsGroups,
  resetEventListScroll,
  headerEventsProps,
  isLive,
  isLinkHead,
  isFavourite,
  loadPrevPage,
  listKey,
  renderGroupHeader,
  position
}) => {
  const { current: tableBetsContextValue } = useRef<TableBetsContextProps>({
    ...(headerEventsProps && { tableEventHead: headerEventsProps }),
    isLive,
    isLinkHead,
    isFavourite,
    listKey,
    position
  })

  const hasBeforeEventIds = useAppSelector((state) =>
    getHasBeforeEventIds(state, listKey)
  )

  const {
    page,
    pagePrev,
    page1EndRef,
    page2StartRef,
    page2EndRef,
    page1StartRef,
    beforeEndRef,
    beforeStartRef,
    beforeStartInView,
    beforeStartInViewPrev
  } = useInfiniteScrollPagesInfo()
  const tableWrapperTopRef = useRef(0)

  useEffect(() => {
    if (!resetEventListScroll) return
    if (beforeStartInView && !beforeStartInViewPrev) {
      resetEventListScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beforeStartInView, resetEventListScroll])

  useEffect(() => {
    if (!hasBeforeEventIds || !loadPrevPage) return

    if (page === 'page1' && (pagePrev === 'page2' || pagePrev === 'both')) {
      const tableElement = document.getElementsByClassName('events-table')[0]
      if (tableElement) {
        const tableWrapperTop = tableElement.getBoundingClientRect().top
        if (tableWrapperTop > tableWrapperTopRef.current) {
          loadPrevPage()
        }
      }
    }
  }, [page, pagePrev, hasBeforeEventIds, loadPrevPage])

  useEffect(() => {
    const tableElement = document.getElementsByClassName('events-table')[0]
    if (tableElement) {
      if (tableWrapperTopRef.current !== undefined) {
        tableWrapperTopRef.current = tableElement.getBoundingClientRect().top
      }
    }
  }, [page])

  return (
    <TableBetsProvider {...tableBetsContextValue}>
      <div className="events-table" data-test-id={ETestData.TestTableEventBets}>
        {eventsGroups.map((group) => {
          return (
            <Fragment key={group.id}>
              {renderGroupHeader?.(group)}
              {group.eventGroups.map((group) => (
                <TournamentGroup
                  beforeEndRef={beforeEndRef}
                  beforeStartRef={beforeStartRef}
                  eventsGroup={group}
                  key={group.id}
                  page1EndRef={page1EndRef}
                  page1StartRef={page1StartRef}
                  page2EndRef={page2EndRef}
                  page2StartRef={page2StartRef}
                />
              ))}
            </Fragment>
          )
        })}
      </div>
    </TableBetsProvider>
  )
}
