import React, { FC } from 'react'
import {
  basketProviderActions,
  selectBasketOutcomes
} from 'astra-core/containers/BasketProvider'
import { useTranslation } from 'react-i18next'
import { getOrdinarBasketOutcomeRestriction } from 'astra-core/domain/basket/validation'

import { ETestData } from 'shared/lib/testData'
import { IconWarning2 } from 'shared/ui/Icon/General/IconWarning2'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { EButtonViews } from 'shared/ui/Button'
import { useAppSelector, useAppDispatch } from 'store'

import { BetCardCoupon } from '../BasketCommon/Common'
import {
  StyledQuickBetSubmitButton,
  StyledQuickBetSubmitButtonText
} from '../BasketQuickBet/components/BasketQuickBetSubmitButton/BasketQuickBetSubmitButton.styled'

import {
  BasketClearAllButtonsWrapper,
  BasketClearAllCancelButton,
  BasketClearAllConfirmButton,
  BasketClearAllOutcomesWrapper,
  BasketClearAllText,
  BasketClearAllTitle,
  BasketClearAllWrapper,
  StyledBetCardCouponWrapper
} from './BasketClearAll.styled'
import { BasketClearAllProps } from './BasketClearAll.types'

export const BasketClearAll: FC<BasketClearAllProps> = (props) => {
  const { onClickCancel } = props
  const outcomes = useAppSelector(selectBasketOutcomes)
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const deleteBasket = () => {
    dispatch(basketProviderActions.deleteBasket())
  }

  return (
    <BasketClearAllWrapper>
      <IconWarning2
        colorProps={{
          name: EColorsNames.Yellow,
          value: 10,
          type: EColorsTypes.CUSTOM
        }}
        size={32}
      />
      <BasketClearAllTitle
        data-test-id={ETestData.TestQuickBetClearBasketTitle}
      >
        {t('coupon will be cleared')}
      </BasketClearAllTitle>
      <BasketClearAllText>{t('need to clear the coupon')}</BasketClearAllText>
      <BasketClearAllOutcomesWrapper>
        {outcomes.map((outcome) => (
          <StyledBetCardCouponWrapper>
            <BetCardCoupon
              outcome={outcome}
              restriction={getOrdinarBasketOutcomeRestriction(outcome)}
            />
          </StyledBetCardCouponWrapper>
        ))}
      </BasketClearAllOutcomesWrapper>
      <BasketClearAllButtonsWrapper>
        <StyledQuickBetSubmitButton>
          <BasketClearAllConfirmButton
            data-test-id={ETestData.TestQuickBetClearBasketConfirm}
            onClick={deleteBasket}
          >
            <StyledQuickBetSubmitButtonText>
              {t('clear')}
            </StyledQuickBetSubmitButtonText>
          </BasketClearAllConfirmButton>
        </StyledQuickBetSubmitButton>
        <BasketClearAllCancelButton
          data-test-id={ETestData.TestQuickBetClearBasketCancel}
          view={EButtonViews.SECONDARY}
          onClick={onClickCancel}
        >
          <StyledQuickBetSubmitButtonText>
            {t('сancel')}
          </StyledQuickBetSubmitButtonText>
        </BasketClearAllCancelButton>
      </BasketClearAllButtonsWrapper>
    </BasketClearAllWrapper>
  )
}
